import React from 'react';
import { Button, Modal, Result } from 'antd';

import { useAppSelector } from 'src/hooks';
import { AppI } from 'src/redux';

import homeI18n from 'src/i18n/home.i18n.json';
import { glLettersLogo } from 'src/assets/images';

interface SuccessCodeModal {
    handleModal: () => void;
    success: boolean;
    openModal: boolean;
    code?: string;
}

const SuccessCodeModal = ({ code, handleModal, openModal, success }: SuccessCodeModal) => {
    const { lang } = useAppSelector(({ app }: { app: AppI }) => app);

    return (
        <Modal
            centered
            open={openModal}
            onOk={handleModal}
            onCancel={handleModal}
            closable
            footer={[
                <Button key='submit' type='primary' onClick={handleModal} ghost>
                    {homeI18n[lang].btnContinue}
                </Button>]
            }
        >
            {success ?
            <>
                <Result
                    icon={<img src={glLettersLogo} alt='glook-logo' width={100} height={100} />}
                    title={homeI18n[lang].howWorkSuccessTitle}
                    subTitle={
                        <div style={{ textAlign: 'center' }}>
                            <h3>{homeI18n[lang].howWorkSuccessDescription}</h3>
                            <h1>{code}</h1>
                            <h4>{homeI18n[lang].howWorkSuccessComplement}</h4>
                        </div>
                    }
                />
            </>
            :
            <>
                <Result
                    icon={<img src={glLettersLogo} alt='glook-logo' width={100} height={100} />}
                    title={homeI18n[lang].howWorkInfo}
                    subTitle={homeI18n[lang].howWorkInfoComplement}
                />
            </>
            }
        </Modal>
    )
}
export default SuccessCodeModal;
