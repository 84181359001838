import React, { useEffect, useState } from 'react';

import { ArrowLeftOutlined, MenuOutlined, ShoppingOutlined } from '@ant-design/icons';
import { Badge, Button, Divider, Layout, Tooltip, theme } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { glookLogo, glLettersLogo } from 'src/assets/images';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { AppI, CartI, CommonI, UserDataI, removeUser, setLang, setLocale } from 'src/redux';

import DrawerApp from './DrawerApp';
import LayoutI18n from 'src/i18n/layout.i18n.json';
import styles from './DrawerApp.module.css';
import SelectLanguage from 'src/pages/auth/SelectLanguage';
import { getOption } from 'src/pages/compositions';

const { Header } = Layout;

const MainHeader = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { locale, lang } = useAppSelector(({ app }: { app: AppI }) => app);
    const { data } = useAppSelector(({ user }: { user: UserDataI }) => user);
    const { currentService } = useAppSelector(({ common }: { common: CommonI }) => common);
    const { products } = useAppSelector(({ cart }: { cart: CartI }) => cart.data);

    const { token } = theme.useToken();
    const [showDrawer, setShowDrawer] = useState(false);

    const handleShowDrawer = () => setShowDrawer(true);

    const handleHideDrawer = () => setShowDrawer(false);

    return (
        <>
            <Header style={{
                backgroundColor: token.colorBgContainer,
                position: 'sticky',
                top: 0,
                zIndex: 100,
            }} >
                <div className={`container ${styles.header_web}`}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Link to='/'><img src={glookLogo} alt='glook-logo' width={180} height={35} /></Link>
                        <div>
                            <Divider type='vertical' />
                            <Link to='/nosotros'>{LayoutI18n[lang].about}</Link>
                            <Divider type='vertical' />
                            <Link to={currentService ? `/servicios/${currentService}` : '/'}>{LayoutI18n[lang].services}</Link>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <Tooltip
                            placement='bottom'
                            color='#FFFFFF'
                            title={<SelectLanguage
                                locale={locale}
                                lang={lang}
                                onChangeLocale={(value) => dispatch(setLocale(value))}
                                onChangeLang={(value) => dispatch(setLang(value))}
                            />}
                        >
                            <div style={{}}>
                                {getOption(locale, lang, styles.flags)}
                            </div>
                        </Tooltip>
                        <Divider type='vertical' />
                        {!data.name ? 
                        <Link to='/inicio-sesion'>{LayoutI18n[lang].login}</Link>
                        :
                        <Tooltip
                            placement='bottom'
                            color='#FFFFFF'
                            title={
                                <Button type="text" onClick={() => { dispatch(removeUser()) }}>
                                    {LayoutI18n[lang].closeSessionText}
                                </Button>
                            }
                        >
                            <Link to='/'>{`${data.name}${data.lastnames ? ` ${data.lastnames}` : ''}`}</Link>
                        </Tooltip>
                        }
                        <Divider type='vertical' />
                        <Button style={{ width: 30, height: 30 }} type='text' shape='circle' icon={products.length > 0 ? <Badge count={products.reduce((acc, pr) => acc + pr.numberProducts, 0)}><ShoppingOutlined style={{ width: 25, fontSize: 22 }} /></Badge> : <ShoppingOutlined style={{ width: 25, fontSize: 22 }} />} onClick={() => navigate('/cart')} />
                    </div>
                </div>
                <div className={styles.header_mobile}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button type='text' shape='circle' icon={<MenuOutlined />} onClick={handleShowDrawer} />
                        <Link to='/'><img src={glLettersLogo} alt='glook-logo' width={30} height={30} /></Link>
                        {window.location.pathname !== '/' && <Button style={{ padding: 0, marginLeft: 10 }} type='link' onClick={() => {
                            navigate(-1);
                        }}>
                            <ArrowLeftOutlined style={{ fontSize: 20, color: '#C2A799' }} />
                        </Button>}
                    </div>
                    <div>
                        <Button style={{ width: 30, height: 30 }} type='text' shape='circle' icon={products.length > 0 ? <Badge count={products.reduce((acc, pr) => acc + pr.numberProducts, 0)}><ShoppingOutlined style={{ width: 25, fontSize: 22 }} /></Badge> : <ShoppingOutlined style={{ width: 25, fontSize: 22 }} />} onClick={() => navigate('/cart')} />
                    </div>
                </div>
            </Header>
            <DrawerApp
                openDrawer={showDrawer}
                onCloseDrawer={handleHideDrawer}
            />
        </>
    )
}
export default MainHeader;
