import { Fragment } from 'react';

import dayjs from 'dayjs';
import 'dayjs/locale/en';

dayjs.locale('en');

const En = () => {
 
   return (
      <div className='container'>
         <div className='m-5'>
            <h1 className='text-center'>Privacy Policy Good Look USA, LLC</h1>
            <hr />
            <h3 style={{ textTransform: 'capitalize' }}>Fecha: {dayjs().format('DD/MMM/YYYY')}</h3>
            <br />
            <div className='text-justified'>
               <h2 id='definiciones'>1. INTRODUCTION</h2>
               <br />
               <p>1.1. The purpose of this privacy policy is to explain how Good Look USA, LLC (&ldquo;Good Look&rdquo;, &ldquo;we&rdquo;, &ldquo;us&rdquo; and &ldquo;our&rdquo;) handles Personal Data information collected from you (&ldquo;You&rdquo;, &ldquo;User&rdquo;, &ldquo;Customer&rdquo;) during the use and payment of services on the Good Look USA, LLC website, &ldquo;Website&rdquo; (located at <a href='https://goodlook.com.mx' rel='noreferrer' target='_blank'>https://www.goodlook.com.mx</a>).</p>
               <p>1.2. Terms not defined in this Privacy Policy have the meanings ascribed to them in our Terms of Use, located at <a href='https://goodlook.com.mx' rel='noreferrer' target='_blank'>https://www.goodlook.com.mx</a></p>
               <p>1.3. This policy does not apply to third-party websites that you may access from our &ldquo;Website&rdquo; nor does it include other information collection practices not conducted over the internet.</p>
               <p>1.4. The user must accept the current data protection guidelines by confirming on the ldquo;Website&rdquo;. By confirming these terms, the user acknowledges and accepts the statements described in this document.</p>
               <br />
               <h2 id='alcance'>2. DEFINITIONS</h2>
               <br/>
               <p>2.1. For the purposes of this document, the following definitions shall be understood in an enumerative but not limiting manner:</p>
               <ol type='a'>
                  <li><strong>“Good Look”:</strong> Good Look USA, LLC, is the company established in the state of Texas and responsible for collecting and safeguarding your personal data.</li>
                  <li><strong>Privacy Notice:</strong> This electronic document or any other format generated by the data controller that is made available to the data subject before the processing of their personal data.</li>
                  <li><strong>Databases/Record System:</strong> Refers to the organized set of personal data related to an identified or identifiable person. This group of records is under the control of an entity, and the information is obtained through a unique personal identifier.</li>
                  <li><strong>Consent:</strong> Expression of the data subject&apos;s will by which the processing of their data is carried out.</li>
                  <li><strong>Personal Data:</strong> Any information concerning an identified or identifiable natural person, including but not limited to racial or ethnic origin, present and future health status, genetic information, religious, philosophical, and moral beliefs, union membership, political opinions, sexual preference.</li>
                  <li><strong>Profile:</strong> Refers to the collection of settings that together determine the information that will be included or excluded from a particular report. For example, a user account is a profile.</li>
                  <li><strong>Data Controller:</strong> Private individual or legal entity that decides on the processing of personal data.</li>
                  <li><strong>Third Party:</strong> The natural or legal person, national or foreign, other than the data subject or the data controller.</li>
               </ol>
               <br />
               <h2 id='naturalezanegocio'>3. PERSONAL DATA COLLECTEC</h2>
               <br/>
               <p>3.1. When you visit our &ldquo;Website&rdquo; to read pages or download information, we automatically collect and store the following information, which does not constitute personally identifiable information:</p>
               <ol type='i'>
                  <li>The Internet Protocol (IP) address from which you accessed our website. An IP address is a unique number assigned automatically to the computer you are using each time you browse the internet. This number is used for our internet security protocols to help protect the security of Good Look&apos;s information technology systems.</li>
                  <li>The type of browser, such as Safari, Internet Explorer, or Firefox, and the operating system, such as Windows, macOS, or Linux, used to access our site, to optimize your experience on the website.</li>
                  <li>The date and time when our website was accessed, to monitor demand.</li>
                  <li>The pages that were visited, to improve the usefulness of our website by providing useful links and removing pages that are not read.</li>
               </ol>
               <p>The information we retain does not include personally identifiable information. The aforementioned information is kept in the records of our systems to help make our site more useful for visitors, to know the number of visitors our site has, and the types of technology they use. Records may be retained indefinitely and used as necessary to prevent a security breach and ensure the integrity of data on our servers.</p>
               <p>3.2. <strong>Personally Provided:</strong> When you provide information to us personally through various electronic means, such as contact via instant messaging, email, subscribing to our services, purchasing our products online, contacting us through instant messaging platforms, participating in user surveys, or requesting the invoicing of a product or service.</p>
               <ol type='i'>
                  <li>
                     The data may include, among others:<br />
                     <ol type='a'>
                        <li>Name and Last Name</li>
                        <li>Complete address</li>
                        <li>Email</li>
                        <li>Contact number</li>
                     </ol>
                     Likewise, the &ldquo;Website&rdquo; system may provide the corresponding security validation accreditation.<br /><br />
                     Good Look does not collect or store financial accounts; banking data will be collected by external platforms, through a data validation and verification certificate. If you do not want to provide the required authentication or payment information, you will not be able to make purchases from the Good Look Website.
                  </li>
               </ol>
               <p>3.3. Social Networks: Social networks are a communication and interconnection platform within digital platforms of different users, and they are not the responsibility of &ldquo;Good Look&rdquo; and/or its owner and affiliates.</p>
               <p>3.4. However, Good Look may request the following information through its social network platforms, as well as those designated as instant messaging applications:</p>
               <ol type='a'>
                  <li>Name and Last Name</li>
                  <li>Contact Number</li>
                  <li>Email</li>
               </ol>
               <p>3.5. We inform you that on our Website, we may use cookies or external platforms to recognize devices, but not to track them. These tools may monitor your behavior as an internet user, as well as provide better advertising services and a user experience while browsing our Website.</p>
               <p>3.6. Likewise, information storage platforms may be established in other countries and with third-party providers, inherent to the controller&apos;s purpose.</p>
               <br />
               <h2 id='visitantesyusuarios'>4. Children&apos;s Online Privacy Protection Act (COPPA).</h2>
               <br/>
               <p>
                  4.1. Good Look, in strict compliance with the Children&apos;s Online Privacy Protection Act (COPPA), Title 16 of the Code of Federal Regulations, Part 312, adheres to the requirements established by this law for operators of websites intended for children under 13 and those who are aware of the collection and maintenance of information from children under 13.<br />
                  According to COPPA, verifiable parental consent is required before collecting, using, or disclosing personal information from children. In the case of Good Look, the contracting of services will only be allowed for individuals of legal age. However, we will not assume responsibility if a child under 13 intentionally enters their data, as this responsibility lies solely with the parent or legal guardian.
               </p>
               <br />
               <h2 id='registrousuario'>5. PURPOSES OF PROCESSING YOUR PERSONAL DATA</h2>
               <br/>
               <p>
                  5.1. Your personal data will be used for the following purposes:
               </p>
               <ol type='a'>
                  <li>Facilitating the creation and ensuring the security of your account in our network;</li>
                  <li>Subscription to services;</li>
                  <li>Facilitating beauty services at the requested location;</li>
                  <li>Identifying you as a user in our system;</li>
                  <li>Periodic evaluations of products and services;</li>
                  <li>Improving the quality of the experience when interacting with our services;</li>
                  <li>Digital marketing;</li>
                  <li>Customer service;</li>
                  <li>Sending administrative notifications by email, such as security or support and maintenance notices;</li>
                  <li>Also, maintaining a record for financial reporting, auditing, and compliance purposes; additionally, to comply with legal obligations that may arise from the commercial and service relationship between Good Look and you.</li>
               </ol>
               <br />
               <h2 id='comprasypagos'>6. NOTE FOR USERS OUTSIDE THE UNITED STATES</h2>
               <br/>
               <p>6.1. Your Personal Data may be processed in the country where it was collected and in other countries, including the United States, where laws related to the processing of Personal Data may be less stringent than the laws in your country.</p>
               <br />
               <h2 id='cancelacionreembolsos'>7. DATA TRANSFER</h2>
               <br/>
               <p>7.1. Your personal data may be transferred and processed within the country by individuals other than Good Look.</p>
               <p>7.2. In this context, your information may be shared with collaborators and independent service providers of Good Look to ensure the proper provision of services paid through the Website.</p>
               <p>7.3. The transfer of information will take place for the following purposes:</p>
               <ol type='a'>
                  <li>Developing and maintaining the security of Good Look: We ensure the protection and continuity of Good Look&apos;s security by transferring data for monitoring, analysis, and continuous improvement of our security systems and protocols.</li>
                  <li>With independent service providers: We will share information with external collaborators and providers to ensure the efficient and effective provision of services offered through Good Look. This includes coordinating appointments, scheduling home beauty services, and other essential aspects to meet the needs of our users.</li>
                  <li>With software and engineering providers: We will collaborate with specialized software and engineering providers to ensure the development, improvement, and continuous innovation of our platforms and digital services. This collaboration aims to optimize the user experience and keep our systems up-to-date with the latest technologies and best practices.</li>
                  <li>Affiliates and Acquisitions: We may share some or all of your personal data with our parent company established under the laws of Mexico, subsidiaries, joint ventures, or other companies under common control (&ldquo;Affiliates&rdquo;), in which case we will require our Affiliates to respect this Privacy Policy. If another company acquires our company, business, or our assets, that company will own the personal data collected by us and will assume the rights and obligations regarding your personal data as described in this Privacy Policy.</li>
                  <li>For legal reasons: We will share personal information outside of Good Look if we believe in good faith that access, use, preservation, or disclosure of the information is reasonably necessary to: (i) comply with any applicable law, regulation, legal process, or government request. (ii) We share information about the quantity and type of requests we receive from governments in our Transparency Report. (iii) Enforce applicable Terms of Service, including investigating potential violations. (iv) Detect, prevent, or otherwise address fraud, security, or technical issues. (v) Protect against harm to the rights, property, or safety of Good Look, our users, or the public, as required or permitted by law.</li>
               </ol>
               <br />
               <h2 id='vigencia'>8. OPTIONS REGARDING YOUR PERSONAL DATA:</h2>
               <br/>
               <ol type='a'>
                  <li>
                     Communication Options: <br /><br />
                     <ol type='i'>
                        <li>We offer alternatives regarding the collection, use, and sharing of your personal data. We may send you newsletters and promotional emails that directly encourage the use of our services. At any time, you can express your preference to stop receiving these communications by opting out, following the unsubscribe instructions provided in the emails received, or by contacting us directly.</li>
                        <li>Despite your email preferences, we may send you service-related communications, such as notifications of updates to our Terms of Use or Privacy Policy.</li>
                     </ol>
                  </li>
                  <li>
                     Modification of Personal Data:<br /><br />
                     You can update any personal information in your account by sending us an email to the address provided below. You can also request the deletion of your personal data, although it is important to note that we will retain business transaction records, and it may be necessary to retain certain information for legal or regulatory reasons. When we delete information, it is removed from the active database, although it may persist in our archives, for example, for fraud prevention or similar purposes.
                  </li>
                  <li>
                     Security of Your Personal Data:<br /><br />
                     We are committed to safeguarding the security of your personal data through the implementation of industry-standard technologies and security procedures. Although we require a password to access your account information, no method of Internet transmission or electronic storage is entirely infallible. Despite our reasonable efforts, we cannot guarantee the absolute security of your personal data.
                  </li>
               </ol>
               <br />
               <h2 id='fallas'>9. MODIFICATION TO THE PRIVACY NOTICE</h2>
               <br/>
               <p>9.1. We reserve the right to make modifications or updates to this privacy notice at any time, to address legislative or regulatory changes, internal policies, new requirements for the provision or offering of our services or products, and market practices. These modifications will be made available to the public through the corresponding electronic media, to the email addresses you have provided, and through our website <a href='https://goodlook.com.mx' rel='noreferrer' target='_blank'>https://www.goodlook.com.mx/</a></p>
               <br />
               <h2 id='derechosylicencias'>10. SECURITY OF YOUR PERSONAL INFORMATION</h2>
               <br/>
               <p>10.1. We are committed to safeguarding the integrity of your personal data by implementing various industry-recognized technologies and security protocols. We employ standard measures to prevent unauthorized access, use, or disclosure of your personal data. Additionally, we ask you to establish a password to access your account information and remind you to avoid sharing that password with unauthorized individuals.</p>
               <p>10.2. Despite our rigorous efforts, it is important to note that no method of internet transmission or electronic storage can be considered completely infallible. Therefore, while we make all reasonable efforts to protect your personal data, we cannot guarantee its absolute security.</p>
               <br />
               <h2 id='limitacion'>11. CONTACT US</h2>
               <br/>
               <p>11.1. For additional details on Good Look&apos;s privacy and confidentiality measures, please reach out to our Privacy Department by sending an email to <a href='https://hello@goodlook.com.mx' rel='noreferrer' target='_blank'>hello@goodlook.com.mx</a></p>
               <br />
               <h2 id='propiedadintelectual'>12. DATA REQUESTS</h2>
               <br/>
               <p>12.1. For information on data requests, please contact Good Look by email at  <a href='https://hello@goodlook.com.mx' rel='noreferrer' target='_blank'>hello@goodlook.com.mx</a></p>
            </div>
         </div>
      </div>
   )
 }
export default En;