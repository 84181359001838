import React, { useEffect, useState } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ComplementLook, OurProfessionals } from 'src/pages/compositions';

import ServiceDetails from './ServiceDetails';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { AppI, CommonI, setCurrentProduct } from 'src/redux';

const ServiceDetail = () => {
    const { lang } = useAppSelector(({ app }: { app: AppI }) => app);
    const { currentProduct, products, currentZone } = useAppSelector(({ common }: { common: CommonI }) => common);
    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const { idProduct } = useParams();

    const [titleService, setTitleService] = useState('');

    useEffect(() => {
        if (location.pathname) {
            const url = new URL(window.location.origin+location.pathname+location.search).searchParams;
            const title = url.get('title');
            setTitleService(title || '');
        }
    }, [location])

    useEffect(() => {
        if (idProduct && currentProduct.id === 0) {
            const prod = products.find(i => i.id === Number(idProduct));
            if (prod) dispatch(setCurrentProduct(prod))
        } 
    }, [currentProduct])

    useEffect(() => {
        if (currentZone.locality === '') {
            navigate('/', { replace: true});
        }
    }, [currentZone.locality])

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [])

    return (
        <>
        <ServiceDetails
            currentProduct={currentProduct}
            titleService={titleService}
            typeService={currentProduct ? currentProduct.name[lang].toLowerCase().indexOf('premium') > -1 ? 'Premium' : 'Estandar' : 'Estandar'}
        />
        <ComplementLook />
        <OurProfessionals />
        </>
    )
}

export default ServiceDetail