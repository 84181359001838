import React, { useEffect, useState } from 'react';

import { pdf,} from '@react-pdf/renderer';
import { Button, Modal, notification } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import { initializeApp } from 'firebase/app';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';

import { glLettersLogo } from 'src/assets/images';
import { useAppSelector } from 'src/hooks';
import { AppI } from 'src/redux';
import { En, Es } from './lang';
import { TermsEnPdf } from './TermsEnPdf';
import { TermsPdf } from './TermsPdf';

import notifI18n from 'src/i18n/notifications.i18n.json';

const firebaseConfig = process.env.REACT_APP_FIREBASE ? JSON.parse(process.env.REACT_APP_FIREBASE) : {apiKey: '',
    authDomain: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '' 
};

const app = initializeApp(firebaseConfig);

const removeAccentsAndSpecialChars = (str: string) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w\s]/gi, '');
}

const TermsGoodlookero = () => {
    const [loading, setLoading] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [api, contextHolder] = notification.useNotification();

    const { lang, locale } = useAppSelector(({ app }: { app: AppI }) => app);
    const navigate = useNavigate();
    const datas = localStorage.getItem('goodlookInfo');

    const handleTerms = async () => {
        try {
            setLoading(true)
            // choode terms by lang
            const pdfComp = lang === 'es' ? <TermsPdf locale={locale} /> : <TermsEnPdf locale={locale} />;
            // init pdf
            const asPdf = pdf(<></>);
            // update component
            asPdf.updateContainer(pdfComp);
            // get blob file
            const blob = await asPdf.toBlob();
            // init storage
            const storage = getStorage(app);
            if (storage) {
                // storage initialized correctly

                // path of bucket
                const path = 'pdfs/';
                const fileName = datas ? `${removeAccentsAndSpecialChars(JSON.parse(datas).name.replace(/ /g,'').toLowerCase())}.pdf` : `no-name-${dayjs().format('DD/MM/YYYY')}.pdf`;
                // creating references
                const storageRef = ref(storage, `${path}${fileName}`)
                // upload file to bucket
                const docRef = await uploadBytes(storageRef, blob);
                // verify url
                if (docRef.ref) {
                    // get file url
                    const urlFile = await getDownloadURL(docRef.ref);
                    sendInfoByEmail(urlFile);
                }
            } else {
                console.error('Error al inicializar Firebase Storage.');
                setLoading(false);
            }
        } catch (error:any) {
            console.log('ERROR: ',error);
            setLoading(false);
            api.error({
                message: lang === 'es' ? 'Términos y Condiciones Goodlookero' : 'Goodlookero terms and conditions',
                description: lang === 'es' ? 'Error de generación de PDF y guardado en almacenamiento' : 'PDF Generation and Save in Storage Error',
                placement: 'topLeft',
                key: 'eventkey'
            });
        }
    }

    const handleOk = () => {
        localStorage.removeItem('goodlookInfo');
        navigate('/', { replace: true });
    }

    const sendInfoByEmail = async (linkTerms: string) => {
        try {
            if (!loading) setLoading(true);
            const dataGl = localStorage.getItem('goodlookInfo');
            const objData = dataGl ? JSON.parse(dataGl) : {};

            const body = {
                service_id: process.env.REACT_APP_EMAIL_SERVICE_ID || '',
                template_id: process.env.REACT_APP_EMAIL_SUBSCRIBE_TEMPLATE || '',
                user_id: process.env.REACT_APP_EMAIL_KEY || '',
                template_params: {
                    title: `Aceptación de Términos y Condiciones Goodlookero para Registro${process.env.REACT_APP_ENVIRONMENT === 'DEV' ? ' (TESTING ENVIRONMENT)': ''}`,
                    content: `
                    DATOS PERSONALES:\n
                    Nombre: ${objData.name}
                    Edad: ${objData.age}
                    Correo Electrónico: ${objData.email}
                    Teléfono: ${objData.phone}
                    Red Social: ${objData.link},
                    Ciudad: ${objData.city},
                    Estado de la Republica: ${objData.republicState},
                    Contactar por: ${objData.preferMean}

                    Los términos y condiciones aceptados estan en: ${linkTerms}
                    Fecha de aceptación: ${dayjs().format('DD/MMM/YYYY')}

                    SALUDOS
                    `
                }
            }
            const { data } = await axios.post('https://api.emailjs.com/api/v1.0/email/send', body) 
            if (data === 'OK') {
                setSuccessModal(true);
            } else {
                api.error({
                    message: 'Términos y Condiciones Goodlookero',
                    description: notifI18n[lang].goodlookeroDescriptionNotification,
                    placement: 'topLeft',
                    key: 'eventkey'
                });
            }
            setLoading(false);
        } catch (error:any) {
            api.error({
                message: 'Términos y Condiciones Goodlookero',
                description: `${notifI18n[lang].goodlookeroDescriptionNotification}. ${error.message}`,
                placement: 'topLeft',
                key: 'eventkey'
            });
            setLoading(false);
        }
    }

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [])

    return (
        <>
        {contextHolder}
        {lang === 'es' ?
            <Es 
                onClickContinue={handleTerms}
                loading={loading}
            />
            :
            <En 
                onClickContinue={handleTerms}
                loading={loading}
            />
        }
        <Modal
            open={successModal}
            onOk={handleOk}
            closable={false}
            centered
            footer={[
                <Button key="submit" type="primary" onClick={handleOk} ghost>
                    {lang === 'es' ? 'Finalizar' : 'Finish'}
                </Button>]
            }
        >
            <div style={{ textAlign: 'center'}}>
                <img src={glLettersLogo} width={70} height={70} alt='gl-letters-logo' />
                <br />
                <br />
                <h2>{lang === 'es' ? 'Tus datos y Los términos y Condiciones quedaron registrados exitosamente' : 'Your data and the terms and conditions were successfully registered'}</h2>
                <p>{lang === 'es' ? 'Nos pondremos en contacto contigo mas adelante' : 'We will contact you later'}</p>
            </div>
        </Modal>
        </>
    )
}
export default TermsGoodlookero;
