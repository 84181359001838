import React, { useEffect, useState } from 'react';

import { glLettersLogo } from 'src/assets/images';

import styles from './termsgl.module.css';
import { Button, Col, Row, Table, TableColumnsType } from 'antd';
import dayjs from 'dayjs';
import Checkbox, { CheckboxChangeEvent } from 'antd/es/checkbox';

interface DataType {
    key: React.Key;
    service: JSX.Element;
    time: number;
    revenue: number;
}

interface TermsDataType {
    key: React.Key;
    name: string;
    city: string;
    date: string;
    acceptTerm: any;
}

const columns: TableColumnsType<DataType> = [
    {
        title: 'Services',
        dataIndex: 'service'
    },
    {
        title: 'estimated time',
        dataIndex: 'time',
        render: (text: any) => `${text} min`
    },
    {
        title: 'Revenue USD',
        dataIndex: 'revenue',
        render: (text: any) => `$${text} USD`
    }
];

const columnsTerms: TableColumnsType<TermsDataType> = [
    {
        title: 'Name(s)',
        dataIndex: 'name'
    },
    {
        title: 'City',
        dataIndex: 'city',
    },
    {
        title: 'Date',
        dataIndex: 'date',
    },
    {
        title: 'Accept T&C',
        dataIndex: 'acceptTerm'
    }
];

const data: DataType[] = [
    {
        key: '1',
        service: <>
            <h3>Manicure and pedicure Express</h3>
            <p>Removal of cuticles and nail polish (dry base)</p>
        </>,
        time: 50,
        revenue: 70
    },
    {
        key: '2',
        service: <>
            <h3>Manicure and pedicure</h3>
            <p>Cuticle and nail polish removal (water based)</p>
        </>,
        time: 80,
        revenue: 65
    },
    {
        key: '3',
        service: <>
            <p>Nail polish on hands and feet</p>
            <p>High-end gel nail polishes</p>
        </>,
        time: 60,
        revenue: 42
    },
    {
        key: '4',
        service: <>
            <h3>Manicure and Pedicure with Gel Polish</h3>
            <p>Cuticle removal and gel polish (water-based)</p>
        </>,
        time: 120,
        revenue: 88
    }
]

interface TermsProps {
    loading?: boolean;
    onClickContinue: () => void;
}

const En = ({ loading, onClickContinue }: TermsProps) => {
    const datas = localStorage.getItem('goodlookInfo');

    const [checkedTerms, setCheckedTerms] = useState(false);
    const [checkedTotallyTerms, setCheckedTotallyTerms] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    const onChangeTerms = (e: CheckboxChangeEvent) => {
        setCheckedTerms(e.target.checked);
    }

    const onChangeTotallyTerms = (e: CheckboxChangeEvent) => {
        setCheckedTotallyTerms(e.target.checked);
    }

    useEffect(() => {
        if (checkedTerms && checkedTotallyTerms)
            setIsDisabled(false);
        else
            setIsDisabled(true);
    }, [checkedTerms, checkedTotallyTerms])

    return (
        <div>
            <div className={styles.header_gl}>
                <img style={{ objectFit: 'contain' }} width={50} height={50} src={glLettersLogo} />
            </div>
            <div className={`container ${styles.card_gl}`}>
                <br />
                <h1>Independent Stylist Agreement and Terms</h1>
                <br />
                <p>Terms and conditions governing the contractual relationship between GOOD LOOK USA, LLC (“Good Look.”) Authorized address: 216 W. VILLAGE BLVD. SUITE 102-12 LAREDO, TX 78041 Authorized email: xxx and the undersigned named “The Independent Stylist”.</p>
                <p>Below, we collectively refer to “Good Look” and Independent Stylist as the “Parties” and each of them may be individually named as a “Party.”</p>
                <p>The parties accept and sign the following agreements:</p>
                <ol>
                    <li>Legal Age. The Independent Stylist represents that he is of legal age in the State where this Agreement is entered into.</li>
                    <li>Certification/License: The Independent Stylist declares that they have an active License and authorized by the State where they will provide the services.</li>
                    <li>Good Look Agreement. The Independent Stylist is subject to acceptance by Good Look. Upon acceptance by Good Look, the terms and conditions contained in this Independent Stylist Agreement, together with Good Look&apos;s Policies and Procedures for Independent Stylists (the &ldquo;Policies and Procedures&rdquo;), and the Privacy Policy, all which are incorporated into this Independent Stylist Agreement by reference, shall constitute the entire agreement (the &ldquo;Agreement&rdquo;) between Good Look and the Independent Stylist.</li>
                    <li>Registration Procedure: To begin the registration process as an independent stylist with Good Look, you are required to provide the following documents:
                        <ol type='a'>
                            <li>Social Security number</li>
                            <li>Makeup Artist license</li>
                            <li>Public account on social networks where your work is reflected (website, IG, Facebook, Tiktok)</li>
                            <li>Photos of tools that will be used in the services (makeup and hairstyle)</li>
                            <li>Official identification</li>
                            <li>Active bank account</li>
                        </ol>
                    </li>
                </ol>
                <br />
                <h1>Nails</h1>
                <Row>
                    <Col style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', border: 'solid', borderWidth: 0.5, borderColor: '#f0f0f0', paddingInline: 10 }} xs={24} sm={24} md={6} lg={6} xl={6}>
                        <h3>Manicure and Pedicure</h3>
                        <p>In our manicure and pedicure services we offer high quality products from brands such as Organic, Jessica, Shelac, among others.</p>
                    </Col>
                    <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                        <Table
                            columns={columns}
                            dataSource={data}
                            size='small'
                            pagination={false}
                        />
                    </Col>
                </Row>
                <br />
                <p>Electronic Transactions Act (UETA), both in force at the federal and state levels, the parties agree to the following: (i) Consent to the Use of Electronic Signatures: The parties recognize and accept the use of electronic signatures as valid means to express your consent and formalize agreements. (ii) Definition of Electronic Signature: &ldquo;electronic signature&lrdquo; any electronic symbol or process, associated with a contract or other record, adopted by a person with the intention of signing the record.(iii) Informed Consent: By signing electronically, each party expresses his or her informed consent to use the electronic signature with respect to all documents, contracts and transactions related to this agreement. Document Integrity: The parties agree that any electronically signed document will have the same legal force and effect as a physically signed document. Likewise, they recognize that electronic signatures are binding and that the electronic document will retain its integrity.</p>
                <br />
                <p>By signing electronically, each party acknowledges that it has read and understood the terms of this electronic signature clause, and expresses its consent to proceed with the electronic signature as provided.</p>
                <br />
                <Table
                    size='small'
                    pagination={false}
                    columns={columnsTerms}
                    dataSource={datas ? [
                        {
                            key: '1',
                            name: `${JSON.parse(datas).name}`,
                            city: `${JSON.parse(datas).city}`,
                            date: dayjs().format('DD/MMM/YYYY'),
                            acceptTerm: <>
                                <Checkbox checked={checkedTerms} onChange={onChangeTerms}>
                                  I&apos;ve read the document and accept its conditions
                                </Checkbox>
                            </>
                        }
                    ] : []}
                />
                <br />
                <p>Electronic Transactions Act (UETA), both in force at the federal and state levels, the parties agree to the following: (i) Consent to the Use of Electronic Signatures: The parties recognize and accept the use of electronic signatures as valid means to express your consent and formalize agreements. (ii) Definition of Electronic Signature: &ldquo;electronic signature&rdquo; any electronic symbol or process, associated with a contract or other record, adopted by a person with the intention of signing the record.(iii) Informed Consent: By signing electronically, each party expresses his or her informed consent to use the electronic signature with respect to all documents, contracts and transactions related to this agreement. Document Integrity: The parties agree that any electronically signed document will have the same legal force and effect as a physically signed document. Likewise, they recognize that electronic signatures are binding and that the electronic document will retain its integrity.</p>
                <br />
                <p>By signing electronically, each party acknowledges that it has read and understood the terms of this electronic signature clause, and expresses its consent to proceed with the electronic signature as provided.</p>
                <br />
                <div style={{ textAlign: 'center', backgroundColor: '#DDDBD1', paddingBlock: 10 }}>
                    <Checkbox checked={checkedTotallyTerms} onChange={onChangeTotallyTerms}>
                      I have read the document in its entirety and accept its conditions
                    </Checkbox>
                </div>
                <br />
                <div style={{ textAlign: 'center', paddingBlock: 10 }}>
                    <Button
                        htmlType='submit'
                        size='large' type='primary' ghost
                        style={{ width: '35%' }}
                        disabled={isDisabled}
                        onClick={onClickContinue}
                        loading={loading}
                    >
                        Continue
                    </Button>
                </div>
                <br />
                <br />
            </div>
        </div>
    )
}

export default En;
