import { api, returningError } from "src/api";
import { store } from "src/redux";

export const getInfoByPlaceId = async (placeId: string) => {
    try {
        const { data }:any = await api.get(`/${store.getState().app.locale || 'es'}/v1/gmaps/place`, {
            params: {
                place_id: placeId
            }
        });
        return data;
    } catch (error:any) {
        return returningError(error);
    } 
}