import React from 'react';
import { Button, Carousel, Col, Row } from 'antd';

import homeI18n from 'src/i18n/home.i18n.json';
import styles from './RegistryEvent.module.css';
import { useAppSelector } from 'src/hooks';
import { AppI } from 'src/redux';
import { useNavigate } from 'react-router-dom';
import { graduations, weddings, xvyears } from 'src/assets/images';

const getImage = (img:string) => {
    switch (img) {
        case 'graduations':
            return graduations;
        case 'weddings':
            return weddings;
        case 'xvyears':
            return xvyears;
    }
}

const RegistryEvent = () => {
    const { lang } = useAppSelector(({ app }: { app: AppI }) => app);
    const navigate = useNavigate();

    return (
        <div className={styles.registry_event}>
            <h1 style={{ color: '#FFFFFF', fontSize: '1.5rem' }}>{homeI18n[lang].eventTitle}</h1>
            <p style={{ color: '#FFFFFF', margin: 0 }}>{homeI18n[lang].eventDescriptionOne}</p>
            <p style={{ color: '#FFFFFF' }}>{homeI18n[lang].eventDescriptionTwo}</p>
            <Row gutter={[20, 20]} className={`container ${styles.row_events}`}>
                {homeI18n[lang].events.map(({ id, title, uri }) => 
                    <Col key={id} style={{ display: 'flex', justifyContent: 'center' }} xs={12} sm={12} md={12} lg={8} xl={8} xxl={8}>
                        <div
                            className={styles.card_event}
                            style={{
                                backgroundImage: `url(${getImage(uri)})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center'
                            }}
                        >
                            <p className={styles.service_button}>
                                {title}
                            </p>
                        </div>
                    </Col>
                )}
            </Row>
            <div className={styles.carousel_events}>
            <Carousel style={{ height: 330 }} autoplay>
                {homeI18n[lang].events.map(i => (
                    <div key={i.id}>
                        <div className={styles.card_event}
                            style={{
                                backgroundImage: `url(${getImage(i.uri)})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center'
                            }}
                        >
                            <p className={styles.service_button}>
                                {i.title}
                            </p>
                        </div>
                    </div>
                ))}
            </Carousel>
            </div>
            <Button
                className={styles.registry_event_btn} size='large' type='primary' ghost
                onClick={() => navigate('/eventos')}
            >
                {homeI18n[lang].btnEvent}
            </Button>
            <br />
            <br />
            <p style={{ color: '#FFFFFF', fontFamily: 'Gotham', fontSize: 14 }}>{homeI18n[lang].common}</p>
        </div>
    )
}
export default RegistryEvent
