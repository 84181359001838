import React from 'react';

import AboutHero from './AboutHero';
import Introduce from './Introduce';
import Corporate from './Corporate';
import { aboutImages } from 'src/assets/images';

const About = () => {
    return (
        <>
            <AboutHero />
            <Introduce />
            <Corporate />
            <div style={{ height: 250, paddingTop: 50, backgroundColor: '#FFFFFF' }}>
                <img style={{ objectFit: 'cover' }} src={ aboutImages} alt='about-imgs' height={200} width={'100%'} />
            </div>
        </>
    )
}
export default About;
