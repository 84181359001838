export const typeEnum = [
    {
        label: "Boda",
        value: "wedding"
    },
    {
        label: "Cumpleaños",
        value: "birthday"
    },
    {
        label: "Graduación",
        value: "graduation"
    },
    {
        label: "Aniversario",
        value: "anniversary"
    },
    {
        label: "Otro",
        value: "other"
    }
];

export const hours = [
    {
        label: '06:00 hrs',
        value: '06:00'
    },
    {
        label: '06:30 hrs',
        value: '06:30'
    },
    {
        label: '07:00 hrs',
        value: '07:00'
    },
    {
        label: '07:30 hrs',
        value: '07:30'
    },
    {
        label: '08:00 hrs',
        value: '08:00'
    },
    {
        label: '08:30 hrs',
        value: '08:30'
    },
    {
        label: '09:00 hrs',
        value: '09:00'
    },
    {
        label: '09:30 hrs',
        value: '09:30'
    },
    {
        label: '10:00 hrs',
        value: '10:00'
    },
    {
        label: '10:30 hrs',
        value: '10:30'
    },
    {
        label: '11:00 hrs',
        value: '11:00'
    },
    {
        label: '11:30 hrs',
        value: '11:30'
    },
    {
        label: '12:00 hrs',
        value: '12:00'
    },
    {
        label: '12:30 hrs',
        value: '12:30'
    },
    {
        label: '13:00 hrs',
        value: '13:00'
    },
    {
        label: '13:30 hrs',
        value: '13:30'
    },
    {
        label: '14:00 hrs',
        value: '14:00'
    },
    {
        label: '14:30 hrs',
        value: '14:30'
    },
    {
        label: '15:00 hrs',
        value: '15:00'
    },
    {
        label: '15:30 hrs',
        value: '15:30'
    },
    {
        label: '16:00 hrs',
        value: '16:00'
    },
    {
        label: '16:30 hrs',
        value: '16:30'
    },
    {
        label: '17:00 hrs',
        value: '17:00'
    },
    {
        label: '17:30 hrs',
        value: '17:30'
    },
    {
        label: '18:00 hrs',
        value: '18:00'
    },
    {
        label: '18:30 hrs',
        value: '18:30'
    },
    {
        label: '19:00 hrs',
        value: '19:00'
    },
    {
        label: '19:30 hrs',
        value: '19:30'
    },
    {
        label: '20:00 hrs',
        value: '20:00'
    },
    {
        label: '20:30 hrs',
        value: '20:30'
    }
]