import React, { useEffect, useState } from 'react'
import { glLogo, glEnLogo } from 'src/assets/images';
import { useAppSelector } from 'src/hooks';
import AboutI18n from 'src/i18n/about.i18n.json';
import { AppI } from 'src/redux';

const Introduce = () => {
   const { lang } = useAppSelector(({ app }: { app: AppI }) => app);

   const [width, setWidth] = useState(window.innerWidth);
    
    useEffect(() => {
        window.addEventListener('resize', () => {
            setWidth(window.innerWidth);
        })
        return () => window.removeEventListener('resize', () => {});
    }, [])

   return (
      <div style={{ backgroundColor: '#FFFFFF' }}>
         <div className='container' style={{ paddingTop: '50px', paddingBottom: '5px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', backgroundColor: '#FFFFFF' }}>
               <img src={lang === 'es' ? glLogo : glEnLogo} width={328} height={110} alt='gl-logo' />
               <br />
               <h1>{AboutI18n[lang].titleText}</h1>
               <p style={{ width: width < 600 ? '80%' : '65%', fontFamily: 'GothamLight', fontWeight: 500, fontSize: '1.2em' }}>
                  {AboutI18n[lang].firstText}
               </p>
         </div>
      </div>
   )
}
export default Introduce;
