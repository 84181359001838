import React, { useEffect, useState } from 'react';

import { Button, Spin } from 'antd';
import { Link, useNavigate } from 'react-router-dom';

import { Input, Password } from 'src/components';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { emailValidation } from 'src/utils';

import styles from '../Login.module.css';
import { UserDataI, userCleanStatus, userLogin } from 'src/redux';

const LoginForm = () => {
    const dispatch = useAppDispatch();
    const { userIsFetching, userSuccess } = useAppSelector(({ user }: { user: UserDataI }) => user)
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    const [isDisabled, setIsDisabled] = useState(true);

    const formValidation = () => {
        if (email && emailValidation(email) && pass) return setIsDisabled(false);
        setIsDisabled(true);
    }

    const handleSubmit = (e:any) => {
        e.preventDefault();

        dispatch(userLogin({
            customer: {
                email,
                password: pass
            }
        }))
    }

    useEffect(() => {
        formValidation();
    }, [email, pass])

    useEffect(() => {
        if (!userIsFetching && userSuccess) {
            navigate('/');
        }
    }, [userSuccess])

    useEffect(() => {
        return () => {
            dispatch(userCleanStatus());
        }
    }, [])

    return (
        <Spin spinning={userIsFetching}>
        <form onSubmit={handleSubmit}>
            <h3>Iniciar Sesión</h3>
            <Input
                name='email'
                placeholder='Correo electrónico'
                size='large'
                value={email}
                onChange={(e) => setEmail(e.target.value ? e.target.value.toLowerCase().trim() : '')}
                typeColor={email && emailValidation(email) ? 'default' : email !== '' ? 'error' : 'default'}
                status={email && emailValidation(email) ? '' : email !== '' ? 'error' : ''}
                text={email && emailValidation(email) ? '' : email !== '' ? 'Formato invalido' : ''}
            />
            <Password
                name='password'
                placeholder='Contraseña'
                size='large'
                value={pass}
                onChange={(e) => setPass(e.target.value ? e.target.value.trim() : '')}
            />
            <div className={styles.forgot_pass_container}>
                <Link className={styles.forgot_pass_text} to={'/'}>Olvidé mi contraseña</Link>
            </div>
            <Button
                onClick={handleSubmit}
                disabled={isDisabled}
                shape='default'
                type='primary'
                style={{ width: '100%', marginBottom: 10 }}
                size='large'
                htmlType='submit'
            >
                Continuar
            </Button>
            <br />
            <br />
            <h4>¿Aún no tienes una cuenta?</h4>
            <Button shape='default' style={{ width: '100%', marginBottom: 10 }} size='large' type='primary' ghost>Registrate</Button>
            <Button onClick={() => navigate('/')} shape='default' style={{ width: '100%' }} size='large' type='primary' ghost>Entrar como invitado</Button>
        </form>
        </Spin>
    )
}
export default LoginForm;
