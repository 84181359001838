import React from 'react';

import { UserOutlined } from '@ant-design/icons';
import { Avatar, Carousel, Rate } from 'antd';

import homeI18n from 'src/i18n/home.i18n.json';
import styles from './CustomersVoice.module.css';
import { useAppSelector } from 'src/hooks';
import { AppI } from 'src/redux';
import { rated } from './rated';

const CustomersVoice = () => {
    const { lang } = useAppSelector(({ app }: { app: AppI }) => app);
    return (
        <div>
            <div className='container text-center' style={{ marginBlock: 50 }}>
                <h3 className={styles.customers_title}>{homeI18n[lang].customerVoiceTitle}</h3>
                <h1>{homeI18n[lang].customerVoiceSubtitle}</h1>
                <br />
                <Carousel style={{ height: 220 }} autoplay>
                    {rated.map(i => (
                        <div key={i.id}>
                            <div style={{ textAlign: 'center' }}>
                                <Avatar
                                    size={{ xs: 45, sm: 55, md: 65, lg: 70, xl: 80, xxl: 100 }}
                                    icon={<UserOutlined />}
                                />
                                <br />
                                <Rate
                                    defaultValue={i.rated}
                                    disabled
                                />
                                <p style={{ fontStyle: 'italic' }}>{i.description}</p>
                                <h3>{i.name}</h3>
                            </div>
                        </div>
                    ))}

                </Carousel>
            </div>
        </div>
    )
}
export default CustomersVoice;
