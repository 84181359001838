import React from 'react'

import { Button, Col, Row } from 'antd';

import { goodlookeros } from 'src/assets/images';

import homeI18n from 'src/i18n/home.i18n.json';
import styles from './Goodlookeros.module.css';
import { useAppSelector } from 'src/hooks';
import { AppI } from 'src/redux';
import { useNavigate } from 'react-router-dom';

const Goodlookeros = () => {
    const { lang } = useAppSelector(({ app }: { app: AppI }) => app);
    const navigate = useNavigate();

    return (
        <div className={styles.goodlookeros}>
            <Row>
                <Col
                    className={styles.contain}
                    xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}
                    style={{
                        backgroundImage: `url(${goodlookeros})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                    }}
                />
                <Col style={{ minHeight: 350, display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingInline: 30 }} xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <h3 className={styles.goodlookeros_title}>{homeI18n[lang].goodlookerosTitle}</h3>
                    <h2>{homeI18n[lang].goodlookerosSubtitle}</h2>
                    <br />
                    <p>{homeI18n[lang].goodlookerosDescription}</p>
                    <Button
                        size='large' type='primary' ghost style={{ marginInline: '20%' }}
                        onClick={() => navigate('/goodlookeros')}
                    >
                        {homeI18n[lang].btnRegistry}
                    </Button>
                </Col>
            </Row>
        </div>
    )
}
export default Goodlookeros;
