import React from 'react';

import { Button, Col, Row } from 'antd';

import { specialPackage } from 'src/assets/images';
import homeI18n from 'src/i18n/home.i18n.json';
import styles from './SpecialPackage.module.css';
import { useAppSelector } from 'src/hooks';
import { AppI } from 'src/redux';


const SpecialPackage = () => {
    const { lang } = useAppSelector(({ app }: { app: AppI }) => app);
    return (
        <div>
            <Row>
                <Col className={styles.step_col} xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <h3 className={styles.step_title}>{homeI18n[lang].specialPackageTitle}</h3>
                    <h2>{homeI18n[lang].specialPackageSubtitle}</h2>
                    {homeI18n[lang].specialPackageSteps.map(item => (
                        <Row key={item.id}>
                            <Col>
                                <h3>{item.title}</h3>
                                <p>{item.description}</p>
                            </Col>
                        </Row>
                    ))}
                    <Button size='large' type='primary' ghost onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}>{homeI18n[lang].btnSchedule}</Button>
                </Col>
                <Col style={{ minHeight: 377 }} xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <img src={specialPackage} alt='spe-pack-img' width='100%' height={'100%'} />
                </Col>
            </Row>
        </div>
    )
}
export default SpecialPackage;
