import React, { useEffect } from 'react';

import HeroEvent from './HeroEvent';
import FormEvent from './FormEvent';

const Events = () => {

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [])

    return (
        <>
            <HeroEvent />
            <FormEvent />
        </>
    )
}
export default Events;
