import React from 'react';

import { Input as AInput, InputProps } from 'antd';

interface IProps extends InputProps {
    label?: React.ReactNode;
    text?: React.ReactNode | React.ReactNode[] | string | JSX.Element | JSX.Element[];
	typeColor?: 'default' | 'success' | 'info' | 'error';
}

const Password = ({ label, text, typeColor='default', ...rest }: IProps) => {
	return (
		<div className='form-input'>
			<AInput.Password
				{...rest}
			/>
			<span
                className={`span-form ${typeColor}`}
            >
                {text}
            </span>
		</div>
	)
}

export default Password;