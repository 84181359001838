import React from 'react';

import { useAppSelector } from 'src/hooks';
// import detServI18n from 'src/i18n/detailservice.i18n.json';
import { AppI } from 'src/redux';

import styles from './ComplementLook.module.css';

const ComplementLook = () => {
  const { lang } = useAppSelector(({ app }: { app: AppI }) => app);

  return (
    <div className={styles.complement_look}>
        <div className='container text-center pt-5 pb-5'>
            {/* <h2>{detServI18n[lang].complementLook}</h2> */}
        </div>
    </div>
  )
}

export default ComplementLook