import { getCategories, getEmployees, getProductsByCategory, getProductsByZone, getZones } from 'src/api';
import { citiesIsFetching, productsIsFetching, servicesIsFetching, setCities, setServices, setProducts } from './commonSlice';

export const getAllZones = () => async (dispatch: Function) => {
    try {
        dispatch(citiesIsFetching());
        const { data } = await getZones();
        if (data) {
            dispatch(setCities({
                data,
                success: true
            }));
        } else {
            dispatch(setCities({
                data: [],
                success: false
            }));
        }
    } catch (error) {
        console.log(error);
        dispatch(setCities({
            data: [],
            success: false
        }));
    }
};

export const getAllCategories = () => async (dispatch: Function) => {
    try {
        dispatch(servicesIsFetching());
        const { data } = await getCategories();
        if (data) {
            // filter only true status
            const newData = data.filter((item:any) => item.status);
            dispatch(setServices({
                data: newData,
                success: true
            }));
        } else {
            dispatch(setServices({
                data: [],
                success: false
            }));
        }
    } catch (error) {
        console.log(error);
        dispatch(setServices({
            data: [],
            success: false
        }));
    }
}

export const getProductsByCategoryId = (id: string) => async (dispatch: Function) => {
    try {
        dispatch(productsIsFetching());
        const { data } = await getProductsByCategory(id);
        if (data) {
            // filter only true status
            const newData = data.filter((item:any) => item.status);
            dispatch(setProducts({
                data: newData,
                success: true
            }));
        } else {
            dispatch(setProducts({
                data: [],
                success: false
            }));
        }
    } catch (error) {
        console.log(error);
        dispatch(setProducts({
            data: [],
            success: false
        }));
    }
}

export const getProductsByZoneId = (id: string) => async (dispatch: Function) => {
    try {
        dispatch(productsIsFetching());
        const { data } = await getProductsByZone(id);
        if (data) {
            // filter only true status
            const newData = data.filter((item:any) => item.status);
            dispatch(setProducts({
                data: newData,
                success: true
            }));
        } else {
            dispatch(setProducts({
                data: [],
                success: false
            }));
        }
    } catch (error) {
        console.log(error);
        dispatch(setProducts({
            data: [],
            success: false
        }));
    }
}
