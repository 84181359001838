import React, { useEffect, useState } from 'react';

import { glLettersLogo } from 'src/assets/images';

import styles from './termsgl.module.css';
import { Button, Col, Row, Table, TableColumnsType } from 'antd';
import dayjs from 'dayjs';
import Checkbox, { CheckboxChangeEvent } from 'antd/es/checkbox';

interface DataType {
    key: React.Key;
    service: JSX.Element;
    time: number;
    revenue: number;
}

interface TermsDataType {
    key: React.Key;
    name: string;
    city: string;
    date: string;
    acceptTerm: any;
}

const columns: TableColumnsType<DataType> = [
    {
        title: 'Servicios',
        dataIndex: 'service'
    },
    {
        title: 'Tiempo Estimado',
        dataIndex: 'time',
        render: (text: any) => `${text} min`
    },
    {
        title: 'Ganancia USD',
        dataIndex: 'revenue',
        render: (text: any) => `$${text} USD`
    }
];

const columnsTerms: TableColumnsType<TermsDataType> = [
    {
        title: 'Nombre',
        dataIndex: 'name'
    },
    {
        title: 'Ciudad',
        dataIndex: 'city',
    },
    {
        title: 'Fecha',
        dataIndex: 'date',
    },
    {
        title: 'Aceptar TyC',
        dataIndex: 'acceptTerm'
    }
];

const data: DataType[] = [
    {
        key: '1',
        service: <>
            <h3>Manicura & Pedicura Express</h3>
            <p>Eliminación de cuticulas y esmalte de uñas (base seca)</p>
        </>,
        time: 50,
        revenue: 70
    },
    {
        key: '2',
        service: <>
            <h3>Manicura & Pedicura</h3>
            <p>Remoción de cutícula y esmalte de uñas (base agua)</p>
        </>,
        time: 80,
        revenue: 65
    },
    {
        key: '3',
        service: <>
            <p>Esmaltes de uñas en manos y pies</p>
            <p>Esmaltes de uñas en gel de alta gama</p>
        </>,
        time: 60,
        revenue: 42
    },
    {
        key: '4',
        service: <>
            <h3>Manicura & Pedicura con Esmalte en Gel</h3>
            <p>Eliminación de cutícula y esmalte en gel (base agua)</p>
        </>,
        time: 120,
        revenue: 88
    }
]

interface TermsProps {
    loading?: boolean;
    onClickContinue: () => void;
}

const Es = ({ loading, onClickContinue }: TermsProps) => {
    const datas = localStorage.getItem('goodlookInfo');

    const [checkedTerms, setCheckedTerms] = useState(false);
    const [checkedTotallyTerms, setCheckedTotallyTerms] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    const onChangeTerms = (e: CheckboxChangeEvent) => {
        setCheckedTerms(e.target.checked);
    }

    const onChangeTotallyTerms = (e: CheckboxChangeEvent) => {
        setCheckedTotallyTerms(e.target.checked);
    }

    useEffect(() => {
        if (checkedTerms && checkedTotallyTerms)
            setIsDisabled(false);
        else
            setIsDisabled(true);
    }, [checkedTerms, checkedTotallyTerms])

    return (
        <div>
            <div className={styles.header_gl}>
                <img style={{ objectFit: 'contain' }} width={50} height={50} src={glLettersLogo} />
            </div>
            <div className={`container ${styles.card_gl}`}>
                <br />
                <h1>Acuerdo y Términos de Estilista Independiente</h1>
                <br />
                <p>Términos y condiciones que rigen la relación contractual entre GOOD LOOK USA, LLC (“Good Look”.”) Domicilio autorizado: 216 W. VILLAGE BLVD. SUITE 102-12 LAREDO, TX 78041 Correo electrónico autorizado: xxx y el que suscribe denominado como “El Estilista Independiente”.</p>
                <p>A continuación, nos referimos colectivamente a “Good Look” y a Estilista Independiente como las “Partes” y cada una de ellas se podrá nombrar como “Parte” en lo individual.</p>
                <p>Las partes aceptan y firman los acuerdos siguientes:</p>
                <ol>
                    <li>Edad Legal. El Estilista Independiente declara que es mayor de edad en el Estado donde se celebra el presente Acuerdo.</li>
                    <li>Certificación/Licencia: El Estilista Independiente declara que cuenta con Licencia activa y autorizada por el Estado donde prestará los servicios.</li>
                    <li>Acuerdo de Good Look. El Estilista Independiente está sujeto a la aceptación por parte de Good Look. Tras la aceptación por parte de Good Look, los términos y condiciones contenidos en este Acuerdo de Estilista Independiente, junto con las Políticas y Procedimientos de Good Look para Estilistas Independientes (las &quot;Políticas y Procedimientos&rdquo;), y la Política de Privacidad, todos los cuales se incorporan a este Acuerdo de Estilista Independiente por referencia, constituirán el acuerdo completo (el &ldquo;Acuerdo&rdquo;) entre Good Look y el Estilista Independiente.</li>
                    <li>Procedimiento de registro: Para comenzar el proceso de registro como estilista independiente con Good Look, se requiere proporcionar los siguientes documentos:
                        <ol type='a'>
                            <li>Número de Seguridad Social</li>
                            <li>Licencia de maquillista</li>
                            <li>Cuenta pública en redes sociales donde se refleje su trabajo (website, IG, Fb, Tiktok)</li>
                            <li>Fotos de herramientas que utilizarán en los servicios (maquillaje y peinado)</li>
                            <li>Credencial Oficial</li>
                            <li>Cuenta bancaria activa</li>
                        </ol>
                    </li>
                </ol>
                <br />
                <h1>Nails</h1>
                <Row>
                    <Col style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', border: 'solid', borderWidth: 0.5, borderColor: '#f0f0f0', paddingInline: 10 }} xs={24} sm={24} md={6} lg={6} xl={6}>
                        <h3>Manicura & Pedicura</h3>
                        <p>En nuestros servicios de manicura y pedicura ofrecemos alta calidad en productos de marcas como Organic, Jessica, Shelac, entre otros</p>
                    </Col>
                    <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                        <Table
                            columns={columns}
                            dataSource={data}
                            size='small'
                            pagination={false}
                        />
                    </Col>
                </Row>
                <br />
                <p>Transacciones Electrónicas (Uniform Electronic Transactions Act, UETA), ambas en vigor a nivel federal y estatal, las partes acuerdan lo siguiente: (i) Consentimiento para el Uso de Firmas Electrónicas: Las partes reconocen y aceptan el uso de firmas electrónicas como medios válidos para expresar su consentimiento y formalizar acuerdos. (ii) Definición de Firma Electrónica: Se entiende por &ldquo;firma electrónica&lrdquo; cualquier símbolo o proceso electrónico, asociado con un contrato u otro registro, adoptado por una persona con la intención de firmar el registro.(iii) Consentimiento Informado: Al firmar electrónicamente, cada parte manifiesta su consentimiento informado para utilizar la firma electrónica con respecto a todos los documentos, contratos y transacciones relacionadas con el presente acuerdo. Integridad del Documento: Las partes acuerdan que cualquier documento firmado electrónicamente tendrá la misma fuerza y efecto legal que un documento firmado físicamente. Asimismo, reconocen que las firmas electrónicas son vinculantes y que el documento electrónico conservará su integridad.</p>
                <br />
                <p>Al firmar electrónicamente, cada parte reconoce haber leído y entendido los términos de esta cláusula de firma electrónica, y manifiesta su consentimiento para proceder con la firma electrónica según lo dispuesto.</p>
                <br />
                <Table
                    size='small'
                    pagination={false}
                    columns={columnsTerms}
                    dataSource={datas ? [
                        {
                            key: '1',
                            name: `${JSON.parse(datas).name}`,
                            city: `${JSON.parse(datas).city}`,
                            date: dayjs().format('DD/MMM/YYYY'),
                            acceptTerm: <>
                                <Checkbox name='accept-terms' checked={checkedTerms} onChange={onChangeTerms}>
                                    He leído el documento y acepto las condiciones del mismo
                                </Checkbox>
                            </>
                        }
                    ] : []}
                />
                <br />
                <p>Transacciones Electrónicas (Uniform Electronic Transactions Act, UETA), ambas en vigor a nivel federal y estatal, las partes acuerdan lo siguiente: (i) Consentimiento para el Uso de Firmas Electrónicas: Las partes reconocen y aceptan el uso de firmas electrónicas como medios válidos para expresar su consentimiento y formalizar acuerdos. (ii) Definición de Firma Electrónica: Se entiende por &ldquo;firma electrónica&rdquo; cualquier símbolo o proceso electrónico, asociado con un contrato u otro registro, adoptado por una persona con la intención de firmar el registro.(iii) Consentimiento Informado: Al firmar electrónicamente, cada parte manifiesta su consentimiento informado para utilizar la firma electrónica con respecto a todos los documentos, contratos y transacciones relacionadas con el presente acuerdo. Integridad del Documento: Las partes acuerdan que cualquier documento firmado electrónicamente tendrá la misma fuerza y efecto legal que un documento firmado físicamente. Asimismo, reconocen que las firmas electrónicas son vinculantes y que el documento electrónico conservará su integridad.</p>
                <br />
                <p>Al firmar electrónicamente, cada parte reconoce haber leído y entendido los términos de esta cláusula de firma electrónica, y manifiesta su consentimiento para proceder con la firma electrónica según lo dispuesto.</p>
                <br />
                <div style={{ textAlign: 'center', backgroundColor: '#DDDBD1', paddingBlock: 10 }}>
                    <Checkbox name='accept-totally-terms' checked={checkedTotallyTerms} onChange={onChangeTotallyTerms}>
                        He leído en su totalidad el documento y acepto las condiciones del mismo
                    </Checkbox>
                </div>
                <br />
                <div style={{ textAlign: 'center', paddingBlock: 10 }}>
                    <Button
                        htmlType='submit'
                        size='large' type='primary' ghost
                        style={{ width: '35%' }}
                        disabled={isDisabled}
                        onClick={onClickContinue}
                        loading={loading}
                    >
                        Continuar
                    </Button>
                </div>
                <br />
                <br />
            </div>
        </div>
    )
}

export default Es