import { createSlice } from '@reduxjs/toolkit';
import { cartInitState } from '../schema';

export const cartSlice = createSlice({
    name: 'CART',
    initialState: cartInitState,
    reducers: {
        setProdCart: ({ data }, { payload }) => {
            // setear productos temporales
            const tempProd = data.products;
            // variable a agregar nuevo producto
            let newProds = [];
            // validar si existen productos
            if (tempProd.length > 0) {
                // validar si existe un producto con el mismo id
                if (tempProd.some((item) => { return item.id === payload.id })) {
                    // si existe, aumentar el numero de productoo
                    newProds = tempProd.map(i => {
                        if (i.id === payload.id) {
                            return {
                                ...payload,
                                numberProducts: i.numberProducts + 1
                            };
                        } else {
                            return i;
                        }
                    })
                } else {
                    // si no existe, crear nodeo
                    newProds = [...tempProd, payload];
                }
            } else {
                //crear nodo
                newProds = [payload]
            }
            // guardar en constante
            const newArr = newProds;
            // obtener monto
            const amount = newArr.reduce((acc, cv) => acc + (cv.cost * cv.numberProducts), 0);
            // setear en el state
            data.products = newArr;
            data.subTotal = amount;
            // data.total = (amount * .16) + amount;
            data.total = amount;
        },
        deleteProdCart: ({ data }, { payload }) => {
            // setear productos temporales
            const tempProd = data.products;
            // crear variable temporal donde se almacenaran los productos
            let newProds = [];
            // encontrar producto
            const prod = tempProd.find(i => i.id === payload.id);
            // si existe restamos numero de procuto
            if (prod) prod.numberProducts = prod.numberProducts - 1;
            // si existe y el numero de producto es mayor que sero
            if (prod && prod.numberProducts > 0) {
                // sustituir valor por el del prod, y setear los demas productos
                newProds = tempProd.map(i => {
                    if (i.id === payload.id) {
                        return prod;
                    } else {
                        return i;
                    }
                })
            } else {
                // si numero de productos es igual que cero, filtramos el id que se borro
                newProds = tempProd.filter(i => { if (i.id !== payload.id) return i});
            }
            // guardar en constante
            const newArr = newProds;
            // obtener monto
            const amount = newArr.reduce((acc, cv) => acc + (cv.cost * cv.numberProducts), 0);
            // actualizar state
            data.products = newArr;
            data.subTotal = amount;
            // data.total = (amount * .16) + amount;
            data.total = amount;
        },
        setDiscountCode: (state, { payload }) => {
            state.data.discountCode = payload;
        },
        setUserInfo: (state, { payload }) => {
            state.data.user = payload
        },
        setClearIntent: (state) => {
            state.data.products = [];
            state.data.discountCode = '';
            state.data.subTotal = 0;
            state.data.total = 0;
            state.data.user = {
                ...state.data.user,
                date: '',
                hour: '',
                alias: '',
                address: '',
                zipCode: '',
                addressMunicipality: '',
                neighboorhood: '',
                addressStreet: '',
                addressExtNumber: '',
                addressIntNumber: '',
                details: ''
            }
        },
        setResetCart: (state) => {
            state.data.products = [];
            state.data.discountCode = '';
            state.data.subTotal = 0;
            state.data.total = 0;
        }
    },
});

export const { deleteProdCart, setProdCart, setDiscountCode, setUserInfo, setClearIntent, setResetCart } = cartSlice.actions;
