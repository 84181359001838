import React from 'react';

import { ComplementLook } from '../compositions';
import { ShoppingInfo } from './shoppinginfo';

const Shopping = () => {
  return (
    <>
        <ShoppingInfo />
        <ComplementLook />
    </>
  )
}
export default Shopping;
