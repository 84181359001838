import React from 'react';

import { Col, Row } from 'antd';

import styles from './About.module.css';
import { aboutHero, glWhLettersLogo } from 'src/assets/images';

const AboutHero = () => {
  return (
    <Row className={styles.hero}>
        <Col className={styles.hero_col} xs={0} sm={0} md={0} lg={12} xl={12} xxl={12}>
            <img src={glWhLettersLogo} width={110} height={110} alt='gl-logo' />
        </Col>
        <Col
          // style={{
          //   backgroundImage: `url(${aboutHero})`,
          //   backgroundRepeat: 'no-repeat',
          //   backgroundSize: 'cover',
          //   backgroundAttachment: 'fixed',
          //   backgroundPosition: 'center',
          // }}
          xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}
        >
          <img
            src={aboutHero}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              float: 'left'
            }}
          />
        </Col>
    </Row>
  )
}
export default AboutHero;
