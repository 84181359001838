import { useEffect, useState } from 'react';

import { Button, Col, Modal, notification, Row } from 'antd';
import { useNavigate } from 'react-router-dom';

import { glLettersLogo } from 'src/assets/images';
import { Input, Select } from 'src/components';
import { useAppSelector } from 'src/hooks';
import { AppI } from 'src/redux';
import { emailValidation, ucFirst } from 'src/utils';

import formI18n from 'src/i18n/form.i18n.json';
import glI18n from 'src/i18n/goodlookero.i18n.json';
import notifI18n from 'src/i18n/notifications.i18n.json';

const options = [
    {
        value: 'sms',
        label: 'SMS',
    },
    {
        value: 'whatsapp',
        label: 'WhatsApp'
    },
    {
        value: 'email',
        label: 'E-mail'
    }
]

const FormGoodlookero = () => {
    const { lang } = useAppSelector(({ app }: { app: AppI }) => app);

    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();
    const [name, setName] = useState('');
    const [age, setAge] = useState('');
    const [email, setEmail] = useState('');
    const [link, setLink] = useState('');
    const [phone, setPhone] = useState('');
    const [preferMean, setPreferMean] = useState('00');
    const [city, setCity] = useState('');
    const [republicState, setRepublicState] = useState('');
    
    const [nameEmpty, setNameEmpty] = useState(false);
    const [ageEmpty, setAgeEmpty] = useState(false);
    const [linkEmpty, setLinkEmpty] = useState(false);
    const [emailEmpty, setEmailEmpty] = useState(false);
    const [phoneEmpty, setPhoneEmpty] = useState(false);
    const [cityEmpty, setCityEmpty] = useState(false);
    const [repStateEmpty, setRepStateEmpty] = useState(false);

    const [successModal, setSuccessModal] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);

    const handleSubmit = async (e:any) => {
        e.preventDefault();
        setSuccessModal(true);
        try {
            localStorage.setItem('goodlookInfo',JSON.stringify({
                name,
                age,
                email,
                phone,
                socialNetwork: link,
                city,
                republicState,
                preferMean
            }));
            navigate('/terminos-goodlookeros');
        } catch (error:any) {
            api.error({
                message: lang === 'es' ? 'Registro de Goodlookero' : 'Goodlookero Registration',
                description: `${notifI18n[lang].goodlookeroDescriptionNotification}. ${error.message}`,
                placement: 'topLeft',
                key: 'eventkey'
            });
        }
    }

    const handleOk = () => {
        setName('')
        setAge('')
        setEmail('')
        setLink('')
        setPhone('')
        setNameEmpty(false);
        setAgeEmpty(false);
        setLinkEmpty(false);
        setEmailEmpty(false);
        setSuccessModal(false);
        navigate('/', { replace: true });
    }

    useEffect(() => {
        if (name && age && email && link && phone && phone.replace(/ /g, '').length === 10 && preferMean !== '00' && city && republicState) {
            return setIsDisabled(false);
        }
        setIsDisabled(true);
    }, [name, email, phone, link, age, preferMean, city, republicState])

    return (
        <>
            {contextHolder}
            <div style={{ backgroundColor: '#FFF' }}>
                <div className='container p-5'>
                    <h2 style={{ textAlign: 'center' }}>{glI18n[lang].titleTerms}</h2>
                    <br />
                    <p style={{ paddingInline: '10%', textAlign: 'center', fontFamily: 'GothamLight', fontSize: 18 }}>{glI18n[lang].descriptionTerms}</p>
                    <br />
                    <form onSubmit={handleSubmit}>
                        <Row style={{ paddingInline: '10%' }} gutter={[10, 5]}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                <h3>{formI18n[lang].contactInfo}</h3>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }}>
                                <Select
                                    placeholder={`${formI18n[lang].selectPreferMean}*`}
                                    style={{ width: '100%' }}
                                    options={[{ value: '00', label: formI18n[lang].selectPreferMean }, ...options ]}
                                    onChange={(value) => {
                                        setPreferMean(value)
                                    }}
                                    value={preferMean}
                                />
                            </Col>
                        </Row>
                        <Row style={{ paddingInline: '10%' }} gutter={[10, 5]}>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                <Input type='string' id='name' placeholder={`${formI18n[lang].namePlaceholder}*`}
                                    value={name}
                                    onChange={(e) => {
                                        setName(e.target.value ? ucFirst(e.target.value) : '')
                                        if (e.target.value === '')
                                            return setNameEmpty(true);
                                            setNameEmpty(false);
                                    }}
                                    onBlur={() => { 
                                        if (name === '') 
                                            return setNameEmpty(true)
                                            setNameEmpty(false);
                                    }}
                                    typeColor={nameEmpty ? 'error' : 'default'}
                                    status={nameEmpty ? 'error' : ''}
                                    text={nameEmpty ? formI18n[lang].requiredText : ''}
                                />
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                <Input type='number' id='age' placeholder={`${formI18n[lang].agePlaceholder}*`}
                                    value={age}
                                    onChange={(e) => {
                                        setAge(e.target.value)
                                        if (e.target.value === '')
                                            return setAgeEmpty(true);
                                            setAgeEmpty(false);
                                    }}
                                    onBlur={() => { 
                                        if (age === '') 
                                            return setAgeEmpty(true)
                                        setAgeEmpty(false);
                                    }}
                                    typeColor={ageEmpty ? 'error' : 'default'}
                                    status={ageEmpty ? 'error' : ''}
                                    text={ageEmpty ? formI18n[lang].requiredText : ''}
                                />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                <Input type='email' id='email' placeholder={`${formI18n[lang].emailPlaceholder}*`}
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value.trim())
                                        if (e.target.value === '')
                                            return setEmailEmpty(true);
                                        setEmailEmpty(false);
                                    }}
                                    onBlur={() => { 
                                        if (email === '') 
                                            return setEmailEmpty(true)
                                        setEmailEmpty(false);
                                    }}
                                    typeColor={emailEmpty ? 'error' : email && emailValidation(email) ? 'default' : email !== '' ? 'error' : 'default'}
                                    status={emailEmpty ? 'error' : email && emailValidation(email) ? '' : email !== '' ? 'error' : ''}
                                    text={emailEmpty ? formI18n[lang].requiredText : email && emailValidation(email) ? '' : email !== '' ? formI18n[lang].formatError : ''}
                                />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                <Input
                                    type='tel' id='phone' placeholder={`${formI18n[lang].numberPlaceHolcer}*`}
                                    typeColor={phoneEmpty ? 'error' : phone && phone.indexOf('_') === -1 ? 'default' : phone !== '' ? 'error' : 'default'}
                                    status={phoneEmpty ? 'error' : phone && phone.indexOf('_') === -1 ? '' : phone !== '' ? 'error' : ''}
                                    text={phoneEmpty ? formI18n[lang].requiredText : ''}
                                    onChange={e => {
                                        const inputValue = e.target.value;

                                        // clean not numeric characters
                                        const cleanedValue = inputValue.replace(/\D/g, '')

                                        // apply mask for phone number
                                        let maskedValue = '';
                                        for (let i=0 ; i < cleanedValue.length ; i++) {
                                            if (i ===2 || i === 6) {
                                                maskedValue+= ' ';
                                            }
                                            maskedValue += cleanedValue[i];
                                        }
                                        setPhone(maskedValue)
                                        if (maskedValue === '' || maskedValue.replace(/ /g, '').length < 10)
                                            return setPhoneEmpty(true);
                                        setPhoneEmpty(false);
                                    }}
                                    onBlur={() => { 
                                        if (phone === '' || phone.replace(/ /g, '').length < 10)
                                            return setPhoneEmpty(true)
                                        setPhoneEmpty(false);
                                    }}
                                    value={phone}
                                    maxLength={12}
                                />
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                <Input
                                    placeholder={`${formI18n[lang].cityPlaceholder}*`}
                                    style={{ width: '100%' }}
                                    // options={[{ value: '00', label: formI18n[lang].selectCity }, ...options ]}
                                    onChange={(e) => {
                                        setCity(e.target.value ? ucFirst(e.target.value) : '');
                                        if (e.target.value === '')
                                            return setCityEmpty(true);
                                            setCityEmpty(false);
                                    }}
                                    value={city}
                                    onBlur={() => { 
                                        if (city === '') 
                                            return setCityEmpty(true)
                                            setCityEmpty(false);
                                    }}
                                    typeColor={cityEmpty ? 'error' : 'default'}
                                    status={cityEmpty ? 'error' : ''}
                                    text={cityEmpty ? formI18n[lang].requiredText : ''}
                                />
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                <Input
                                    placeholder={`${formI18n[lang].statePlaceholder}*`}
                                    style={{ width: '100%' }}
                                    // options={[{ value: '00', label: formI18n[lang].selectState }, ...options ]}
                                    onChange={(e) => {
                                        setRepublicState(e.target.value ? ucFirst(e.target.value) : '')
                                        if (e.target.value === '')
                                            return setRepStateEmpty(true);
                                            setRepStateEmpty(false);
                                    }}
                                    value={republicState}
                                    onBlur={() => { 
                                        if (republicState === '') 
                                            return setRepStateEmpty(true)
                                            setRepStateEmpty(false);
                                    }}
                                    typeColor={repStateEmpty ? 'error' : 'default'}
                                    status={repStateEmpty ? 'error' : ''}
                                    text={repStateEmpty ? formI18n[lang].requiredText : ''}
                                />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }} xxl={{ span: 12, offset: 6 }}>
                                <Input id='socialmedia' placeholder={`${formI18n[lang].socialMediaPlaceholder}*`}
                                    addonBefore='https://'
                                    value={link}
                                    onChange={(e) => {
                                        setLink(e.target.value ? e.target.value.trim() : '')
                                        if (e.target.value === '')
                                            return setLinkEmpty(true);
                                        setLinkEmpty(false);
                                    }}
                                    onBlur={() => { 
                                        if (link === '') 
                                            return setLinkEmpty(true)
                                        setLinkEmpty(false);
                                    }}
                                    typeColor={linkEmpty ? 'error' : 'default'}
                                    status={linkEmpty ? 'error' : ''}
                                    text={linkEmpty ? formI18n[lang].requiredText : ''}
                                />
                            </Col>
                            <Col style={{ textAlign: 'center'}} xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                <Button
                                    htmlType='submit'
                                    size='large' type='primary' ghost
                                    style={{ width: '35%' }}
                                    disabled={isDisabled}
                                >
                                    {formI18n[lang].btnSend}
                                </Button>
                            </Col>
                        </Row>
                    </form>
                </div>
            </div>
            <Modal
                open={successModal}
                onOk={handleOk}
                closable={false}
                centered
                footer={[
                    <Button
                        key="submit" type="primary"
                        onClick={handleOk}
                        ghost
                    >
                        {lang === 'es' ? 'Finalizar' : 'Finish'}
                    </Button>]
                }
            >
                <div style={{ textAlign: 'center'}}>
                    <img src={glLettersLogo} width={70} height={70} alt='gl-letters-logo' />
                    <br />
                    <br />
                    <h2>{lang === 'es' ? 'Gracias por comunicarte con Nosotros' : 'Thank you for communicating with us'}</h2>
                    <p>{lang === 'es' ? 'Nos comunicaremos contigo para más detalles' : 'We will contact you for more details'}</p>
                </div>
            </Modal>
        </>   
    )
}
export default FormGoodlookero;
