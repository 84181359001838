export const professionals = [
   // {
   //    professional: 'By Jazmin CDMX',
   //    url: 'https://goodlook-assets.s3.amazonaws.com/professionals/by-jamin-cdmx.jpg',
   // },
   {
      professional: 'By Adri Monterrey',
      url: 'https://goodlook-assets.s3.amazonaws.com/professionals/by-adri-mty.JPG',
   },
   {
      professional: 'By Ale Punta Mita',
      url: 'https://goodlook-assets.s3.amazonaws.com/professionals/by-ale-puntamita.JPG',
   },
   {
      professional: 'By Alexa Vallarta',
      url: 'https://goodlook-assets.s3.amazonaws.com/professionals/by-alexa-vallarta.JPG',
   },
   {
      professional: 'By Ana Morelia',
      url: 'https://goodlook-assets.s3.amazonaws.com/professionals/by-ana-morelia.JPG',
   },
   {
      professional: 'By Andie CDMX',
      url: 'https://goodlook-assets.s3.amazonaws.com/professionals/by-andie-cdmx.JPG',
   },
   {
      professional: 'By Erika Sn Luis Potosi',
      url: 'https://goodlook-assets.s3.amazonaws.com/professionals/by-erika-snluis.JPG',
   },
   {
      professional: 'By Grecia Cuernavaca',
      url: 'https://goodlook-assets.s3.amazonaws.com/professionals/by-grecia-cuerna.JPG',
   },
   {
      professional: 'By Isabel Veracruz',
      url: 'https://goodlook-assets.s3.amazonaws.com/professionals/by-isabel-veracruz.JPG',
   },
   {
      professional: 'By Ivette Ixtapa',
      url: 'https://goodlook-assets.s3.amazonaws.com/professionals/by-ivette-ixtapa.JPG',
   },
   {
      professional: 'By Karen CDMX',
      url: 'https://goodlook-assets.s3.amazonaws.com/professionals/by-karen-cdmx.JPG',
   },
   {
      professional: 'By Karime Torreon',
      url: 'https://goodlook-assets.s3.amazonaws.com/professionals/by-karime-torreon.JPG',
   },
   {
      professional: 'By Lupita Tijuana',
      url: 'https://goodlook-assets.s3.amazonaws.com/professionals/by-lupita-tijuana.JPG',
   },
   {
      professional: 'By Magda Ensenada',
      url: 'https://goodlook-assets.s3.amazonaws.com/professionals/by-magda-ensenada.JPG',
   },
   {
      professional: 'By Montse GDL',
      url: 'https://goodlook-assets.s3.amazonaws.com/professionals/by-montse-gdl-2.JPG',
   },
   {
      professional: 'By Montse GDL',
      url: 'https://goodlook-assets.s3.amazonaws.com/professionals/by-montse-gdl.JPG',
   },
   {
      professional: 'By Paola Leon',
      url: 'https://goodlook-assets.s3.amazonaws.com/professionals/by-paola-leon.JPG',
   },
   {
      professional: 'By Rebeca QRO',
      url: 'https://goodlook-assets.s3.amazonaws.com/professionals/by-rebeca-qro.JPG',
   },
   {
      professional: 'By Roy Leon',
      url: 'https://goodlook-assets.s3.amazonaws.com/professionals/by-roy-leon.JPG',
   },
   {
      professional: 'By Salma Ensenada',
      url: 'https://goodlook-assets.s3.amazonaws.com/professionals/by-salma-ensedada.JPG',
   },
   {
      professional: 'By Sandy GDL',
      url: 'https://goodlook-assets.s3.amazonaws.com/professionals/by-sandy-gdl.JPG',
   },

   {
      professional: 'By Vale Zacatecaz',
      url: 'https://goodlook-assets.s3.amazonaws.com/professionals/by-vale-zacatecas.JPG',
   },
];