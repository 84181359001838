import React, { useEffect, useState } from 'react';

import { Col, Row } from 'antd';

import AboutI18n from 'src/i18n/about.i18n.json';
import { useAppSelector } from 'src/hooks';
import { AppI } from 'src/redux';

const Corporate = () => {
    const { lang } = useAppSelector(({ app }: { app: AppI }) => app);

    const [width, setWidth] = useState(window.innerWidth);
    
    useEffect(() => {
        window.addEventListener('resize', () => {
            setWidth(window.innerWidth);
        })
        return () => window.removeEventListener('resize', () => {});
    }, [])

    return (
        <Row>
            <Col
                // style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 30, backgroundColor: '#FFFFFF', justifyContent: 'center', height: 350 }} 
                style={{ padding: '5px 0', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', backgroundColor: '#FFFFFF' }}
                xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}
            >
                <p style={{ width: width < 600 ? '80%' : '65%', fontFamily: 'GothamLight', fontWeight: 500, fontSize: '1.2em' }}>
                    {AboutI18n[lang].secondText}
                </p>
            </Col>
            {/* <Col xs={{ order: 1, span: 24 }} sm={{ order: 1, span: 24 }} md={{ order: 2, span: 12 }} lg={{ order: 2, span: 12 }} xl={{ order: 2, span: 12 }} xxl={{ order: 2, span: 12 }}>
                <img style={{ objectFit: 'cover' }} src={aboutOne} alt='about-corp-img' height={350} width={'100%'} />
            </Col>
            <Col xs={{ order: 3, span: 24 }} sm={{ order: 3, span: 24 }} md={{ order: 3, span: 12 }} lg={{ order: 3, span: 12 }} xl={{ order: 3, span: 12 }} xxl={{ order: 3, span: 12 }}>
                <img style={{ objectFit: 'cover' }} src={aboutTwo} alt='about-corp-img' height={350} width={'100%'} />
            </Col> */}
            <Col
                // style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 30, backgroundColor: '#FFFFFF', justifyContent: 'center', height: 350 }} 
                style={{ padding: '5px 0', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', backgroundColor: '#FFFFFF' }}
                xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}
            >
                <p style={{ width: width < 600 ? '80%' : '65%', fontFamily: 'GothamLight', fontWeight: 500, fontSize: '1.2em' }}>
                {AboutI18n[lang].tirdText}
                </p>
            </Col>
        </Row>
    )
}
export default Corporate;
