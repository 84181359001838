import axios from 'axios';

export const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

export const Interceptor = (store: any) => {
    /**
     * Request interceptor
     */
    api.interceptors.request.use((request: any) => {
        // TODO: Enable when token are added
        // const { data } = store.getState().user;
        // if ( useToken && data.token && request.headers)
        //   request.headers.Authorization = `Bearer ${data.token}`;
        return request;
    });

    /**
     * Response interceptor
     */
    api.interceptors.response.use((response) => response);
}

export const returningError = (error:any) => {
    if (error?.response) {
        const err = typeof(error?.response?.data) !== 'object' ?
            error?.response?.data
            :
            error?.response?.data?.errors[0] || JSON.stringify(error?.response?.data?.errors).replaceAll(/[{}]|\]|\[|"|status|/gi, '');
        return {
            data: '',
            errors: err,
            status: error?.response?.status,
            success: 'false'
        };
    }
    return {
        data: '',
        errors: error.message,
        status: 500,
        success: 'false'
    };
}
