import React from 'react';

import { Card, Tooltip } from 'antd';

import { glLogo, glEnLogo } from 'src/assets/images';
import SelectLanguage from './SelectLanguage';
import { LoginForm } from './compositions';

import styles from './Login.module.css';
import { getOption } from 'src/pages/compositions/getCommonComponent';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { AppI, setLocale, setLang } from 'src/redux';

const Login = () => {
    const { locale, lang } = useAppSelector(({ app }: { app: AppI }) => app);
    const dispatch = useAppDispatch();

    return (
        <Card className={styles.auth_card}>
            <img src={lang === 'es' ? glLogo : glEnLogo} alt='gl-logo' width={212} height={72} />
            <Tooltip
                placement='bottom'
                color='#FFFFFF'
                title={<SelectLanguage 
                    locale={locale}
                    lang={lang}
                    onChangeLocale={(value) => {
                        dispatch(setLocale(value))
                    }}
                    onChangeLang={(value) => dispatch(setLang(value))}
                />}
            >
                <div className={styles.select_locale}>
                    {getOption(locale, lang, styles.flags)}
                </div>
            </Tooltip>
            <LoginForm />
        </Card>
    )
}

export default Login