import React from 'react';

import { Col, Layout, Row, } from 'antd';
import { Outlet } from 'react-router-dom';
import styles from './AuthLayout.module.css';

const { Content } = Layout;

const AuthLayout = () => {
    return (
        <Layout className={styles.content}>
            <Content className='container'>
                <Row style={{ minHeight: '100vh', alignItems: 'center' }}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
                        <Outlet />
                    </Col>
                </Row>
            </Content>
        </Layout>
    )
}
export default AuthLayout;
