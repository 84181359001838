import React from 'react';

import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter as Router } from 'react-router-dom';

import { persistor, store } from 'src/redux';
import AppRouter from 'src/router';
import { ThemeProvider, createTheme } from '@mui/material';

const Providers = () => {
    const theme = createTheme({
        components: {
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        "&.Mui-focused": {
                            "& .MuiOutlinedInput-notchedOutline": {
                            border: `2px solid #866F5E`,
                            }
                        },
                        "& label": {
                            "&.Mui-focused": {
                              color: '#866F5E'
                            }
                        }
                    },
                  }
            }
        }
    });
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ThemeProvider theme={theme}>
                    <ConfigProvider theme={{
                        token: {
                            colorText: '#3A2D32',
                            colorPrimary: '#866F5E',
                            colorTextBase: '#3A2D32',
                            colorTextHeading: '#3A2D32',
                            colorBgContainer: '#FFFFFF'
                        }
                    }}>
                        <Router>
                            <AppRouter />
                        </Router>
                    </ConfigProvider>
                </ThemeProvider>
            </PersistGate>
        </Provider>
    )
}
export default Providers
