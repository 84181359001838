export const rated = [
    {
        id: 1,
        name: 'Gabriela Aguilar',
        description: `Súper buen servicio, muy puntuales y profesionales. Yohani muy amable y el maquillaje que nos hizo a mi mamá y a mi nos encantó, nos propuso ideas y colores que quedaron súper bien! Definitivamente las volvería a contratar.`,
        rated: 5
    },
    {
        id: 2,
        name: 'Dani Barrera',
        description: `Excelente servicio!! Altamente recomendado con Carla y Ariana en CDMX.`,
         rated: 5
    },
    {
        id: 3,
        name: 'Roxana Jiménez',
        description: `Encantada con el servicio de Alexa en Aguascalientes ♥️ Amé el resultado 👌🏼`,
        rated: 5
    },
    {
        id: 4,
        name: 'Cindy Sainz',
        description: `Excelente servicio, muy profesionales y amables, buen precio, 100% recomendable.`,
        rated: 5
    },
    {
        id: 5,
        name: 'Stephanie Lozano',
        description: `Muy buena atención y me gusto mucho como me maquillo y peinó, recomiendo ampliamente a Carla Baz`,
         rated: 5
    },
    {
        id: 6,
        name: 'Elizabeth Vazquez',
        description: `Súper recomendable el servicio de Good Look puntuales y profesionales me gusto muchísimo su servicio!`,
        rated: 5
    },
    {
        id: 7,
        name: 'Azucena Román',
        description: `Me encantó el servicio. Profesionales y súper amables.”`,
         rated: 5
    },
    {
        id: 8,
        name: 'Yesenia Gn',
        description: `Ale Sandoval en Punta Mita 100000/10. Mi maquillaje me encantó porque me lo dejó aún más bonito que la imagen de referencia, me duró TODO el día (a pesar del calor). Mi peinado fueron unas ondas súper bonitas. Sin duda excelente servicio.`,
        rated: 5
    },
    {
        id: 9,
        name: 'Karen Martínez',
        description: `Amé mucho mi servicio de maquillaje y peinado hecho por Daniela Zelaya. Es una persona muy amable, atenta, dedicada y escucha con atención lo que quieres en tu maquillaje. 100% recomendada ella y Good Look💕`,
        rated: 5
    },
    {
        id: 10,
        name: 'Elsa Ramos',
        description: `Super buen servicio. Me peinaron y maquillaron Esmeralda y Susana. Llegaron super puntuales y entendieron perfecto lo que quería. Me encanto.`,
        rated: 5
    },
    {
        id: 11,
        name: 'Jocelyn Rios',
        description: `Excelente servicio. Nos encantó el trabajo de Carla Baz y Arianda Hernández. Lo recomendamos mucho! `,
        rated: 5
    },
    {
        id: 12,
        name: 'Alejandra Escalona',
        description: `Muy buen servicio de Dalila en Peña de Bernal, todas muy felices con su trabajo 🙌🏻`,
         rated: 5
    },
    {
        id: 13,
        name: 'Susana Terán',
        description: `Siempre me ha gustado el servicio de Good Look. Esta vez fue con una maquillista llamada Sandy en Guadalajara para un bautizo y me encantó!`,
        rated: 5
    },
    {
        id: 14,
        name: 'Allison Cullen',
        description: `Me gustó mucho mi maquillaje hecho por Fernanda G en CDMX.`,
         rated: 5
    },
    {
        id: 15,
        name: 'Sofía Soto',
        description: `Me encanto el servicio dado por Metzli en Saltillo. Maquillaje que duró todo el dia. Peinado con mi pelo chino que quedo super. Llego a tiempo, estaba preparada, y super amable.`,
        rated: 5
    },
    {
        id: 16,
        name: 'Itzel PH',
        description: `Todo estuvo excelente. Las personas que me atendieron súper amables y profesionales. Cumplieron al 100% con mis expectativas.`,
        rated: 5
    },
    {
        id: 17,
        name: 'Aide Bello',
        description: `Excelente atención de Yohani y Elena, hermoso maquillaje y peinado, muchas gracias por todo 🥰👏🏻 100% recomendado el servicio.`,
        rated: 5
    },
    {
        id: 18,
        name: 'Jimena Gómez',
        description: `Me encantó el servicio y Adriana fue muy rápida en peinarme y maquillarme para quedar a tiempo para mi evento mi maquillaje me duró toda la noche!`,
         rated: 5
    },
    {
        id: 19,
        name: 'Cristina Chigüila',
        description: `Viajaba a México 🇲🇽 a un evento muy importante, me atreví a usar el servicio de Good Look y quede muy satisfecha. Recomiendo el trabajo de Jennifer Díaz, en Monterrey Nuevo León. Hermoso peinado y maquillaje excepcional. ♥️`,
        rated: 5
    },
    {
        id: 20,
        name: 'Eliza Ramirez',
        description: `Excelencia y calidad ✨ Me encantó mi peinado y los productos que utilizan 100/10 💖 me sentí muy contenta con su servicio 🤗`,
        rated: 5
    },
    {
        id: 21,
        name: 'Pilar Zepeda',
        description: `Buen servicio en general por el precio pagado. Buena atención. Puntualidad. Amabilidad. El maquillaje duró todo el evento!
        Nos atendió Fernanda G`,
        rated: 5
    }
]