import React from 'react';
import { Document, Page, StyleSheet, Text, View, Font } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { AppI } from 'src/redux';
import { useAppSelector } from 'src/hooks';
// const GB = require('./fonts/Gotham-Bold.ttf');
// const GL = require('./fonts/Gotham-Light.ttf');

// Font.register({
//     family: 'GothamBold',
//     format: "truetype",
//     src: GB,
//   });
  
// Font.register({
//     family: 'GothamLight',
//     format: "truetype",
//     src: GL,
// });
// Create styles
const styles = StyleSheet.create({
    page: {
        display: 'flex',
        flexDirection: 'column',
        padding: 30,
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
        margin: 10,
        padding: 10,
    },
    title: {
        fontSize: 20,
        // fontFamily: 'GothamBold',
        textAlign: 'center',
        marginBottom: 15,
    },
    subtitle: {
        fontSize: 15,
        // fontFamily: 'GothamBold',
        textAlign: 'center',
        marginBottom: 15,
    },
    text: {
        fontSize: 12,
        textAlign: 'justify',
        marginBottom: 10,
        // fontFamily: 'GothamLight',
        fontWeight: 'light'
    },
    textTable: {
        fontSize: 11,
        textAlign: 'left',
        // fontFamily: 'GothamLight',
        fontWeight: 'light'
    },
    li: {
        fontSize: 12,
        textAlign: 'left',
        // fontFamily: 'GothamLight',
        fontWeight: 'light',
        marginLeft: 25
    },
    divider: {
        width: '100%',
        marginBottom: 25
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        borderColor: '#e0e0e0',
        border: '1px solid'
    },
    col: {
        display: 'flex',
        flexDirection: 'column',
    }
});

interface TermsPdfI {
    locale?: 'es' | 'en' | string;
}

export const TermsPdf = ({ locale }: TermsPdfI) => {
    const datas = localStorage.getItem('goodlookInfo');

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <Text style={styles.title}>{'Acuerdo y Términos de Estilista Independiente'}</Text>
                    <Text style={styles.text}>{'Términos y condiciones que rigen la relación contractual entre GOOD LOOK USA, LLC (“Good Look”.”) Domicilio autorizado: 216 W. VILLAGE BLVD. SUITE 102-12 LAREDO, TX 78041 Correo electrónico autorizado: xxx y el que suscribe denominado como “El Estilista Independiente”.'}</Text>
                    <Text style={styles.text}>{'A continuación, nos referimos colectivamente a “Good Look” y a Estilista Independiente como las “Partes” y cada una de ellas se podrá nombrar como “Parte” en lo individual.'}</Text>
                    <Text style={styles.text}>{'Las partes aceptan y firman los acuerdos siguientes:'}</Text>
                    <Text style={{ ...styles.text, marginLeft: 10 }}>{'1. Edad Legal. El Estilista Independiente declara que es mayor de edad en el Estado donde se celebra el presente Acuerdo.'}</Text>
                    <Text style={{ ...styles.text, marginLeft: 10 }}>{'2. Certificación/Licencia: El Estilista Independiente declara que cuenta con Licencia activa y autorizada por el Estado donde prestará los servicios.'}</Text>
                    <Text style={{ ...styles.text, marginLeft: 10 }}>{'3. Acuerdo de Good Look. El Estilista Independiente está sujeto a la aceptación por parte de Good Look. Tras la aceptación por parte de Good Look, los términos y condiciones contenidos en este Acuerdo de Estilista Independiente, junto con las Políticas y Procedimientos de Good Look para Estilistas Independientes (las "Políticas y Procedimientos"), y la Política de Privacidad, todos los cuales se incorporan a este Acuerdo de Estilista Independiente por referencia, constituirán el acuerdo completo (el "Acuerdo") entre Good Look y el Estilista Independiente.'}</Text>
                    <Text style={{ ...styles.text, marginLeft: 10 }}>{'4. Procedimiento de registro: Para comenzar el proceso de registro como estilista independiente con Good Look, se requiere proporcionar los siguientes documentos:'}</Text>
                    <Text style={styles.li}>{'a. Número de Seguridad Social'}</Text>
                    <Text style={styles.li}>{'b. Licencia de maquillista'}</Text>
                    <Text style={styles.li}>{'c. Cuenta pública en redes sociales donde se refleje su trabajo (website, IG, Fb, Tiktok)'}</Text>
                    <Text style={styles.li}>{'d. Fotos de herramientas que utilizarán en los servicios (maquillaje y peinado)'}</Text>
                    <Text style={styles.li}>{'e. Credencial Oficial'}</Text>
                    <Text style={styles.li}>{`f. Dierección en ${locale === 'es' ? 'México' : 'USA'}`}</Text>
                    <Text style={styles.li}>{'g. Cuenta bancaria activa'}</Text>
                </View>
            </Page>
            <Page size={'A4'} style={styles.page}>
            <View style={styles.section}>
                    <Text style={styles.subtitle}>{'Nails'}</Text>
                    <View style={{ ...styles.row, minHeight: 280 }}>
                        <View style={{ ...styles.col, width: '30%', justifyContent: 'center', paddingLeft: 10, paddingRight: 10 }}>
                            <Text style={{ ...styles.textTable, textAlign: 'center' }}>{'Manicure & Pedicure'}</Text>
                            <Text style={styles.textTable}>{'En nuestros servicios de manicura y pedicura ofrecemos alta calidad en productos de marcas como Organic, Jessica, Shelac entre otros'}</Text>
                        </View>
                        <View style={{ ...styles.col, width: '70%' }}>
                            <View style={{ ...styles.row, border: 'none', backgroundColor: '#d4d4d4' }}>
                                <View style={{ ...styles.col, width: '33.33%', height: 40, justifyContent: 'center', alignItems: 'center' }}>
                                    <Text style={{ ...styles.text, marginBottom: 0 }}>{'Servicios'}</Text>
                                </View>
                                <View style={{ ...styles.col, width: '33.33%', height: 40, justifyContent: 'center', alignItems: 'center' }}>
                                    <Text style={{ ...styles.text, marginBottom: 0 }}>{'Tiempo Estimado'}</Text>
                                </View>
                                <View style={{ ...styles.col, width: '33.33%', height: 40, justifyContent: 'center', alignItems: 'center' }}>
                                    <Text style={{ ...styles.text, marginBottom: 0 }}>{'Ganancia USD'}</Text>
                                </View>
                            </View>
                            <View style={{ ...styles.row, border: 'none' }}>
                                <View style={{ ...styles.col, width: '33.33%', padding: 10, justifyContent: 'center', alignItems: 'center', borderLeftWidth: 1, borderLeftColor: '#e0e0e0', borderLeft: 'solid' }}>
                                    <Text style={{ ...styles.textTable, marginBottom: 0 }}>{'Manicure & Pedicure Express'}</Text>
                                    <Text style={{ ...styles.textTable, marginBottom: 0 }}>{'Eliminación de cutículas y esmalte de uñas (base seca)'}</Text>
                                </View>
                                <View style={{ ...styles.col, width: '33.33%', justifyContent: 'center', alignItems: 'center',borderLeftWidth: 1, borderLeftColor: '#e0e0e0', borderLeft: 'solid' }}>
                                    <Text style={{ ...styles.textTable, marginBottom: 0 }}>{'50 min'}</Text>
                                </View>
                                <View style={{ ...styles.col, width: '33.33%', justifyContent: 'center', alignItems: 'center',borderLeftWidth: 1, borderLeftColor: '#e0e0e0', borderLeft: 'solid' }}>
                                    <Text style={{ ...styles.textTable, marginBottom: 0 }}>{'$70'}</Text>
                                </View>
                            </View>
                            <View style={{ ...styles.row, border: 'none' }}>
                                <View style={{ ...styles.col, width: '33.33%', padding: 10, justifyContent: 'center', alignItems: 'center', borderLeftWidth: 1, borderLeftColor: '#e0e0e0', borderLeft: 'solid' }}>
                                    <Text style={{ ...styles.textTable, marginBottom: 0 }}>{'Manicure & Pedicure'}</Text>
                                    <Text style={{ ...styles.textTable, marginBottom: 0 }}>{'Remoción de cutícula y esmalte de uñas (base agua)'}</Text>
                                </View>
                                <View style={{ ...styles.col, width: '33.33%', justifyContent: 'center', alignItems: 'center', borderLeftWidth: 1, borderLeftColor: '#e0e0e0', borderLeft: 'solid' }}>
                                    <Text style={{ ...styles.textTable, marginBottom: 0 }}>{'80 min'}</Text>
                                </View>
                                <View style={{ ...styles.col, width: '33.33%', justifyContent: 'center', alignItems: 'center', borderLeftWidth: 1, borderLeftColor: '#e0e0e0', borderLeft: 'solid' }}>
                                    <Text style={{ ...styles.textTable, marginBottom: 0 }}>{'$65'}</Text>
                                </View>
                            </View>
                            <View style={{ ...styles.row, border: 'none' }}>
                                <View style={{ ...styles.col, width: '33.33%', padding: 10, justifyContent: 'center', alignItems: 'center', borderLeftWidth: 1, borderLeftColor: '#e0e0e0', borderLeft: 'solid' }}>
                                    <Text style={{ ...styles.textTable, marginBottom: 0 }}>{'Manos y Pies'}</Text>
                                    <Text style={{ ...styles.textTable, marginBottom: 0 }}>{'Esmaltes de uñas en manos y pies'}</Text>
                                    <Text style={{ ...styles.textTable, marginBottom: 0 }}>{'Esmaltes de uñas en gel de alta gama'}</Text>
                                </View>
                                <View style={{ ...styles.col, width: '33.33%', justifyContent: 'center', alignItems: 'center', borderLeftWidth: 1, borderLeftColor: '#e0e0e0', borderLeft: 'solid' }}>
                                    <Text style={{ ...styles.textTable, marginBottom: 0 }}>{'60 min'}</Text>
                                </View>
                                <View style={{ ...styles.col, width: '33.33%', justifyContent: 'center', alignItems: 'center', borderLeftWidth: 1, borderLeftColor: '#e0e0e0', borderLeft: 'solid' }}>
                                    <Text style={{ ...styles.textTable, marginBottom: 0 }}>{'$42'}</Text>
                                </View>
                            </View>
                            <View style={{ ...styles.row, border: 'none' }}>
                                <View style={{ ...styles.col, width: '33.33%', padding: 10, justifyContent: 'center', alignItems: 'center', borderLeftWidth: 1, borderLeftColor: '#e0e0e0', borderLeft: 'solid' }}>
                                    <Text style={{ ...styles.textTable, marginBottom: 0 }}>{'Manicure & Pedicure esmalte en gel'}</Text>
                                    <Text style={{ ...styles.textTable, marginBottom: 0 }}>{'Eliminación de cutícula y esmalte en gel (base agua)'}</Text>
                                </View>
                                <View style={{ ...styles.col, width: '33.33%', justifyContent: 'center', alignItems: 'center', borderLeftWidth: 1, borderLeftColor: '#e0e0e0', borderLeft: 'solid' }}>
                                    <Text style={{ ...styles.textTable, marginBottom: 0 }}>{'120 min'}</Text>
                                </View>
                                <View style={{ ...styles.col, width: '33.33%', justifyContent: 'center', alignItems: 'center', borderLeftWidth: 1, borderLeftColor: '#e0e0e0', borderLeft: 'solid' }}>
                                    <Text style={{ ...styles.textTable, marginBottom: 0 }}>{'$88'}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
                <View style={styles.section}>
                    <Text style={styles.text}>{'Transacciones Electrónicas (Uniform Electronic Transactions Act, UETA), ambas en vigor a nivel federal y estatal, las partes acuerdan lo siguiente: (i) Consentimiento para el Uso de Firmas Electrónicas: Las partes reconocen y aceptan el uso de firmas electrónicas como medios válidos para expresar su consentimiento y formalizar acuerdos. (ii) Definición de Firma Electrónica: Se entiende por "firma electrónica" cualquier símbolo o proceso electrónico, asociado con un contrato u otro registro, adoptado por una persona con la intención de firmar el registro.(iii) Consentimiento Informado: Al firmar electrónicamente, cada parte manifiesta su consentimiento informado para utilizar la firma electrónica con respecto a todos los documentos, contratos y transacciones relacionadas con el presente acuerdo. Integridad del Documento: Las partes acuerdan que cualquier documento firmado electrónicamente tendrá la misma fuerza y efecto legal que un documento firmado físicamente. Asimismo, reconocen que las firmas electrónicas son vinculantes y que el documento electrónico conservará su integridad.'}</Text>
                    <Text style={styles.text}>{'Al firmar electrónicamente, cada parte reconoce haber leído y entendido los términos de esta cláusula de firma electrónica, y manifiesta su consentimiento para proceder con la firma electrónica según lo dispuesto.'}</Text>
                </View>
            </Page>
            <Page size={'A4'} style={styles.page}>
                <View style={styles.section}>
                    <View style={{ ...styles.row, border: 'none', backgroundColor: '#d4d4d4' }}>
                        <View style={{ ...styles.col, width: '40%', height: 40, justifyContent: 'center', alignItems: 'center' }}>
                            <Text style={{ ...styles.text, marginBottom: 0 }}>{'Nombre'}</Text>
                        </View>
                        <View style={{ ...styles.col, width: '20%', height: 40, justifyContent: 'center', alignItems: 'center' }}>
                            <Text style={{ ...styles.text, marginBottom: 0 }}>{'Ciudad'}</Text>
                        </View>
                        <View style={{ ...styles.col, width: '20%', height: 40, justifyContent: 'center', alignItems: 'center' }}>
                            <Text style={{ ...styles.text, marginBottom: 0 }}>{'Fecha'}</Text>
                        </View>
                        <View style={{ ...styles.col, width: '40%', height: 40, justifyContent: 'center', alignItems: 'center' }}>
                            <Text style={{ ...styles.text, marginBottom: 0 }}>{'Aceptar TyC'}</Text>
                        </View>
                    </View>
                    <View style={{ ...styles.row, borderColor: '#e0e0e0' }}>
                        <View style={{ ...styles.col, width: '40%', padding: 10, justifyContent: 'center', alignItems: 'center' }}>
                            <Text style={{ ...styles.textTable, marginBottom: 0 }}>{`${JSON.parse(datas || '{}').name}`}</Text>
                        </View>
                        <View style={{ ...styles.col, width: '20%', justifyContent: 'center', alignItems: 'center',borderLeftWidth: 1, borderLeftColor: '#e0e0e0', borderLeft: 'solid' }}>
                            <Text style={{ ...styles.textTable, marginBottom: 0 }}>{`${JSON.parse(datas || '{}').city}`}</Text>
                        </View>
                        <View style={{ ...styles.col, width: '20%', justifyContent: 'center', alignItems: 'center',borderLeftWidth: 1, borderLeftColor: '#e0e0e0', borderLeft: 'solid' }}>
                            <Text style={{ ...styles.textTable, marginBottom: 0 }}>{dayjs().format('DD/MMM/YYYY')}</Text>
                        </View>
                        <View style={{ ...styles.col, width: '40%', justifyContent: 'center', alignItems: 'center',borderLeftWidth: 1, borderLeftColor: '#e0e0e0', borderLeft: 'solid' }}>
                            <Text style={{ ...styles.textTable, marginBottom: 0 }}>{'✅ He leído el documento y acepto las condiciones del mismo'}</Text>
                        </View>
                    </View>
                </View>
                <View style={styles.section}>
                    <Text style={styles.text}>{'Transacciones Electrónicas (Uniform Electronic Transactions Act, UETA), ambas en vigor a nivel federal y estatal, las partes acuerdan lo siguiente: (i) Consentimiento para el Uso de Firmas Electrónicas: Las partes reconocen y aceptan el uso de firmas electrónicas como medios válidos para expresar su consentimiento y formalizar acuerdos. (ii) Definición de Firma Electrónica: Se entiende por "firma electrónica" cualquier símbolo o proceso electrónico, asociado con un contrato u otro registro, adoptado por una persona con la intención de firmar el registro.(iii) Consentimiento Informado: Al firmar electrónicamente, cada parte manifiesta su consentimiento informado para utilizar la firma electrónica con respecto a todos los documentos, contratos y transacciones relacionadas con el presente acuerdo. Integridad del Documento: Las partes acuerdan que cualquier documento firmado electrónicamente tendrá la misma fuerza y efecto legal que un documento firmado físicamente. Asimismo, reconocen que las firmas electrónicas son vinculantes y que el documento electrónico conservará su integridad.'}</Text>
                    <Text style={styles.text}>{'Al firmar electrónicamente, cada parte reconoce haber leído y entendido los términos de esta cláusula de firma electrónica, y manifiesta su consentimiento para proceder con la firma electrónica según lo dispuesto.'}</Text>
                </View>
                <View style={{ ...styles.section, marginTop: 0 }}>
                    <View style={{ backgroundColor: '#d4d4d4', display: 'flex', flexDirection: 'column', paddingTop: 5, paddingBottom: 5 }}>
                        <Text style={{ ...styles.text, marginBottom: 0, textAlign: 'center' }}>{'✅ He leído en su totalidad el documento y acepto las condiciones del mismo'}</Text>
                    </View>
                </View>
            </Page>
        </Document>
    )
}
// export default TermsPdf;/