import { postSignIn } from "src/api";
import { UserRequestI, userSchema } from "../schema";
import { setUser, userIsFetching } from "./userSlice";

export const userLogin = (body: UserRequestI) => async (dispatch: Function) => {
    try {
        dispatch(userIsFetching());
        const { data, success } = await postSignIn(body);
        if (success === 'true') {
            delete data.password;
            return dispatch(setUser({
                data,
                success: true
            }));
        }
        dispatch(setUser({
            data: userSchema,
            success: false
        }));
    } catch (error:any) {
        dispatch(setUser({
            data: userSchema,
            success: false
        }));
    }
}