import React, { useEffect } from 'react'

import { OurProfessionals } from 'src/pages/compositions';
import Detail from './detail';

const Services = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [])

    return (
        <>
            <Detail />
            <OurProfessionals />
        </>
    )
}
export default Services;
