import { createSlice } from '@reduxjs/toolkit'
import { AppI } from '../schema';

const appInitState:AppI = {
    locale: 'es',
    lang: 'es',
}

export const appSlice = createSlice({
    name: 'APP',
    initialState: appInitState,
    reducers: {
        setLocale: (state, { payload }) => {
            state.locale = payload
        },
        setLang: (state, { payload }) => {
            state.lang = payload;
        }
    },
});

export const { setLocale, setLang } = appSlice.actions;
