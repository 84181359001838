import react from 'react';

import dayjs from 'dayjs';
import 'dayjs/locale/es-mx';

dayjs.locale('es-mx');

const Es = () => {

   return (
      <div className='container'>
         <div className='m-5'>
            <h1 className='text-center'>Terminos de Uso Good Look</h1>
            <hr />
            <h3 style={{ textTransform: 'capitalize' }}>Fecha: {dayjs().format('DD/MMM/YYYY')}</h3>
            <div className='text-center'>
               <p>POR FAVOR LEA LA SIGUIENTE INFORMACI&Oacute;N CUIDADOSAMENTE.</p>
               <p>AL UTILIZAR EL SITIO, USTED ACEPTA ESTOS &ldquo;T&Eacute;RMINOS DE USO&rdquo;; SI NO EST&Aacute; DE ACUERDO, NO SE SUSCRIBA, NI CONTRATE LOS SERVICIOS QUE AQU&Iacute; SE OFRECEN.</p>
            </div>
            <div className='text-justified'>
               <p>Los actuales T&eacute;rminos y Condiciones, en adelante <strong>&quot;T&eacute;rminos de Uso&quot;,</strong> regulan su acceso y uso de los Servicios proporcionados por la empresa <strong>Good Look USA, LLC</strong>, en lo sucesivo <strong>&quot;Good Look&quot; (&quot;nosotros&quot;, &quot;nuestro&quot; y &quot;nosotros&quot;)&nbsp;</strong>a trav&eacute;s de su Sitio Web <a href='https://www.goodlook.com.mx' target='_blank' rel='noreferrer'><strong>https://www.goodlook.com.mx</strong></a>. Est&aacute;n sujetos a su aceptaci&oacute;n, sin modificaciones, de todos los t&eacute;rminos y condiciones contenidos en este documento, as&iacute; como de todas las dem&aacute;s reglas operativas, pol&iacute;ticas (incluida, entre otras, la Pol&iacute;tica de Privacidad) y procedimientos que podamos publicar ocasionalmente.</p>
               <p>Este documento constituye un acuerdo de v&iacute;nculo legal-comercial entre usted y &quot;Good Look&quot; (definido m&aacute;s adelante). Antes de utilizar la plataforma de Good Look, es importante que conozca sus derechos y obligaciones, as&iacute; como los de Good Look y los prestadores de servicios. Una vez solicitados los servicios, estos &quot;T&eacute;rminos de Uso&quot; ser&aacute;n aplicables para ambas partes. Usted acepta que ESTE ACUERDO (&ldquo;T&Eacute;RMINOS DE USO&rdquo;) IMPLICA LA RENUNCIA A PARTICIPAR EN DEMANDAS COLECTIVAS Y RENUNCIA A JUICIOS CON JURADO. ADEM&Aacute;S, ESTABLECE QUE LAS DISPUTAS ENTRE LAS PARTES SE RESOLVER&Aacute;N MEDIANTE ARBITRAJE VINCULANTE Y DEFINITIVO, DE MANERA INDIVIDUAL Y NO COLECTIVA.</p>
               <p>Al aceptar estos <strong>&quot;T&eacute;rminos de Uso&quot;,</strong> el usuario confirma ser mayor de edad, poseer la capacidad legal requerida y asume plena responsabilidad por las obligaciones derivadas del uso de nuestros servicios, realizando esta aceptaci&oacute;n de manera consciente y voluntaria.&nbsp;</p>
               <p>Usted reconoce que los servicios proporcionados en <a href='https://www.goodlook.com.mx' target='_blank' rel='noreferrer'><strong>https://www.goodlook.com.mx</strong></a> est&aacute;n dise&ntilde;ados como intermediaci&oacute;n entre <strong>&ldquo;Proveedores de Servicio&rdquo;&nbsp;</strong>y <strong>&ldquo;Usuarios&rdquo;&nbsp;</strong>(ambos conceptos definidos en la secci&oacute;n de definiciones), facilitando la organizaci&oacute;n de servicios de belleza a domicilio, entre otros. Es importante destacar que <strong>&quot;Good Look&quot;</strong>, as&iacute; como sus representantes legales y/o trabajadores, no act&uacute;an como proveedores de servicios de belleza, sino que desempe&ntilde;an el papel de mediadores entre especialistas en este &aacute;mbito y usted, como bien se detalla en el cuerpo de este documento.&nbsp;</p>
               <p>Estos<strong>&nbsp;&quot;T&eacute;rminos de Uso&quot;&nbsp;</strong>se aplican exclusivamente a los <strong>&quot;Usuarios&quot;.&nbsp;</strong>Si usted es un <strong>&quot;Proveedor de Servicios&quot;,</strong> deber&aacute; seguir las pautas establecidas en sus acuerdos y reglamentos individuales.</p>
               <p>Los presentes &ldquo;<strong>T&eacute;rminos de Uso&rdquo;&nbsp;</strong>se aplican exclusivamente en los Estados Unidos de Am&eacute;rica, y su &aacute;mbito de aplicaci&oacute;n geogr&aacute;fica se limita a las ubicaciones mencionadas en este documento.</p>
            </div>
            <div>
               <h3>CONTENIDO DE LOS &ldquo;T&Eacute;RMINOS DE USO&rdquo;:</h3>
               <ol>
                  <li><a href='#definiciones'>Definiciones</a></li>
                  <li><a href='#alcance'>Alcance de Aplicación de los Servicios</a></li>
                  <li><a href='#naturalezanegocio'>Naturaleza del Negocio</a></li>
                  <li><a href='#visitantesyusuarios'>Responsabilidad de los Visitantes y Usuarios</a></li>
                  <li><a href='#registrousuario'>Registro de Usuario/Suscripción</a></li>
                  <li><a href='#comprasypagos'>Condiciones de compras y pagos</a></li>
                  <li><a href='#cancelacionreembolsos'>Cancelación y Reembolsos</a></li>
                  <li><a href='#vigencia'>Vigencia</a></li>
                  <li><a href='#fallas'>Fallas en el Sistema</a></li>
                  <li><a href='#derechosylicencias'>Derechos y Licencias</a></li>
                  <li><a href='#limitacion'>Limitación de la Responsabilidad</a></li>
                  <li><a href='#propiedadintelectual'>Propiedad Intelectual</a></li>
                  <li><a href='#conflictosyarbitraje'>Resolución de Conflictos y Arbitraje</a></li>
                  <li><a href='#jurisdiccion'>Jurisdicción</a></li>
                  <li><a href='#preguntas'>Preguntas, Quejas o Reclamos</a></li>
               </ol>
            </div>
            <div className='text-justified'>
               <h2 id='definiciones'>1. DEFINICIONES</h2>
               <br />
               <p>1.1. Para los efectos de los presentes &ldquo;T&eacute;rminos de Uso&rdquo; se entiende como:</p>
               <ol type='a' className='text-justified'>
                  <li><strong>Cuenta: </strong>Registro personal en línea que permite a un usuario acceder y utilizar los servicios de &ldquo;Good Look&rdquo;. Contiene información única de identificación, como un nombre de usuario y contraseña.</li>
                  <li><strong>Cita/Reserva de Cita: </strong>A la acción del Usuario de elegir una hora y fecha especifica para recibir los servicios de maquillaje y/o peinado que se ofrecen en el catálogo de servicios del &ldquo;Sitio&rdquo; en la dirección proporcionada por el usuario, dentro de la limitación geográfica mencionada posteriormente.</li>
                  <li><strong>Catálogo de Servicios: </strong> Es la sección o página del &ldquo;Sitio&rdquo; que contine una lista de los servicios que ofrecen los &ldquo;Prestadores de Servicios&rdquo; a través del &ldquo;Sitio&rdquo; de &ldquo;Good Look&rdquo;. Esta sección puede incluir detalles como descripciones de los servicios, precios, forma de pago, términos y condiciones, imágenes y cualquier otra información relevante.</li>
                  <li><strong>Datos del Servicio: </strong> Se refiere al conjunto de información que incluye nombre, domicilio, contacto de usuario en el que <strong>&ldquo;El Prestador de Servicios&rdquo;</strong> asistirá a una hora y fecha específica elegida por el Usuario, a fin de prestar los servicios de maquillaje y/o peinado establecidos en el catálogo de servicios del &ldquo;Sitio&rdquo;.</li>
                  <li><strong>Good Look: </strong>A la empresa denominada Good Look USA, LLC, con domicilio autorizado en 261 W Village, BLVD, SUITE 102-12, Laredo, Texas, 78041.</li>
                  <li><strong>&ldquo;El Sitio&rdquo; / &ldquo;Sitio&rdquo;: </strong>Se refiere al sitio web con direccion <a href='https://goodlook.com.mx' rel='noreferrer' target='_blank'>https://www.goodlook.com.mx</a>, así como a los programas, aplicaciones y/o servicios ofrecidos por <strong>&ldquo;Good Look&rdquo;</strong>, que incluyen, pero no se limitan a, este sitio web, cualquier servicio de mensajería instantánea, cualquier mini sitio, aplicaciones futuras y cualquier aplicación auxiliar para el uso o acceso del servicio ofrecido por <strong>&ldquo;Good Look&rdquo;</strong>, ya sea de manera conjunta o individual.</li>
                  <li><strong>Prestadores de Servicios: </strong>Los estilistas y maquilladores, tras llegar a un acuerdo previo con &ldquo;Good Look USA, LLC&rdquo;, han definido meticulosamente los términos y condiciones que rigen la prestación de sus servicios a los usuarios. Cualquier atención al usuario y comentarios proporcionados por estos profesionales son de caracter personal y se llevan a cao bajo su única responsabilidad, eximiendo a &ldquo;Good Look&rdquo; de cualquier implicación al respecto.</li>
                  <li><strong>Terceros proveedores/ Proveedor de Pagos</strong>: Hace referencia a plataformas de pagos independientes de <strong>Good Look</strong>. Estos son servicios externos utilizados para gestionar y procesar transacciones financieras, como pagos, de forma separada a la plataforma principal de <strong>Good Look</strong>. Estos proveedores pueden ofrecer servicios especializados de procesamiento de pagos y act&uacute;an como entidades externas que facilitan transacciones financieras de manera segura y eficiente. Cabe destacar que <strong>Good Look</strong> no est&aacute; vinculado a ellos ni asume responsabilidad directa o indirecta en el uso, funcionamiento y seguridad de dichas plataformas.</li>
                  <li><strong>Usuario y/o Usuarios:</strong> Se refiere a la persona f&iacute;sica que accede, se beneficia y/o solicita alguno de los servicios de la Plataforma <strong>&quot;Good Look&quot;</strong>, por cualquier motivo que sea.</li>
               </ol>
               <p>1.2. Los términos en mayúscula, pero no definidos en el presente, tendrán los significados adjudicados a ellos en el Aviso de Privacidad y/o en los &ldquo;Términos de Uso&rdquo; aquí previstos.</p>
               <br />
               <h2 id='alcance'>2. ALCANCE DE APLICACIÓN Y SU RESPONSABILIDAD</h2>
               <br/>
               <p>2.1. El alcance de aplicaci&oacute;n de los servicios del <strong>&ldquo;El Sitio&rdquo;</strong> se limita exclusivamente a las siguientes ciudades: i. Los Angeles, California; ii. San Diego, California; iii. Manhattan, New York; iv. Dallas, Texas; v. San Antonio, Texas y vi. Miami, Florida, de los Estados Unidos de Am&eacute;rica.</p>
               <p>2.2. Los servicios proporcionados a trav&eacute;s del <strong>&ldquo;Sitio&rdquo;&nbsp;</strong>se encuentran disponibles &uacute;nicamente en estas ubicaciones especificadas, quedando fuera de su &aacute;mbito de aplicaci&oacute;n cualquier otra &aacute;rea geogr&aacute;fica.</p>
               <p>2.3. Si el domicilio de la cita que Usted desea agendar se ubica fuera de estas ciudades, abstengase a usar, agendar o solicitar cualquier tipo de cita a trav&eacute;s de nuestro <strong>&quot;Sitio&quot;.</strong></p>
               <p>2.4. Si solicita servicios en la ciudad de Miami en el estado de Florida, debe aceptar que la raz&oacute;n de su solicitud est&aacute; relacionada con la industria cinematogr&aacute;fica, la fotograf&iacute;a de moda, el teatro, la televisi&oacute;n, trabajos de estudio de fotograf&iacute;a, eventos especiales como bodas o desfiles de moda, demostraciones en ferias comerciales, seminarios educativos, o debido a problemas de salud que le impidan acudir a un sal&oacute;n autorizado.</p>
               <p>2.5. En caso de solicitar servicios en el estado de California en las ciudades Los &Aacute;ngeles y San Diego, debe aceptar que el prop&oacute;sito de su solicitud es incidental a la industria de producci&oacute;n de teatro, radio, televisi&oacute;n o pel&iacute;culas; necesario debido a una enfermedad u otra incapacidad f&iacute;sica o mental; o con el objetivo de recibir recomendaciones y/o demostraciones en vivo sobre productos.</p>
               <p>2.6. Estas declaraciones se utilizan para cumplir con los requisitos legales y regulaciones en estos estados y para garantizar que el usuario est&aacute; utilizando los servicios de belleza a domicilio de manera adecuada y legal.</p>
               <p>2.7. Nuestros proveedores de servicios son verificados para asegurarnos de que cuenten con las licencias, certificaciones y procesos adecuados para prestar los servicios, de acuerdo con las regulaciones estatales y nacionales correspondientes. Sin embargo, &ldquo;Good Look&rdquo; no se hace responsable del resultado final de los servicios.</p>
               <br />
               <h2 id='naturalezanegocio'>3. NATURALEZA DEL NEGOCIO</h2>
               <br/>
               <p>3.1. &ldquo;Good Look&rdquo; es operado por la distinguida empresa Good Look, USA LLC, registrada en el Estado de Texas.</p>
               <p>3.2. &ldquo;Good Look&rdquo; opera como una plataforma de mediaci&oacute;n comercial que conecta a profesionales de la belleza con clientes que buscan servicios de peinado y maquillaje a domicilio. Nos especializamos en facilitar encuentros armoniosos entre talentosos expertos en belleza y aquellos que buscan servicios personalizados y convenientes en la comodidad de su domicilio.</p>
               <p>3.3. A trav&eacute;s de nuestro &ldquo;Sitio&rdquo;, en &ldquo;Good Look&rdquo; ponemos a disposici&oacute;n del usuario una herramienta que simplifica la reserva de citas y facilita la elecci&oacute;n de servicios que ser&aacute;n brindados directamente en su domicilio. Nuestra plataforma est&aacute; dise&ntilde;ada para hacer que el proceso de programaci&oacute;n sea conveniente y personalizado, permitiendo a los usuarios seleccionar f&aacute;cilmente los servicios deseados y gestionar las citas de manera eficiente. Estamos comprometidos en hacer que la experiencia de reserva sea sencilla y agradable para nuestros usuarios.</p>
               <p>3.4. &ldquo;Good Look&rdquo; se presenta como una plataforma comercial que facilita la conexi&oacute;n entre profesionales de la belleza y clientes interesados en sus servicios. Es crucial destacar que &ldquo;Good Look&rdquot no act&uacute;a como el empleador de los prestadores de servicios y no mantiene ninguna relaci&oacute;n laboral con ellos. Los profesionales de belleza que ofrecen sus servicios a trav&eacute;s de nuestra plataforma son independientes y aut&oacute;nomos, asumiendo la plena responsabilidad de la calidad de los servicios proporcionados.</p>
               <p>3.5. Para garantizar la integridad y confiabilidad de nuestra plataforma, nos reservamos el derecho de revisar los antecedentes de los prestadores de servicios antes de permitirles ofrecer sus servicios a través de &ldquo;Good Look&rdquo;. Asimismo, requerimos que cada profesional firme un contrato que establece los términos y condiciones para prestar sus servicios, asegurando un marco claro y mutuo para todas las partes involucradas. En &ldquo;Good Look&rdquo;, nos esforzamos por fomentar una relación transparente y de confianza entre los profesionales de la belleza y nuestros valiosos clientes.</p>
               <p>3.6. Los proveedores de servicios son contratistas independientes y no deben considerarse empleados, representantes ni agentes de &ldquo;Good Look&rdquo;.</p>
               <br />
               <h2 id='visitantesyusuarios'>4. RESPONSABILIDAD DE LOS VISITANTES Y USUARIOS</h2>
               <br/>
               <p>4.1. El <strong>Usuario</strong> debe leer, entender y aceptar todas las condiciones establecidas en los presentes <strong>Términos y Condiciones</strong> generales, así como en el Aviso de Privacidad, al igual que en los demás documentos y ligas incorporados a los mismos por referencia, previo al acceso y uso como <strong>Usuario</strong>.</p>
               <p>4.2. El incumplimiento a cualquiera de los términos y condiciones que a continuación se detallan, resultará en la terminación inmediata del presente acuerdo, imputable al <strong>Usuario</strong> y, por consiguiente, de su Cuenta.</p>
               <p>4.3. Los Servicios que publica <strong>&ldquo;Good Look&rdquo;</strong>, sólo están disponibles para personas que tengan capacidad legal para contratar. No podrán utilizar los servicios las personas que no tengan esa capacidad, los <strong>Usuarios</strong> que hayan sido suspendidos temporalmente o inhabilitados definitivamente. Así pues:</p>
               <ol type='a'>
                  <li><strong>Cualquier Usuario, debe tener 18 años o más para usar este servicio, y tener permiso legal bajo las leyes de los Estados Unidos de América</strong></li>
                  <li>Debes ser humano. Las cuentas registradas por &ldquo;bots&rdquo; u otros métodos automatizados no están permitidas.</li>
                  <li>Debe proporcionar su nombre completo legal, una dirección de correo electrónico válida y cualquier otra información solicitada para completar el proceso de registro.</li>
                  <li>Usted es responsable de mantener la seguridad de su cuenta y contraseña. <strong>&ldquo;Good Look&rdquo;</strong> no puede y no será responsable de ninguna pérdida o daño por no cumplir con esta obligación de seguridad.</li>
                  <li>Usted es responsable de la información de contacto, de pago y de su ubicación que proporcione para las citas. Es decir, Usted es responsable de su propio correo electrónico, domicilio, contraseña, usuario, tarjeta de débito y/o de crédito ingresada en cualquier campo del &ldquo;Sitio&rdquo; y de los terceros proveedores conectados a <strong>&ldquo;Good Look&rdquo;</strong>.</li>
                  <li>Sitios, aplicaciones y anuncios de terceros: El <strong>&ldquo;Sitio&rdquo;</strong> puede tener enlaces a sitios web, aplicaciones, servicios y anuncios de terceros, pero no tenemos control sobre ellos y no somos responsables de su contenido. Los proporcionamos sólo para su conveniencia y no revisamos, aprobamos, monitoreamos, respaldamos, garantizamos ni hacemos ninguna representación sobre ellos. Usted los utiliza bajo su propia responsabilidad y debe revisar los términos y políticas del tercero, incluyendo sus prácticas de privacidad y recopilación de datos. <strong>&ldquo;Good Look&rdquo;</strong> trabaja con socios publicitarios que pueden enviarle anuncios en nuestro sitio web o fuera de él, basándose en sus actividades en el sitio o dentro de los servicios.</li>
                  <li>En ningún momento debe utilizar el &ldquo;Sitio&rdquo;, los servicios aquí publicados, las citas reservadas o el contacto con los &ldquo;Prestadores de Servicios&rdquo; con fines ilegales, inmorales o no autorizados.</li>
                  <li>Al utilizar el Servicio de <strong>&ldquo;Good Look&rdquo;</strong>, no debe infringir leyes, tratados, ni disposiciones legales estatales y nacionales en su jurisdicción, incluyendo, pero sin limitarse a, las leyes de Propiedad Intelectual.</li>
                  <li></li>
               </ol>
               <p>4.4. Todas las obligaciones, prohibiciones, limitaciones, permisos, autorizaciones, y demás disposiciones previstas dentro del presente contrato para el uso tanto de las páginas de internet de <strong>&ldquo;Good Look&rdquo;</strong> como de los servicios que <strong>&ldquo;Good Look&rdquo;</strong> ofrece, son de carácter obligatorio y vinculantes para el <strong>&ldquo;Usuario&rdquo;</strong>.</p>
               <p>
                  4.5. <strong>Responsabilidad del Usuario en el Domicilio.</strong><br />
                  Usted como cliente/Usuario beneficiario de los servicios de belleza a domicilio contratados a través del &ldquo;Sitio&rdquo; de <strong>&ldquo;Good Look&rdquo;</strong>, usted tiene ciertas responsabilidades. Tales como se describen a continuación:
               </p>
               <ol type='i'>
                  <li>Debe cumplir con los requisitos del proveedor de servicios aplicables al servicio adquirido, crear un espacio de trabajo seguro, limpio y cómodo.</li>
                  <li>Es importante que usted y cualquier otra persona o mascota presente en el domicilio se abstengan de cualquier comportamiento que pueda resultar ofensivo mientras el proveedor de servicios está presente.</li>
                  <li>Solicitar servicios que no se incluyen en el servicio contratado en el catálogo del &ldquo;Sitio&rdquo;.</li>
                  <li>Solicitar servicios para más personas sin haber reservado y pagado la cita con anticipación.</li>
                  <li>Solicitar al proveedor de servicios el cambio de domicilio.</li>
                  <li>Si tiene mascotas o hijos menores de 13 años, debe confinarlos a otra habitación.</li>
                  <li>En caso de que el usuario presente una enfermedad viral grave, como el COVID-19 u otras similares, el proveedor de servicios se reserva el derecho de cancelar el servicio.</li>
                  <li>El Usuario debe informar previamente al proveedor de servicios sobre cualquier enfermedad de la piel, cabello o reacciones alérgicas que puede surgir en consecuencia de los servicios prestados.</li>
                  <li>Los proveedores de servicios se reservan el derecho de cancelar o negarse a brindar sus servicios si no cumple con estas responsabilidades o si el ambiente de trabajo no es seguro o cómodo para el proveedor de servicios.</li>
               </ol>
               <br />
               <h2 id='registrousuario'>5. REGISTRO DE USUARIO/SUSCRIPCIÓN</h2>
               <br/>
               <p>
                  5.1. Registro y Datos Personales <br />
                  Con la finalidad de brindar el servicio correctamente al Usuario, “El Sitio” contará con las siguientes opciones de (i) registro de cuenta y (ii) realizar la reserva de citas a través de la opción de invitado. <br /><br />
                  Es obligatorio completar el formulario de suscripción con información válida personal (&ldquo;Datos Personales&rdquo;) para utilizar los servicios de &ldquo;Good Look&rdquo;.<br /> <br />
                  El Usuario se compromete a proporcionar &ldquo;Datos Personales&rdquo; exactos, precisos y verdaderos, asumiendo la responsabilidad de actualizarlos. <strong>&ldquo;Good Look&rdquo;</strong> no se responsabiliza por la certeza de los Datos Personales del Usuario.
               </p>
               <p>
                  5.2. Verificacion de Datos:<br />
                  Es responsabilidad del Usuario verificar que sus datos personales estén correctos antes de realizar la reserva de la cita y el pago de los servicios.<br /><br />
                  En caso de que el usuario proporcione información incorrecta, es importante señalar que cualquier consecuencia resultante será responsabilidad exclusiva del usuario. Ni Good Look ni el proveedor de servicios serán responsables de ningún daño o resultado negativo que pueda surgir como resultado de dicha información incorrecta. Se recomienda que los usuarios tomen las medidas necesarias para garantizar que toda la información proporcionada sea precisa y esté actualizada para evitar cualquier problema potencial en el futuro.
               </p>
               <p>
                  5.3. Acceso y Responsabilidad de la Cuenta:<br />
                  El Usuario accede a su cuenta personal mediante su nombre de Usuario Seudónimo junto con la Clave de Seguridad personal elegida, &ldquo;Good Look&rdquo; se reserva el derecho de cancelar, suspender o inhabilitar cuentas duplicadas o  relacionadas.<br /><br />
                  El usuario es responsable de todas las operaciones que se realicen en su cuenta. Es importante que proteja su nombre de usuario y contraseña, y notifique a <strong>&ldquo;Good Look&rdquo;</strong> si sospecha alguna actividad no autorizada. <strong>&ldquo;Good Look&rdquo;</strong> no se hace responsable de ninguna pérdida o daño debido a la falta de seguridad en la cuenta del usuario.
               </p>
               <p>
                  5.4. Prohibición de Transferencia de Cuentas:<br />
                  Está prohibida la venta, cesión o transferencia de la cuenta a terceros.
               </p>
               <br />
               <h2 id='comprasypagos'>6. CONDICIONES DE SERVICIOS Y PAGOS</h2>
               <br/>
               <p>6.1. El acceso al <strong>&ldquo;Sitio&rdquo;</strong> es gratuito, pero tenga en cuenta que <strong>&ldquo;El Sitio&rdquo;</strong> es una plataforma que conecta a los proveedores de servicios con los usuarios y que los servicios ofrecidos tienen un costo y un precio establecido.</p>
               <p>6.2. El catálogo de los servicios se encontrará en la pestaña denominada <strong>&ldquo;servicios&rdquo;</strong>, dentro del &ldquo;Sitio&rdquo;, sin embargo alguno de ellos podrán ser mostrados en la página principal del <strong>&ldquo;Sitio&rdquo;</strong>, en las redes sociales y en publicidad en diversos medios.</p>
               <p>6.3. Las fotos que se muestran en el catálogo de servicios son solo ilustrativas del servicio que se ofrece. El Usuario y el proveedor de servicios acordarán mutuamente el estilo y el concepto del servicio. En algunos casos, el usuario puede otorgar libertad creativa al proveedor de servicios, pero es responsabilidad exclusiva del Usuario hacerlo.</p>
               <p>6.4. Las tarifas de los servicios se muestran en la sección de servicios del “Sitio”. El costo varía según la ciudad y el tipo de servicio seleccionados por el cliente. En algunos casos, <strong>&ldquo;Good Look&rdquo;</strong> puede ofrecer descuentos, los cuales serán determinados a su discreción.</p>
               <p>6.5. La disponibilidad de servicios en una fecha y hora específicas está sujeta a la agenda de los &ldquo;Proveedores de Servicios&rdquo;, por lo que no se permite a los usuarios solicitar un proveedor de servicios en particular. A pesar de esto, los &ldquo;Proveedores de Servicios&rdquo; se esforzarán por cumplir con las preferencias del usuario en la medida de lo posible.</p>
               <p>6.6. Al realizar una compra en el &ldquo;Sitio&rdquo;, deberá confirmar su compra con el &ldquo;Proveedor de Pago&rdquo; correspondiente a fin de asegurar su reserva de cita. El cargo se realizará a su método de pago, ya sea a través de su tarjeta o mediante depósito, según lo designe el proveedor de pagos (&ldquo;Método de pago&rdquo;). <strong>&ldquo;Good Look&rdquo;</strong> utiliza los servicios de terceros proveedores para solicitar los pagos de los servicios, tal como la plataforma Stripe® (&ldquo;Proveedor de Pago&rdquo;).</p>
               <p>6.7. Si el pago no se ha realizado correctamente, no se asegura ni reserva la cita. Es responsabilidad del usuario asegurarse de que el pago se haya procesado correctamente y de que se haya recibido una confirmación de la reserva de la cita. Si el pago no se procesa correctamente o se rechaza por cualquier motivo, la cita no se reservará y el usuario deberá realizar el pago correctamente para asegurar la reserva de la cita. En caso de que el usuario experimente algún problema con el pago, deberá ponerse en contacto con su banco o proveedor de pagos para resolver el problema y asegurarse de que el pago se procese correctamente. <strong>&ldquo;Good Look&rdquo;</strong> no será responsable de ningún problema relacionado con el procesamiento de pagos, incluyendo pero no limitado a la falta de confirmación de reserva de la cita debido a un problema con el pago.</p>
               <p>6.8. Los precios correspondientes a los servicios que ha seleccionado, así como cualquier impuesto sobre las ventas u otros cargos relacionados que pudieran aplicarse a sus pagos, serán claramente indicados en el monto final durante el proceso de pago proporcionado a través de la &ldquo;Plataforma de Pago&rdquo;.</p>
               <p>6.9. Cualquier objeción a un pago ya realizado debe ser dirigida al servicio de atención al cliente si <strong>&ldquo;Good Look&rdquo;</strong> o la cuenta de terceros proveedores, como Stripe® (&ldquo;Proveedor de Pago&rdquo;), le facturan directamente. También puede presentar una objeción de pago si se pone en contacto con su banco o proveedor de pagos, quien podrá proporcionar información adicional sobre sus derechos y los plazos aplicables.</p>
               <p>6.10. En algunas ocasiones, se ofrecerá el pago diferido de los servicios, pero la responsabilidad de este proceso recae en el banco emisor de la cuenta del usuario y en la plataforma de pago correspondiente.</p>
               <p>6.11. En caso de que el usuario presente un contracargo o una disputa con su banco o proveedor de pagos por cualquier motivo, &ldquo;Good Look&rdquo; se reserva el derecho de suspender o cancelar su cuenta de forma inmediata y a su entera discreción. Además, el usuario será responsable de cualquier costo o gasto relacionado con el contracargo o la disputa, incluyendo cualquier tarifa o cargo impuesto por el banco o el proveedor de pagos. <strong>&ldquo;Good Look&rdquo;</strong> se reserva el derecho de tomar cualquier acción legal o de otro tipo que considere necesaria para recuperar cualquier cantidad adeudada en relación con el contracargo o la disputa.</p>
               <p>6.12. <strong>&ldquo;Good Look&rdquo;</strong> se reserva expresamente el derecho de cambiar o modificar sus precios y tarifas en cualquier momento, y tales cambios o modificaciones se publicarán en línea en este Sitio, y los mismos tendrán vigencia en forma inmediata sin necesidad de que le enviemos ninguna otra notificación.</p>
               <p>6.13. Si por algún motivo no puede cargar las tarifas a su tarjeta o realizar el depósito, puede enviarnos un correo electrónico a <a href='mailto:hello@goodlook.com.mx' rel='noreferrer' target='_blank'>hello@goodlook.com.mx</a> Le enviaremos un correo con la información de pago y para que pueda realizar la reserva de la cita a través del pago correspondiente. Sin embargo, no podemos asegurar la disponibilidad del día y hora deseados mientras se procesa el pago.</p>
               <p>6.14. <strong>Códigos promocionales: &ldquo;Good Look&rdquo;</strong> puede, a su criterio, crear códigos promocionales que se pueden canjear por crédito en su cuenta u otros beneficios relacionados con los servicios, sujetos a los términos adicionales establecidos para cada código promocional <strong>(&ldquo;Códigos promocionales&rdquo;)</strong>. A menos que se indique expresamente lo contrario en los términos adicionales, los códigos promocionales solo se pueden usar una vez por persona y no se pueden utilizar varios códigos promocionales en una sola transacción o cita. Además, cada beneficio del código promocional solo se puede canjear una vez por cuenta. No debe alterar, duplicar, vender, transferir o distribuir códigos promocionales de ninguna manera, a menos que lo permitamos expresamente. Podemos desactivar los códigos promocionales en cualquier momento y por cualquier motivo sin responsabilidad alguna para con nosotros. Los códigos promocionales no tienen valor en efectivo y pueden caducar antes de su uso.</p>
               <br />
               <h2 id='cancelacionreembolsos'>7. CANCELACIÓN Y REEMBOLSOS</h2>
               <br/>
               <p>7.1. Usted puede cancelar cualquier cita de acuerdo con nuestra política de cancelación. La cancelación aplicará sólo en los siguientes casos: </p>
               <ol type='i'>
                  <li>Política de Cancelación por COVID-19: Además de los derechos de cancelación establecidos en la política de cancelación de redactados en el presente documento, Usted debe cancelar una cita a través de los servicios si tiene tos, fiebre u otros síntomas de COVID-19, si ha dado positivo para COVID-19 o si se siente mal en general. Puede hacerlo en cualquier momento hasta la hora de inicio de la cita sin penalización alguna. “Good Look” se reserva el derecho de revisar o actualizar esta política de cancelación complementaria en cualquier momento, incluso para cubrir otras epidemias o enfermedades. Para ello deberá de enviar el comprobante médico que justifique dicha enfermedad. En caso de que se presente este tipo de situación, se le otorgará un crédito por la cantidad pagada por los servicios, excluyendo la tarifa o impuesto y los pagos de servicios por el uso de la plataforma de terceros proveedores, a fin de que sea usado en otra cita.</li>
                  <li>Si el proveedor de servicios cancela una cita por cualquier motivo, en este caso se dará aviso al Usuario y se buscará otro Prestador de Servicios disponible en el día y horario señalado.</li>
                  <li>Si el proveedor de servicios no se presenta a una cita previamente pagada.</li>
                  <li>Si &ldquo;Good Look&rdquo; no puede reservar otro proveedor de servicios aceptable para el usuario durante el horario programado de la cita, la cita se cancelará por completo. En este caso, se reembolsará al usuario el monto pagado por los servicios seleccionados, pero la tarifa de comisión por el uso de la plataforma de terceros proveedores no se incluirá en el reembolso.</li>
               </ol>
               <p>7.2. Si <strong>&ldquo;Good Look&rdquo;</strong> descubre que ha abusado de esta política de cancelación por COVID-19, descrita en el punto (i) anterior, se reserva el derecho de suspender o cancelar su cuenta de manera inmediata y a su entera discreción, tanto si es un cliente como si es un proveedor de servicios. En caso de cancelación, <strong>&ldquo;Good Look&rdquo;</strong> no reembolsará ninguna tarifa pagada previamente.</p>
               <p>7.3. En caso de que se presente una situación no descrita en esta cláusula, no se aplicará la tarifa de reembolso y <strong>&ldquo;Good Look&rdquo;</strong> decidirá a su discreción cómo se tratará el pago. En tal caso, el usuario debe enviar un correo electrónico a <a href='mailto:hello@goodlook.com.mx' rel='noreferrer' target='_blank'>hello@goodlook.com.mx</a> para recibir atención al cliente.</p>
               <p>7.4. Para solicitar un reembolso sólo en los casos aplicables en la presente cláusula, Usted deberá de enviar un correo electrónico <a href='mailto:hello@goodlook.com.mx' rel='noreferrer' target='_blank'>hello@goodlook.com.mx</a> para recibir atención al cliente.</p>
               <br />
               <h2 id='vigencia'>8. VIGENCIA</h2>
               <br/>
               <p>8.1. Estos Términos de Uso estarán vigentes desde el momento en que se activen los Servicios hasta que: (1) sea rescindido según lo dispuesto por este Acuerdo o por cualquier adición a este Acuerdo o (2) sea reemplazado por un Acuerdo revisado.</p>
               <br />
               <h2 id='fallas'>9. FALLAS EN EL SISTEMA</h2>
               <br/>
               <p>9.1. <strong>&ldquo;Good Look&rdquo;</strong> no se responsabiliza por cualquier daño, perjuicio o pérdida al <strong>Usuario</strong> causados por fallas en el sistema, en el servidor o en Internet.</p>
               <p>9.2. <strong>&ldquo;Good Look&rdquo;</strong> tampoco será responsable por cualquier virus que pudiera infectar el equipo del <strong>Usuario</strong>.</p>
               <p>9.3. <strong>&ldquo;Good Look&rdquo;</strong> no garantiza el acceso y uso continuado o ininterrumpido de su sitio. El sistema puede eventualmente no estar disponible debido a dificultades técnicas o fallas de Internet, o por cualquier otra circunstancia ajena a <strong>&ldquo;Good Look&rdquo;</strong> en tales casos se procurará restablecerlo con la mayor celeridad posible sin que por ello pueda imputarse algún tipo de responsabilidad.</p>
               <p>9.4. <strong>&ldquo;Good Look&rdquo;</strong> no será responsable por ningún error u omisión contenidos en el <strong>&ldquo;Sitio&rdquo;</strong></p>
               <p>9.5. Usted acuerda y entiende que, <strong>&ldquo;Good Look&rdquo;</strong> puede actualizar las versiones del <strong>&ldquo;Sitio&rdquo;</strong></p>
               <p>9.6. La falta de actualización de estas versiones conforme al párrafo que antecede pudiera provocar fallas.</p>
               <br />
               <h2 id='derechosylicencias'>10. DERECHOS Y LICENCIAS</h2>
               <br/>
               <p>10.1. Usted tiene una licencia intransferible y no exclusiva para acceder y utilizar los servicios para su uso personal.</p>
               <p>10.2. No puede licenciar, vender, alquilar, transferir, distribuir, alojar ni explotar comercialmente, ni revender los servicios.</p>
               <p>10.3. No puede modificar, realizar trabajos derivados, desensamblar, compilar ni realizar ingeniería inversa en ninguna parte de los servicios.</p>
               <p>10.4. No puede acceder a los servicios para crear un servicio similar o competitivo.</p>
               <p>10.5. Todos los derechos de propiedad intelectual, incluyendo derechos de autor, patentes, marcas comerciales y secretos comerciales, en los servicios, incluyendo el sitio y las aplicaciones futuras, son de <strong>&ldquo;Good Look&rdquo;</strong> o de nuestros licenciantes.</p>
               <p>10.6. Nos reservamos el derecho de modificar, suspender o discontinuar el acceso a los servicios con o sin previo aviso.</p>
               <p>10.7. No tendremos ninguna obligación de brindar soporte o mantenimiento en relación con los servicios.</p>
               <p>10.8. La prestación de los servicios no transfiere a usted ni a ningún tercero ningún derecho, título o interés sobre los derechos de propiedad intelectual.</p>
               <p>10.9. Nosotros y nuestros proveedores nos reservamos todos los derechos no otorgados en este acuerdo.</p>
               <br />
               <h2 id='limitacion'>11. LIMITACIÓN DE LA RESPONSABILIDAD</h2>
               <br/>
               <p>11.1. En la medida máxima permitida por la ley, no seremos responsables ante usted o terceros por ninguna pérdida de beneficios o daño indirecto, consecuente, ejemplar, incidental, especial o punitivo, que surja o se relacione con este acuerdo por los servicios incluso si se nos ha informado de la posibilidad de tales daños.</p>
               <p>11.2. Los límites de nuestra responsabilidad se limitarán a la mayor de las siguientes opciones: (a) cincuenta dólares estadounidenses ($50); o (b) las cantidades que haya pagado a &ldquo;Good Look&rdquo; en los seis meses anteriores (si las hay). La existencia de más de una reclamación no ampliará este límite. Además, acepta que nuestros proveedores no tendrán responsabilidad de ningún tipo que surja o se relacione con este acuerdo.</p>
               <p>11.3. Esto significa que, en caso de que se produzca algún daño o pérdida que sea nuestra responsabilidad, nuestra responsabilidad estará limitada a la mayor de las dos opciones mencionadas anteriormente. Además, cualquier reclamación adicional no ampliará este límite. También acepta que nuestros proveedores no tendrán ninguna responsabilidad en relación con este acuerdo.</p>
               <p>11.4. Los Clientes comprenden y aceptan que el Estilista es independiente y no está asociado con Good Look. En consecuencia, el Profesional y/o Good Look asume responsabilidad por daños, pérdidas o lesiones que puedan surgir durante o después de la prestación de servicios, salvo en casos de negligencia comprobada. Los Clientes liberan al Profesional y/o Good Look de cualquier reclamación y eximen de responsabilidad por cualquier incidente que pueda ocurrir durante o después de la provisión de servicios de peluquería.</p>
               <p>11.5. Para casos en específico Usted deberá enviar correo electrónico a <a href='mailto:hello@goodlook.com.mx' rel='noreferrer' target='_blank'>hello@goodlook.com.mx</a></p>
               <br />
               <h2 id='propiedadintelectual'>12. PROPIEDAD INTELECTUAL</h2>
               <br/>
               <p>12.1. Los contenidos en el “Sitio” relativas a los servicios de <strong>&ldquo;Good Look&rdquo;</strong> como así también los programas, bases de datos, redes, archivos que permiten al <strong>Usuario</strong> acceder y usar su Cuenta, son de propiedad de <strong>&ldquo;Good Look&rdquo;</strong> y están protegidas por las leyes nacionales y los tratados internacionales de derecho de autor, marcas, patentes, modelos y diseños industriales. El uso indebido y la reproducción total o parcial de dichos contenidos quedan prohibidos, salvo autorización expresa y por escrito de <strong>&ldquo;Good Look&rdquo;</strong>.</p>
               <p>12.2. El Sitio puede contener enlaces a otros sitios web lo cual no indica que sean propiedad u operados por <strong>&ldquo;Good Look&rdquo;</strong> En virtud que <strong>&ldquo;Good Look&rdquo;</strong> no tiene control sobre tales sitios, NO será responsable por los contenidos, materiales, acciones y/o servicios prestados por los mismos, ni por daños o pérdidas ocasionadas por la utilización de los mismos, sean causadas directa o indirectamente. La presencia de enlaces a otros sitios web no implica una sociedad, relación, aprobación, respaldo de <strong>&ldquo;Good Look&rdquo;</strong> a dichos sitios y sus contenidos.</p>
               <br />
               <h2 id='conflictosyarbitraje'>13. RESOLUCIÓN DE CONFLICTOS Y ARBITRAJE</h2>
               <br/>
               <p>13.1. Resolución de disputas: Este acuerdo establece que cualquier disputa, reclamación o solicitud de compensación relacionada con el acceso o uso del &ldquo;Sitio&ldquo; o los servicios adquiridos a través del &ldquo;Sitio&rdquo; debe resolverse mediante acuerdos vinculantes de arbitraje, salvo en casos especiales como reclamos en tribunales de reclamos menores o por infracción de propiedad intelectual.</p>
               <p>13.2. El arbitraje se regirá por las Reglas y Procedimientos de Arbitraje de JAMS y se llevará a cabo por JAMS o por otro foro de arbitraje si JAMS no está disponible. El árbitro tendrá la autoridad para otorgar daños monetarios y cualquier remedio o compensación no monetario disponible según la ley aplicable. Los reclamos deben presentarse de manera individual y no se permiten reclamos colectivos.</p>
               <p>13.3. Usted tiene el derecho de excluirse de las disposiciones de este acuerdo mediante el envío de una notificación por escrito dentro de los 30 (treinta) días posteriores a la primera vez que quedó sujeto a este Acuerdo de Arbitraje.</p>
               <p>13.4. Le solicitamos que cualquier disputa sea enviada a <a href='mailto:hello@goodlook.com.mx' rel='noreferrer' target='_blank'>hello@goodlook.com.mx</a>, <strong>&ldquo;Good Look&rdquo;</strong> se encuentra abierto a previa negociación y solución de conflictos.</p>
               <br />
               <h2 id='jurisdiccion'>14. JURISDICCIÓN</h2>
               <br/>
               <p>14.1. Este acuerdo se celebrará, regirá e interpretará de acuerdo con las leyes del Estado de Texas, sin dar efecto a ningún principio que establezca la aplicación de la ley de otra jurisdicción. Además, cualquier disputa, reclamo o solicitud de compensación relacionada con este acuerdo o su uso de los servicios se resolverá mediante acuerdos vinculantes de arbitraje de conformidad con la Ley Federal de Arbitraje y las reglas y procedimientos de arbitraje de JAMS, o por otro foro de arbitraje si JAMS no está disponible.</p>
               <p>14.2. Cualquier cambio material en este Acuerdo de Arbitraje en el futuro se le notificará, y tendrá la opción de rechazar esos cambios dentro de los treinta (30) días posteriores a que dichos cambios entren en vigencia.</p>
               <br />
               <h2 id='preguntas'>15. PREGUNTAS, QUEJAS, RECLAMOS</h2>
               <br/>
               <p>15.1. Tal como se estableció en el cuerpo de este documento lo alentamos a que cualquier queja o reclamo con respecto a los Servicios, comuníquese con nosotros a: <a href='mailto:hello@goodlook.com.mx' rel='noreferrer' target='_blank'>hello@goodlook.com.mx</a></p>
               <p>15.2. En &ldquo;Good Look&rdquo;, nuestra prioridad es brindar un servicio excepcional y satisfactorio. Nos comprometemos a estar abiertos a ofrecer soluciones y a mediar en cualquier situación que pueda surgir durante su experiencia con nosotros. Valoramos la satisfacción de nuestros clientes y estamos aquí para asegurarnos de que cada interacción con &ldquo;Good Look&rdquo; sea positiva y resuelta de manera eficaz. Si en algún momento surge algún problema o inquietud, no dude en comunicarse con nuestro equipo dedicado, ya que estamos comprometidos a garantizar su completa satisfacción y a abordar cualquier situación de manera proactiva y diligente.</p>
            </div>
         </div>
      </div>
   )
 }
export default Es;