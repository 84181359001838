import React from 'react';
import { Document, Page, StyleSheet, Text, View, Font } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { useAppSelector } from 'src/hooks';
import { AppI } from 'src/redux';
// const GB = require('./fonts/Gotham-Bold.ttf');
// const GL = require('./fonts/Gotham-Light.ttf');

// Font.register({
//     family: 'GothamBold',
//     format: "truetype",
//     src: GB,
//   });
  
// Font.register({
//     family: 'GothamLight',
//     format: "truetype",
//     src: GL,
// });
// Create styles
const styles = StyleSheet.create({
    page: {
        display: 'flex',
        flexDirection: 'column',
        padding: 30,
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
        margin: 10,
        padding: 10,
    },
    title: {
        fontSize: 20,
        // fontFamily: 'GothamBold',
        textAlign: 'center',
        marginBottom: 15,
    },
    subtitle: {
        fontSize: 15,
        // fontFamily: 'GothamBold',
        textAlign: 'center',
        marginBottom: 15,
    },
    text: {
        fontSize: 12,
        textAlign: 'left',
        marginBottom: 10,
        // fontFamily: 'GothamLight',
        fontWeight: 'light'
    },
    textTable: {
        fontSize: 11,
        textAlign: 'left',
        // fontFamily: 'GothamLight',
        fontWeight: 'light'
    },
    li: {
        fontSize: 12,
        textAlign: 'left',
        // fontFamily: 'GothamLight',
        fontWeight: 'light',
        marginLeft: 25
    },
    divider: {
        width: '100%',
        marginBottom: 25
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        borderColor: '#e0e0e0',
        border: '1px solid'
    },
    col: {
        display: 'flex',
        flexDirection: 'column',
    }
});

interface TermsEnPdf {
    locale?: 'es' | 'en' | string;
}

export const TermsEnPdf = ({ locale }: TermsEnPdf) => {
    const datas = localStorage.getItem('goodlookInfo');

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <Text style={styles.title}>{'Independent Stylist Agreement and Terms'}</Text>
                    <Text style={styles.text}>{`Terms and conditions governing the contractual relationship between GOOD LOOK USA, LLC (“Good Look.”) Authorized address: 216 W. VILLAGE BLVD. SUITE 102-12 LAREDO, TX 78041 Authorized email: xxx and the undersigned named “The Independent Stylist”.`}</Text>
                    <Text style={styles.text}>{'Below, we collectively refer to “Good Look” and Independent Stylist as the “Parties” and each of them may be individually named as a “Party.”'}</Text>
                    <Text style={styles.text}>{'The parties accept and sign the following agreements:'}</Text>
                    <Text style={{ ...styles.text, marginLeft: 10 }}>{'1. Legal Age. The Independent Stylist represents that he is of legal age in the State where this Agreement is entered into.'}</Text>
                    <Text style={{ ...styles.text, marginLeft: 10 }}>{'2. Certification/License: The Independent Stylist declares that they have an active License and authorized by the State where they will provide the services.'}</Text>
                    <Text style={{ ...styles.text, marginLeft: 10 }}>{`3. Good Look Agreement. The Independent Stylist is subject to acceptance by Good Look. Upon Good Look's acceptance of the terms and conditions contained in this Independent Stylist Agreement, together with Good Look's Policies and Procedures for Independent Stylists (the "Policies and Procedures"), and the Privacy Policy, all which are incorporated into this Independent Stylist Agreement by reference, shall constitute the entire agreement (the "Agreement") between Good Look and the Independent Stylist.`}</Text>
                    <Text style={{ ...styles.text, marginLeft: 10 }}>{'4. Registration Procedure: To begin the registration process as an independent stylist with Good Look, you are required to provide the following documents:'}</Text>
                    <Text style={styles.li}>{'a. Social Security number'}</Text>
                    <Text style={styles.li}>{'b. Makeup artist license'}</Text>
                    <Text style={styles.li}>{'c. Public account on social networks where your work is reflected (website, IG, Facebook, Tiktok)'}</Text>
                    <Text style={styles.li}>{'d. Photos of tools that will be used in the services (makeup and hairstyle)'}</Text>
                    <Text style={styles.li}>{'e. Official Credential'}</Text>
                    <Text style={styles.li}>{`f. Address in ${locale === 'es' ? 'Mexico' : 'USA'}`}</Text>
                    <Text style={styles.li}>{'g. Active bank account'}</Text>
                </View>
            </Page>
            <Page size={'A4'} style={styles.page}>
            <View style={styles.section}>
                    <Text style={styles.subtitle}>{'Nails'}</Text>
                    <View style={{ ...styles.row, minHeight: 280 }}>
                        <View style={{ ...styles.col, width: '30%', justifyContent: 'center', paddingLeft: 10, paddingRight: 10 }}>
                            <Text style={{ ...styles.textTable, textAlign: 'center' }}>{'Manicure & Pedicure'}</Text>
                            <Text style={styles.textTable}>{'In our manicure and pedicure services we offer high quality products from brands such as Organic, Jessica, Shelac among others.'}</Text>
                        </View>
                        <View style={{ ...styles.col, width: '70%' }}>
                            <View style={{ ...styles.row, border: 'none', backgroundColor: '#d4d4d4' }}>
                                <View style={{ ...styles.col, width: '33.33%', height: 40, justifyContent: 'center', alignItems: 'center' }}>
                                    <Text style={{ ...styles.text, marginBottom: 0 }}>{'Services'}</Text>
                                </View>
                                <View style={{ ...styles.col, width: '33.33%', height: 40, justifyContent: 'center', alignItems: 'center' }}>
                                    <Text style={{ ...styles.text, marginBottom: 0 }}>{'Estimated Time'}</Text>
                                </View>
                                <View style={{ ...styles.col, width: '33.33%', height: 40, justifyContent: 'center', alignItems: 'center' }}>
                                    <Text style={{ ...styles.text, marginBottom: 0 }}>{'USD profit'}</Text>
                                </View>
                            </View>
                            <View style={{ ...styles.row, border: 'none' }}>
                                <View style={{ ...styles.col, width: '33.33%', padding: 10, justifyContent: 'center', alignItems: 'center', borderLeftWidth: 1, borderLeftColor: '#e0e0e0', borderLeft: 'solid' }}>
                                    <Text style={{ ...styles.textTable, marginBottom: 0 }}>{'Manicure & Pedicure Express'}</Text>
                                    <Text style={{ ...styles.textTable, marginBottom: 0 }}>{'Cuticle and nail polish removal (dry base)'}</Text>
                                </View>
                                <View style={{ ...styles.col, width: '33.33%', justifyContent: 'center', alignItems: 'center',borderLeftWidth: 1, borderLeftColor: '#e0e0e0', borderLeft: 'solid' }}>
                                    <Text style={{ ...styles.textTable, marginBottom: 0 }}>{'50 min'}</Text>
                                </View>
                                <View style={{ ...styles.col, width: '33.33%', justifyContent: 'center', alignItems: 'center',borderLeftWidth: 1, borderLeftColor: '#e0e0e0', borderLeft: 'solid' }}>
                                    <Text style={{ ...styles.textTable, marginBottom: 0 }}>{'$70'}</Text>
                                </View>
                            </View>
                            <View style={{ ...styles.row, border: 'none' }}>
                                <View style={{ ...styles.col, width: '33.33%', padding: 10, justifyContent: 'center', alignItems: 'center', borderLeftWidth: 1, borderLeftColor: '#e0e0e0', borderLeft: 'solid' }}>
                                    <Text style={{ ...styles.textTable, marginBottom: 0 }}>{'Manicure & Pedicure'}</Text>
                                    <Text style={{ ...styles.textTable, marginBottom: 0 }}>{'Cuticle and nail polish removal (water based)'}</Text>
                                </View>
                                <View style={{ ...styles.col, width: '33.33%', justifyContent: 'center', alignItems: 'center', borderLeftWidth: 1, borderLeftColor: '#e0e0e0', borderLeft: 'solid' }}>
                                    <Text style={{ ...styles.textTable, marginBottom: 0 }}>{'80 min'}</Text>
                                </View>
                                <View style={{ ...styles.col, width: '33.33%', justifyContent: 'center', alignItems: 'center', borderLeftWidth: 1, borderLeftColor: '#e0e0e0', borderLeft: 'solid' }}>
                                    <Text style={{ ...styles.textTable, marginBottom: 0 }}>{'$65'}</Text>
                                </View>
                            </View>
                            <View style={{ ...styles.row, border: 'none' }}>
                                <View style={{ ...styles.col, width: '33.33%', padding: 10, justifyContent: 'center', alignItems: 'center', borderLeftWidth: 1, borderLeftColor: '#e0e0e0', borderLeft: 'solid' }}>
                                    <Text style={{ ...styles.textTable, marginBottom: 0 }}>{'Hands and feet'}</Text>
                                    <Text style={{ ...styles.textTable, marginBottom: 0 }}>{'Nail polish on hands and feet'}</Text>
                                    <Text style={{ ...styles.textTable, marginBottom: 0 }}>{'High-end gel nail polishes'}</Text>
                                </View>
                                <View style={{ ...styles.col, width: '33.33%', justifyContent: 'center', alignItems: 'center', borderLeftWidth: 1, borderLeftColor: '#e0e0e0', borderLeft: 'solid' }}>
                                    <Text style={{ ...styles.textTable, marginBottom: 0 }}>{'60 min'}</Text>
                                </View>
                                <View style={{ ...styles.col, width: '33.33%', justifyContent: 'center', alignItems: 'center', borderLeftWidth: 1, borderLeftColor: '#e0e0e0', borderLeft: 'solid' }}>
                                    <Text style={{ ...styles.textTable, marginBottom: 0 }}>{'$42'}</Text>
                                </View>
                            </View>
                            <View style={{ ...styles.row, border: 'none' }}>
                                <View style={{ ...styles.col, width: '33.33%', padding: 10, justifyContent: 'center', alignItems: 'center', borderLeftWidth: 1, borderLeftColor: '#e0e0e0', borderLeft: 'solid' }}>
                                    <Text style={{ ...styles.textTable, marginBottom: 0 }}>{'Manicure & Pedicure gel polish'}</Text>
                                    <Text style={{ ...styles.textTable, marginBottom: 0 }}>{'Cuticle removal and gel polish (water-based)'}</Text>
                                </View>
                                <View style={{ ...styles.col, width: '33.33%', justifyContent: 'center', alignItems: 'center', borderLeftWidth: 1, borderLeftColor: '#e0e0e0', borderLeft: 'solid' }}>
                                    <Text style={{ ...styles.textTable, marginBottom: 0 }}>{'120 min'}</Text>
                                </View>
                                <View style={{ ...styles.col, width: '33.33%', justifyContent: 'center', alignItems: 'center', borderLeftWidth: 1, borderLeftColor: '#e0e0e0', borderLeft: 'solid' }}>
                                    <Text style={{ ...styles.textTable, marginBottom: 0 }}>{'$88'}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
                <View style={styles.section}>
                    <Text style={styles.text}>{'Electronic Transactions Act (UETA), both in force at the federal and state levels, the parties agree to the following: (i) Consent to the Use of Electronic Signatures: The parties recognize and accept the use of electronic signatures as valid means to express your consent and formalize agreements. (ii) Definition of Electronic Signature: "Electronic signature" means any electronic symbol or process, associated with a contract or other record, adopted by a person with the intention of signing the record. (iii) Informed Consent: When signing electronically , each party expresses its informed consent to use the electronic signature with respect to all documents, contracts and transactions related to this agreement. Document Integrity: The parties agree that any electronically signed document will have the same legal force and effect as a physically signed document. Likewise, they recognize that electronic signatures are binding and that the electronic document will retain its integrity.'}</Text>
                    <Text style={styles.text}>{'By signing electronically, each party acknowledges that it has read and understood the terms of this electronic signature clause, and expresses its consent to proceed with the electronic signature as provided.'}</Text>
                </View>
            </Page>
            <Page size={'A4'} style={styles.page}>
                <View style={styles.section}>
                    <View style={{ ...styles.row, border: 'none', backgroundColor: '#d4d4d4' }}>
                        <View style={{ ...styles.col, width: '40%', height: 40, justifyContent: 'center', alignItems: 'center' }}>
                            <Text style={{ ...styles.text, marginBottom: 0 }}>{'Name'}</Text>
                        </View>
                        <View style={{ ...styles.col, width: '20%', height: 40, justifyContent: 'center', alignItems: 'center' }}>
                            <Text style={{ ...styles.text, marginBottom: 0 }}>{'City'}</Text>
                        </View>
                        <View style={{ ...styles.col, width: '20%', height: 40, justifyContent: 'center', alignItems: 'center' }}>
                            <Text style={{ ...styles.text, marginBottom: 0 }}>{'Date'}</Text>
                        </View>
                        <View style={{ ...styles.col, width: '40%', height: 40, justifyContent: 'center', alignItems: 'center' }}>
                            <Text style={{ ...styles.text, marginBottom: 0 }}>{'Accept T & C'}</Text>
                        </View>
                    </View>
                    <View style={{ ...styles.row, borderColor: '#e0e0e0' }}>
                        <View style={{ ...styles.col, width: '40%', padding: 10, justifyContent: 'center', alignItems: 'center' }}>
                            <Text style={{ ...styles.textTable, marginBottom: 0 }}>{`${JSON.parse(datas || '{}').name}`}</Text>
                        </View>
                        <View style={{ ...styles.col, width: '20%', justifyContent: 'center', alignItems: 'center',borderLeftWidth: 1, borderLeftColor: '#e0e0e0', borderLeft: 'solid' }}>
                            <Text style={{ ...styles.textTable, marginBottom: 0 }}>{`${JSON.parse(datas || '{}').city}`}</Text>
                        </View>
                        <View style={{ ...styles.col, width: '20%', justifyContent: 'center', alignItems: 'center',borderLeftWidth: 1, borderLeftColor: '#e0e0e0', borderLeft: 'solid' }}>
                            <Text style={{ ...styles.textTable, marginBottom: 0 }}>{dayjs().format('DD/MMM/YYYY')}</Text>
                        </View>
                        <View style={{ ...styles.col, width: '40%', justifyContent: 'center', alignItems: 'center',borderLeftWidth: 1, borderLeftColor: '#e0e0e0', borderLeft: 'solid' }}>
                            <Text style={{ ...styles.textTable, marginBottom: 0 }}>{'✅ I have read the document and accept its conditions'}</Text>
                        </View>
                    </View>
                </View>
                <View style={styles.section}>
                    <Text style={styles.text}>{'Electronic Transactions Act (UETA), both in force at the federal and state levels, the parties agree to the following: (i) Consent to the Use of Electronic Signatures: The parties recognize and accept the use of electronic signatures as valid means to express your consent and formalize agreements. (ii) Definition of Electronic Signature: "Electronic signature" means any electronic symbol or process, associated with a contract or other record, adopted by a person with the intention of signing the record. (iii) Informed Consent: When signing electronically , each party expresses its informed consent to use the electronic signature with respect to all documents, contracts and transactions related to this agreement. Document Integrity: The parties agree that any electronically signed document will have the same legal force and effect as a physically signed document. Likewise, they recognize that electronic signatures are binding and that the electronic document will retain its integrity.'}</Text>
                    <Text style={styles.text}>{'By signing electronically, each party acknowledges that it has read and understood the terms of this electronic signature clause, and expresses its consent to proceed with the electronic signature as provided.'}</Text>
                </View>
                <View style={{ ...styles.section, marginTop: 0 }}>
                    <View style={{ backgroundColor: '#d4d4d4', display: 'flex', flexDirection: 'column', paddingTop: 5, paddingBottom: 5 }}>
                        <Text style={{ ...styles.text, marginBottom: 0, textAlign: 'center' }}>{'✅ I have read the document in its entirety and accept its conditions'}</Text>
                    </View>
                </View>
            </Page>
        </Document>
    )
}