import React from 'react';

import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';

import { MainHeader } from 'src/components';

const { Content } = Layout;

const TermsLayout = () => {
    return (
        <Layout>
            <MainHeader />
            <Content>
                <Outlet />
            </Content>
        </Layout>
    )
}

export default TermsLayout