import { Fragment } from 'react';

import dayjs from 'dayjs';
import 'dayjs/locale/en';

dayjs.locale('en');

const En = () => {
 
   return (
      <div className='container'>
         <div className='m-5'>
            <h1 className='text-center'>Terms of Use Good Look</h1>
            <hr />
            <h3 style={{ textTransform: 'capitalize' }}>Date: {dayjs().format('DD/MMM/YYYY')}</h3>
            <div className='text-center'>
               <p>PLEASE READ THE FOLLOWING INFORMATION CAREFULLY.</p>
               <p>BY USING THE SITE, YOU AGREE TO THESE &ldquo;TERMS OF USE&rdquo; IF YOU DO NOT AGREE, DO NOT SUBSCRIBE OR CONTRACT THE SERVICES OFFERED HERE.</p>
            </div>
            <div className='text-justified'>
               <p>The present Terms and Conditions, hereinafter referred to as &ldquo;Terms of Use&rdquo;, govern your access and use of the Services provided by the company Good Look USA, LLC, hereinafter referred to as &ldquo;Good Look&rdquo; (&ldquo;us&rdquo;, &ldquo;our&rdquo;, and &ldquo;we&rdquo;), through its Website <a href='https://www.goodlook.com.mx' rel='noreferrer' target='_blank'>https://www.goodlook.com.mx</a> (&ldquo;The Site&rdquo;). Your use is subject to acceptance without modification of all the terms and conditions contained herein and all other operating rules, policies (including, without limitation, the Privacy Policy), and procedures that may be published from time to time by us.</p>
               <p>This document constitutes a legal-commercial agreement between you and &ldquo;Good Look&rdquo; (defined below). Before using the Good Look platform, it is important that you are aware of your rights and obligations, as well as those of Good Look and the service providers. Once services are requested, these &ldquo;Terms of Use&rdquo; will be applicable to both parties. You agree that THIS AGREEMENT (&ldquo;TERMS OF USE&rdquo;) INVOLVES A WAIVER OF PARTICIPATION IN CLASS ACTIONS AND A WAIVER OF JURY TRIAL. FURTHER, IT PROVIDES THAT DISPUTES BETWEEN THE PARTIES SHALL BE RESOLVED BY BINDING AND FINAL ARBITRATION, ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS.</p>
               <p>By accepting these &ldquo;Terms of Use&rdquo;, the user confirms that they are of legal age, have the required legal capacity, and assume full responsibility for the obligations arising from the use of our services, making this acceptance consciously and voluntarily.</p>
               <p>You acknowledge that the services provided on &ldquo;The Site&rdquo; are designed as an intermediary between &ldquo;Service Providers&rdquo; and &ldquo;Users&rdquo; (both concepts defined in the definitions section), facilitating the organization of home beauty services, among others. It is important to note that &ldquo;Good Look&rdquo;, as well as its legal representatives and/or workers, do not act as providers of beauty services but rather play the role of mediators between specialists in this field and you, as detailed in the body of this document.</p>
               <p>These &ldquo;Terms of Use&rdquo; apply exclusively to &ldquo;Users&rdquo;. If you are a &ldquo;Service Provider&rdquo;, you must follow the guidelines set forth in your individual agreements and regulations.</p>
               <p>These &ldquo;Terms of Use&rdquo; apply exclusively in the United States of America, and its geographic scope is limited to the locations mentioned herein.</p>
            </div>
            <div>
               <h3>CONTENT OF THE &ldquo;TERMS OF USE&rdquo;:</h3>
               <ol>
                  <li><a href='#definitions'>Definitions</a></li>
                  <li><a href='#scope'>Scope of Application of Services</a></li>
                  <li><a href='#naturebusiness'>Nature of the Business</a></li>
                  <li><a href='#visitorsandusers'>Responsability of Visitors and Users</a></li>
                  <li><a href='#userregistration'>User Registration/Subscription</a></li>
                  <li><a href='#purchaseandpayment'>Purchase and payment conditions</a></li>
                  <li><a href='#cancelrefunds'>Cancellation and Refunds</a></li>
                  <li><a href='#validity'>Validity</a></li>
                  <li><a href='#failures'>System Failures</a></li>
                  <li><a href='#rightsandlicences'>Rights and Licences</a></li>
                  <li><a href='#limitation'>Limitation of Liability</a></li>
                  <li><a href='#intellectualproperty'>Intellectual Property</a></li>
                  <li><a href='#conflictresolution'>Conflict Resolution and Arbitration</a></li>
                  <li><a href='#jurisdiction'>Jurisdiction</a></li>
                  <li><a href='#questions'>Questions, Complaints or Claims</a></li>
               </ol>
            </div>
            <div className='text-justified'>
               <h2 id='definitions'>1. DEFINITIONS</h2>
               <br />
               <p>1.1. For the purposes of these “Terms of Use,” it is understood as:</p>
               <ol type='a' className='text-justified'>
                  <li><strong>Account: </strong>Personal online record that allows a user to access and use Good Look services. Contains unique identifying information, such as a username and password.</li>
                  <li><strong>Appointment/Booking Appointment: </strong>The action of the User to choose a specific time and date to receive the makeup and/or hair styling services offered in the service catalog of the &ldquo;Site&rdquo; at the address provided by the user, within the geographical limitation mentioned later.</li>
                  <li><strong>Services Catalog: </strong>It is the section or page of the “Site” that contains a list of the services offered by the “Service Providers” through the “Good Look” “Site”. This section may include details such as service descriptions, prices, payment method, terms and conditions, images, and any other relevant information.</li>
                  <li><strong>Service Data: </strong>Refers to the set of information that includes (i) name, (ii) address, (iii) contact of the User in which “The Service Provider” will attend a specific time and date chosen by the User, to provide the makeup and/or hair styling services established in the service catalog of the “Site”.</li>
                  <li><strong>Good Look: </strong>Refers to the company called Good Look USA, LLC, with an authorized address at 261 W Village, BLVD, SUITE 102-12, Laredo, Texas, 78041.</li>
                  <li><strong>“The Site”/“Site”: </strong>Refers to the website with the address https://www.goodlook.com.mx, as well as the programs, applications, and/or services offered by &ldquo;Good Look&rdquo;, which include, but are not limited to, this website, any instant messaging service, any mini site, future applications, and any auxiliary application for the use or access of the service offered by &ldquo;Good Look&rdquo;, whether jointly or individually.</li>
                  <li><strong>Service Providers: </strong>Stylists and makeup artists, after reaching a prior agreement with &ldquo;Good Look USA, LLC&rdquo;, have meticulously defined the terms and conditions that govern the provision of their services to users. Any user service and comments provided by these professionals are of a personal nature and are carried out under their sole responsibility, exempting &ldquo;Good Look&rdquo; from any implication in this regard.</li>
                  <li><strong>Third-party providers/Payment Provider:</strong> Refers to payment platforms independent of Good Look. These are external services used to manage and process financial transactions, such as payments, separately from the main Good Look platform. These providers may offer specialized payment processing services and act as third-party entities that facilitate financial transactions securely and efficiently. It should be noted that Good Look is not linked to them nor does it assume direct or indirect responsibility for the use, operation, and security of said platforms.</li>
                  <li><strong>User and/or Users:</strong> Refers to the natural person who accesses, benefits, and/or requests any of the services of the &ldquo;Good Look&rdquo; Platform, for any reason whatsoever.</li>
               </ol>
               <p>1.2. Capitalized terms, but not defined herein, shall have the meanings assigned to them in the Notice of Privacy and/or in the “Terms of Use” provided here.</p>
               <p>Your document is clear and provides a comprehensive understanding of the scope, terms, and nature of the services offered by &ldquo;Good Look&rdquo;. However, I have made a few minor adjustments for clarity and consistency:</p>
               <br />
               <h2 id='scope'>2. SCOPE OF APPLICATION AND YOUR RESPONSIBILITY</h2>
               <br/>
               <p>2.1. The services of &ldquo;The Site&rdquo; are limited exclusively to the following cities in the United States of America:</p>
               <ol type='i'>
                  <li>Los Angeles, California</li>
                  <li>San Diego, California</li>
                  <li>Manhattan, New York</li>
                  <li>Dallas, Texas</li>
                  <li>San Antonio, Texas</li>
                  <li>Miami, Florida</li>
               </ol>
               <p>2.2. The services provided through the &ldquo;Site&rdquo; are available only in these specified locations, and any other geographic area is outside its scope.</p>
               <p>2.3. If the address for the appointment you wish to schedule is located outside of these cities, please refrain from using, scheduling, or requesting any type of appointment through our &ldquo;Site&rdquo;.</p>
               <p>2.4. If you request services in the city of Miami, Florida, you must acknowledge that your request is related to the film industry, fashion photography, theater, television, photography studio jobs, special events such as weddings or fashion shows, demonstrations at trade shows, educational seminars, or due to health problems preventing you from attending an authorized salon.</p>
               <p>2.5. If you request services in the state of California, specifically in the cities of Los Angeles and San Diego, you must acknowledge that the purpose of your request is incidental to the theater, radio, television, or film production industry; necessary due to illness or other physical or mental disability; or for the purpose of receiving product recommendations and/or live demonstrations.</p>
               <p>2.6. These statements comply with legal requirements and regulations in these states, ensuring that users use home beauty services appropriately and legally.</p>
               <p>2.7. While our service providers are verified to ensure they have the appropriate licenses, certifications, and processes to provide services in accordance with applicable state and national regulations, &ldquo;Good Look&rdquo; is not responsible for the final result of the services.</p>
               <br />
               <h2 id='naturebusiness'>3. NATURE OF THE BUSINESS</h2>
               <br/>
               <p>3.1. &ldquo;Good Look&rdquo; is operated by the distinguished company Good Look, USA LLC, registered in the State of Texas.</p>
               <p>3.2. &ldquo;Good Look&rdquo; operates as a business mediation platform connecting beauty professionals with clients seeking at-home hair and makeup services. We specialize in facilitating harmonious encounters between talented beauty experts and those seeking personalized and convenient services in the comfort of their homes.</p>
               <p>3.3. Through our &ldquo;Site&rdquo;, at &ldquo;Good Look&rdquo;, we provide a tool simplifying appointment booking and service selection directly at the user&apos;s home. Our platform is designed to make the scheduling process convenient and personalized, allowing users to easily select desired services and manage appointments efficiently. We are committed to making the booking experience simple and enjoyable for our users.</p>
               <p>3.4. &ldquo;Good Look&rdquo; is a commercial platform facilitating the connection between beauty professionals and clients. It&apos;s crucial to note that &ldquo;Good Look&rdquo; does not act as the employer of service providers and does not maintain any employment relationship with them. Beauty professionals offering services through our platform are independent and autonomous, assuming full responsibility for the quality of the services provided.</p>
               <p>3.5. To ensure the integrity and reliability of our platform, we reserve the right to review the background of service providers before allowing them to offer services through &ldquo;Good Look&rdquo;. We require each professional to sign a contract establishing terms and conditions for service provision, ensuring a clear and mutual framework for all parties involved. At &ldquo;Good Look&rdquo;, we strive to foster a transparent and trusting relationship between beauty professionals and our valued clients.</p>
               <p>3.6. Service providers are independent contractors and should not be considered employees, representatives, or agents of &ldquo;Good Look&rdquo;.</p>
               <br />
               <h2 id='visitorsandusers'>4. RESPONSIBILITY OF VISITORS AND USERS</h2>
               <br/>
               <p>4.1. The User must read, understand, and accept all the conditions established in these General Terms and Conditions, as well as in the Privacy Notice, and in other documents and links incorporated therein by reference, prior to accessing and using as a User.</p>
               <p>4.2. Failure to comply with any of the terms and conditions detailed below will result in the immediate termination of this agreement, attributable to the User, and consequently, their Account.</p>
               <p>4.3. The Services published by “Good Look” are only available to individuals with the legal capacity to contract. People lacking that capacity, Users who have been temporarily suspended or permanently disabled, will not be able to use the services. Therefore:</p>
               <ol type='a'>
                  <li>Any User must be 18 years of age or older to use this service and have legal permission under the laws of the United States of America</li>
                  <li>You must be human. Accounts registered by “bots” or other automated methods are not allowed.</li>
                  <li>Provide your full legal name, a valid email address, and any other requested information to complete the registration process.</li>
                  <li>You are responsible for maintaining the security of your account and password. “Good Look” cannot and will not be liable for any loss or damage due to your failure to comply with this security obligation.</li>
                  <li>You are responsible for the contact, payment, and location information provided for appointments. That is, you are responsible for your own email, address, password, username, debit and/or credit card entered in any field on the “Site,” and for third-party providers connected to “Good Look”.</li>
                  <li>Third-party sites, applications, and advertisements: The “Site” may contain links to third-party websites, applications, services, and advertisements, but we have no control over them and are not responsible for their content. We provide them only as a convenience and do not review, approve, monitor, endorse, guarantee or make any representations about them. You use them at your own risk and should review the third party&apos;s terms and policies, including their privacy and data collection practices. “Good Look” works with advertising partners who may send you ads on or off our website based on your activities on the site or within the services.</li>
                  <li>At no time should you use the “Site,” the services published here, the booked appointments, or contact with the “Service Providers” for illegal, immoral, or unauthorized purposes.</li>
                  <li>In using the Good Look Service, you must not violate any state or national laws, treaties, or legal provisions in your jurisdiction, including, but not limited to, Intellectual Property laws.</li>
                  <li></li>
               </ol>
               <p>4.4. All obligations, prohibitions, limitations, permissions, authorizations, and other provisions provided in this contract for the use of both the “Good Look” internet pages and the services that “Good Look” offers are mandatory and binding for the User.</p>
               <p>
                  4.5. <strong>Responsibility of the User at Home.</strong><br />
                  As a client/User beneficiary of home beauty services contracted through the “Good Look” “Site,” you have certain responsibilities, as described below:
               </p>
               <ol type='i'>
                  <li>Comply with the service provider&apos;s requirements applicable to the service purchased, creating a safe, clean, and comfortable workspace.</li>
                  <li>Ensure that you and any other people or pets present in the home refrain from any behavior that may be offensive while the service provider is present.</li>
                  <li>Avoid requesting services that are not included in the contracted service in the &ldquo;Site&rdquo; catalog.</li>
                  <li>Do not request services for more people without having booked and paid for the appointment in advance.</li>
                  <li>Do not request the service provider to change your address.</li>
                  <li>If you have pets or children under 13 years old, confine them to another room.</li>
                  <li>In the event that the user develops a serious viral illness, such as COVID-19 or similar illnesses, the service provider reserves the right to cancel the service.</li>
                  <li>Inform the service provider about any skin, hair, or allergic reactions that may arise as a result of the services provided.</li>
                  <li>Service providers reserve the right to cancel or refuse to provide their services if you fail to meet these responsibilities or if the work environment is not safe or comfortable for the service provider.</li>
               </ol>
               <br />
               <h2 id='userregistration'>5. USER REGISTRATION/SUBSCRIPTION</h2>
               <br/>
               <p>
                  5.1. Registration and Personal Data: <br />
                  In order to provide the service correctly to the User, &ldquo;The Site&rdquo; will offer the following options for (i) account registration and (ii) making appointment reservations through the guest option. <br /><br />
                  It is mandatory to complete the subscription form with valid personal information (&ldquo;Personal Data&rdquo;) to use the &ldquo;Good Look&rdquo; services.<br /> <br />
                  The User agrees to provide exact, precise, and true &ldquo;Personal Data&rdquo;, assuming the responsibility of updating it. &ldquo;Good Look&rdquo; is not responsible for the accuracy of the User&apos;s Personal Data.
               </p>
               <p>
                  5.2. Data Verification:<br />
                  It is the User&apos;s responsibility to verify that their personal data is correct before booking the appointment and paying for the services.<br /><br />
                  In the event that the user provides incorrect information, it is important to note that any resulting consequences will be the sole responsibility of the user. Neither Good Look nor the service provider will be responsible for any damages or negative results that may arise as a result of such incorrect information. Users are recommended to take necessary steps to ensure that all information provided is accurate and up-to-date to avoid potential problems in the future.
               </p>
               <p>
                  5.3. Account Access and Responsibility:<br />
                  The user accesses their personal account using their Pseudonymous User name along with the chosen personal Security Key. &ldquo;Good Look&rdquo; reserves the right to cancel, suspend, or disable duplicate or related accounts.<br /><br />
                  The user is responsible for all operations carried out on their account. It is crucial to protect the username and password and notify Good Look if any unauthorized activity is suspected. &ldquo;Good Look&rdquo; is not responsible for any loss or damage due to a lack of security in the user&apos;s account.
               </p>
               <p>
                  5.4. Account Transfer Prohibition:<br />
                  The sale, assignment, or transfer of the account to third parties is prohibited.
               </p>
               <br />
               <h2 id='purchaseandpayment'>6. TERMS OF SERVICES AND PAYMENTS</h2>
               <br/>
               <p>6.1. Access to the &ldquo;Site&rdquo; is free; however, please note that &ldquo;The Site&rdquo; is a platform connecting service providers with users, and the services offered have a set cost and price.</p>
               <p>6.2. The catalog of services is found in the tab called &ldquo;services&rdquo;, inside the place. However, some services may be displayed on the main page of the “Site,” on social networks, and in advertising on various media.</p>
               <p>6.3. The photos shown in the service catalog are only illustrative of the service offered. The User and the service provider will mutually agree on the style and concept of the service. In some cases, the User may grant creative freedom to the service provider, but it is the sole responsibility of the User to do so.</p>
               <p>6.4. Service rates are displayed in the services section of the “Site”. The cost varies depending on the city and the type of service selected by the client. In some cases, Good Look may offer discounts, which will be determined at its discretion.</p>
               <p>6.5. The availability of services on a specific date and time is subject to the schedule of the &ldquo;Service Providers&rdquo;; therefore, users are not allowed to request a particular service provider. Despite this, the “Service Providers” will endeavor to comply with the user&apos;s preferences to the extent possible.</p>
               <p>6.6. When making a purchase on the “Site”, you must confirm your purchase with the corresponding “Payment Provider” to secure your appointment reservation. Your payment method will be charged, either through your card or by deposit, as designated by the payment provider (“Payment Method”). &ldquo;Good Look&rdquo; uses the services of third-party providers to request payments for services, such as the Stripe® platform (“Payment Provider”).</p>
               <p>6.7. If payment has not been made correctly, the appointment is not secured or booked. It is the user&apos;s responsibility to ensure that payment has been processed correctly and that an appointment booking confirmation has been received. If the payment is not processed correctly or is declined for any reason, the appointment will not be booked, and the user must make the payment correctly to ensure the appointment is booked. In the event that the user experiences any problem with the payment, they will need to contact their bank or payment provider to resolve the issue and ensure that the payment is processed correctly. &ldquo;Good Look&rdquo; will not be responsible for any issues related to payment processing, including but not limited to the failure to confirm appointment booking due to a payment issue.</p>
               <p>6.8. The prices corresponding to the services you have selected, as well as any sales tax or other related charges that may apply to your payments, will be clearly indicated in the final amount during the payment process provided through the “Payment Platform”.</p>
               <p>6.9. Any objection to a payment already made must be directed to customer service if &ldquo;Good Look&rdquo; or the account of third-party providers, such as Stripe® (“Payment Provider”), they invoice directly. You can also raise a payment objection by contacting your bank or payment provider, who can provide additional information about your rights and applicable deadlines.</p>
               <p>6.10. On some occasions, deferred payment for services will be offered, but the responsibility for this process lies with the bank that issued the user&apos;s account and the corresponding payment platform.</p>
               <p>6.11. In the event that the user files a chargeback or dispute with their bank or payment provider for any reason, &ldquo;Good Look&rdquo; reserves the right to suspend or cancel your account immediately and at its sole discretion. Additionally, the user will be responsible for any costs or expenses related to the chargeback or dispute, including any fees or charges imposed by the bank or payment provider. &ldquo;Good Look&rdquo; reserves the right to take any legal or other action it deems necessary to recover any amounts owed in connection with the chargeback or dispute.</p>
               <p>6.12. <strong>&ldquo;Good Look&rdquo;</strong> expressly reserves the right to change or modify its prices and rates at any time, and such changes or modifications will be published online on this Site, and they will be effective immediately without the need for us to send you any further information. notification.</p>
               <p>6.13. If for any reason you are unable to charge the fees to your card or make the deposit, you can email us at <a href='mailto:hello@goodlook.com.mx' rel='noreferrer' target='_blank'>hello@goodlook.com.mx</a>. We will send you an email with the payment information so that you can reserve the appointment through the corresponding payment. However, we cannot ensure the availability of the desired day and time while payment is being processed.</p>
               <p>6.14. <strong>Promotional codes: &ldquo;Good Look&rdquo;</strong> may, at its discretion, create promotional codes that may be redeemed for credit on your account or other benefits related to the services, subject to the additional terms set forth for each promotional code (&ldquo;Promotional Codes&rdquo;). Unless expressly stated otherwise in the additional terms, promotional codes can only be used once per person, and multiple promotional codes cannot be used in a single transaction or appointment. Additionally, each promotional code benefit can only be redeemed once per account. You must not alter, duplicate, sell, transfer, or distribute promotional codes in any way, unless expressly permitted by us. We may disable promotional codes at any time and for any reason without liability to us. Promotional codes have no cash value and may expire before use.</p>
               <br />
               <h2 id='cancelrefunds'>7. CANCELLATION AND REFUNDS</h2>
               <br/>
               <p>7.1. Cancellation Policy:</p>
               <ol type='i'>
                  <li>COVID-19 Cancellation Policy: In addition to the cancellation rights outlined in the policy, you must cancel an appointment through the services if you have symptoms of COVID-19, have tested positive, or feel unwell. You can do so up to the appointment start time without penalty. &ldquo;Good Look&rdquo; reserves the right to revise this policy, requiring medical proof for illness justification. In such cases, you&apos;ll receive a credit for the service amount, excluding third-party platform fees, to be used on another date.</li>
                  <li>If the service provider cancels an appointment, the User will be notified, and efforts will be made to find another available Service Provider for the indicated day and time.</li>
                  <li>If the service provider does not show up for a prepaid appointment.</li>
                  <li>If &ldquo;Good Look&rdquo; is unable to book another service provider during the scheduled appointment time, the appointment will be canceled, and the user will be refunded for the selected services, excluding the third-party platform&apos;s commission fee.</li>
               </ol>
               <p>
                  7.2. Abuse of COVID-19 Cancellation Policy: <br />
                  If <strong>&ldquo;Good Look&rdquo;</strong> discovers abuse of the COVID-19 cancellation policy, it reserves the right to suspend or cancel the account, whether the user is a customer or a service provider. In such cases, no refunds for previously paid fees will be provided.</p>
               <p>
                  7.3. Unforeseen Situations: <br /> 
                  In situations not described in this clause, &ldquo;Good Look&rdquo; will decide at its discretion how the payment will be treated. For such cases, users must contact customer service at <a href='mailto:hello@goodlook.com.mx' rel='noreferrer' target='_blank'>hello@goodlook.com.mx</a>.
               </p>
               <p>
                  7.4. Refund Requests: <br />
                  To request a refund in applicable cases, send an email to <a href='mailto:hello@goodlook.com.mx' rel='noreferrer' target='_blank'>hello@goodlook.com.mx</a> to contact customer service.</p>
               <br />
               <h2 id='validity'>8. VALIDITY</h2>
               <br/>
               <p>8.1. These Terms of Use will be effective from the time the Services are activated until: <br />(1) terminated as provided by this Agreement or by any addition to this Agreement or <br />(2) replaced by a revised Agreement.</p>
               <br />
               <h2 id='failures'>9. FAULTS IN THE SYSTEM</h2>
               <br/>
               <p>9.1. <strong>&ldquo;Good Look&rdquo;</strong> is not responsible for any damage, loss, or loss to the User caused by failures in the system, the server, or the Internet.</p>
               <p>9.2. <strong>&ldquo;Good Look&rdquo;</strong> will also not be responsible for any virus that may infect any User&apos;s equipment.</p>
               <p>9.3. <strong>&ldquo;Good Look&rdquo;</strong> does not guarantee continued or uninterrupted access and use of its site. The system may eventually be unavailable due to technical difficulties or Internet failures, or due to any other circumstance beyond the control of &ldquo;Good Look&rdquo;. In such cases, efforts will be made to restore it as quickly as possible without imputing any type of responsibility.</p>
               <p>9.4. <strong>&ldquo;Good Look&rdquo;</strong> will not be responsible for any error or omission contained on the <strong>&ldquo;Sitio&rdquo;</strong></p>
               <p>9.5. You agree and understand that <strong>&ldquo;Good Look&rdquo;</strong> may update versions of the <strong>&ldquo;Sitio&rdquo;</strong></p>
               <p>9.6. Failure to update these versions in accordance with the preceding paragraph could cause failures.</p>
               <br />
               <h2 id='rightsandlicences'>10. RIGHTS AND LICENSES</h2>
               <br/>
               <p>10.1. You have a non-transferable, non-exclusive license to access and use the Services for your personal use.</p>
               <p>10.2. You may not license, sell, rent, transfer, distribute, host, commercially exploit, or resell the Services.</p>
               <p>10.3. You may not modify, make derivative works of, disassemble, reverse compile or reverse engineer any part of the Services.</p>
               <p>10.4. You may not access the Services to create a similar or competitive service.</p>
               <p>10.5. All intellectual property rights, including copyrights, patents, trademarks, and trade secrets, in the services, including the site and future applications, are owned by Good Look or our licensors.</p>
               <p>10.6. We reserve the right to modify, suspend, or discontinue access to the services with or without notice.</p>
               <p>10.7. We will have no obligation to provide support or maintenance in connection with the services.</p>
               <p>10.8. The provision of the Services does not transfer to you or any third party any right, title, or interest in or to any intellectual property rights.</p>
               <p>10.9. We and our suppliers reserve all rights not granted in this agreement.</p>
               <br />
               <h2 id='limitation'>11. LIMITATION OF LIABILITY</h2>
               <br/>
               <p>11.1. To the maximum extent permitted by law, we will not be liable to you or any third party for any loss of profits or indirect, consequential, exemplary, incidental, special, or punitive damages, arising out of or in connection with this agreement for the services, even if we have advised of the possibility of such damages.</p>
               <p>11.2. The limits of our liability will be limited to the greater of the following: (a) fifty US dollars ($50); or (b) the amounts you have paid to “Good Look” in the previous six months (if any). The existence of more than one claim will not extend this limit. You further agree that our suppliers will have no liability of any kind arising from or relating to this agreement.</p>
               <p>11.3. This means that in the event of any damage or loss that is our responsibility, our liability will be limited to the greater of the two options mentioned above. Additionally, any additional claims will not increase this limit. You also agree that our suppliers will have no liability in connection with this agreement.</p>
               <p>11.4. Clients understand and agree that the Stylist is independent and not associated with Good Look. Consequently, the Professional and/or Good Look assumes responsibility for damages, losses, or injuries that may arise during or after the provision of services, except in cases of proven negligence. Clients release the Professional and/or Good Look from any claims and hold harmless for any incident that may occur during or after the provision of hairdressing services.</p>
               <p>11.5. For specific cases, you must send an email to <a href='mailto:hello@goodlook.com.mx' rel='noreferrer' target='_blank'>hello@goodlook.com.mx</a></p>
               <br />
               <h2 id='intellectualproperty'>12. INTELLECTUAL PROPERTY</h2>
               <br/>
               <p>12.1. The contents on the “Site” related to the services of <strong>&ldquo;Good Look&rdquo;</strong>, as well as the programs, databases, networks, files that allow the User to access and use their Account, are the property of “Good Look” and are protected by national laws and international treaties on copyright, trademarks, patents, models, and industrial designs. The improper use and total or partial reproduction of said contents are prohibited unless expressly authorized in writing by <strong>&ldquo;Good Look&rdquo;</strong>.</p>
               <p>12.2. The Site may contain links to other websites, which does not indicate that they are owned or operated by “Good Look.” Since “Good Look” has no control over such sites, it will NOT be responsible for the contents, materials, actions, and/or services provided by them, nor for damages or losses caused by their use, whether caused directly or indirectly. The presence of links to other websites does not imply a partnership, relationship, approval, endorsement by “Good Look” of said sites and their contents.</p>
               <br />
               <h2 id='conflictresolution'>13. DISPUTE RESOLUTION AND ARBITRATION</h2>
               <br/>
               <p>13.1. Dispute Resolution: This agreement provides that any dispute, claim, or request for compensation related to access or use of the &ldquo;Site&rdquo; or services purchased through the &ldquo;Site&rdquo; must be resolved by binding arbitration agreements, except in special cases such as claims in small claims court or for intellectual property infringement.</p>
               <p>13.2. The arbitration will be governed by the JAMS Arbitration Rules and Procedures and will be conducted by JAMS or another arbitration forum if JAMS is not available. The arbitrator shall have the authority to award monetary damages and any non-monetary remedy or relief available under applicable law. Claims must be submitted individually, and class-action claims are not permitted.</p>
               <p>13.3. You have the right to opt out of the provisions of this agreement by sending us written notice within 30 (thirty) days after you first became subject to this Arbitration Agreement.</p>
               <p>13.4. We request that any dispute be sent to <a href='mailto:hello@goodlook.com.mx' rel='noreferrer' target='_blank'>hello@goodlook.com.mx</a>, “Good Look” is open to prior negotiation and conflict resolution.</p>
               <br />
               <h2 id='jurisdiction'>14. JURISDICTION</h2>
               <br/>
               <p>14.1. This agreement shall be entered into, governed by, and construed in accordance with the laws of the State of Texas, without giving effect to any principles that provide for the application of the law of another jurisdiction. Additionally, any dispute, claim, or request for relief relating to this Agreement or your use of the Services shall be resolved by binding arbitration agreements in accordance with the Federal Arbitration Act and the JAMS arbitration rules and procedures or by another forum. Arbitration if JAMS is not available.</p>
               <p>14.2. Any material changes to this Arbitration Agreement in the future will be notified to you, and you will have the option to reject such changes within thirty (30) days after such changes become effective.</p>
               <br />
               <h2 id='questions'>15. QUESTIONS, COMPLAINTS, CLAIMS</h2>
               <br/>
               <p>15.1. As set forth in the body of this document, we encourage you to contact us with any complaints or claims regarding the Services at: <a href='mailto:hello@goodlook.com.mx' rel='noreferrer' target='_blank'>hello@goodlook.com.mx</a></p>
               <p>15.2. At “Good Look”, our priority is to provide exceptional and satisfactory service. We are committed to being open to offering solutions and mediating any situation that may arise during your experience with us. We value our customer satisfaction and are here to ensure that every interaction with “Good Look” is positive and resolved effectively. If at any time any issues or concerns arise, please do not hesitate to contact our dedicated team as we are committed to ensuring your complete satisfaction and addressing any situation proactively and diligently.</p>
            </div>
         </div>
      </div>
   )
 }
export default En;