import React, { CSSProperties, useEffect,  useState } from 'react';

import { Input, notification } from 'antd';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

import styles from './SearchAutocomplete.module.css';
import { getInfoByPlaceId } from 'src/api';
import { useAppSelector } from 'src/hooks';
import { AppI } from 'src/redux';

import notifI18n from 'src/i18n/notifications.i18n.json';

interface SearchAutocompleteI {
    placeholder?: string;
    styleContainer?: CSSProperties;
    styleInput?: CSSProperties;
    styleBoxPredictions?: CSSProperties;
    locale?: 'es' | 'en' | string;
    value: string;
    setValue: any;
    onFocused?: () => void;
    size?: 'small' | 'middle' | 'large';
    name?: string;
    id?: string;
}

type GooglePlaceIdT = {
    place_id: string;
    description: string;
    reference: string;
    types: string[];
    structured_formatting: {
        main_text: string;
        secondary_text: string;
    };
    terms: Array<{
        offset: string | number;
        value: string;
    }>
}

type AddressT = Array<{
    long_name: string;
    short_name: string;
    types: string[];
}>

const InputAutocomplete = ({ id, name, placeholder, styleBoxPredictions, styleContainer, styleInput, locale='es', value, setValue, onFocused, size='middle' }: SearchAutocompleteI) => {
    const [pred, setPred] = useState<Array<any>>([]);
    const [optionSelected, setOptionSelected] = useState<undefined | GooglePlaceIdT>(undefined);
    const [focused, setFocused] = useState(false);
    const [address, setAddress] = useState<undefined | AddressT>(undefined);

    const { lang } = useAppSelector(({ app }: { app: AppI }) => app);

    const options = {
        componentRestrictions: {
            country: locale === 'es' ? 'mx' : 'us'
        }
    }

    const {
        placePredictions,
        getPlacePredictions,
      } = useGoogle({
        apiKey: process.env.REACT_APP_GOOGLE_KEY,
        options: options
    });
    // service to get address info from google map API Service bridge 
    const handlePlaceIdInfo = async () => {
        try {
            const { result } = await getInfoByPlaceId(optionSelected ? optionSelected.place_id : '');
            if (result && result.address_components) {
                setAddress(result.address_components);
            } else {
                notification.warning({
                    message: notifI18n[lang].cityTitleNotification,
                    description: notifI18n[lang].cityDescriptionNotification
                });
            }
        } catch (error:any) {
            notification.warning({
                message: notifI18n[lang].cityTitleNotification,
                description: `${notifI18n[lang].commonDescriptionError} ${error.message}`
            });
        }
    }

    // clean state when locale is modified 
    useEffect(() => {
        setValue('');
        setPred([])
        setOptionSelected(undefined);
        setFocused(false)
    }, [locale])
    // use effect to control input search behavior
    useEffect(() => {
        if (!value) {
            setPred([])
        }
        
        if (value && optionSelected && optionSelected.description !== value) {
            setOptionSelected(undefined);
        }
    }, [value])
    // set list of predictions of input search result
    useEffect(() => {
        if (value && placePredictions.length > 0 && !optionSelected) {
            setPred(placePredictions);
        }
    }, [value, placePredictions])
    // get info of google bridge service
    useEffect(() => {
        if (optionSelected) {
            handlePlaceIdInfo();
        }
    }, [optionSelected])

    useEffect(() => {
        if (!focused && !optionSelected && pred.length === 0 && value) {
            setValue(value);
        }
    }, [focused, optionSelected, pred, address])

    return (
        <div style={styleContainer}>
            <div id='map'hidden />
            <Input
                style={styleInput}
                value={value}
                placeholder={placeholder}
                onChange={(evt) => {
                    getPlacePredictions({ input: evt.target.value });
                    setValue(evt.target.value);
                }}
                onFocus={() => {
                    setFocused(true);
                    if (onFocused) onFocused();
                }}
                onBlur={() => {
                    setTimeout(() => {
                        setFocused(false);
                    }, 500)
                }}
                size={size}
                id={id}
                name={name}
            />
            {(pred.length > 0 && focused) && 
            <div style={styleBoxPredictions ? {
                position: 'absolute', zIndex: 10, width: '100%', backgroundColor: '#FFF', marginTop: 5, borderRadius: 10, ...styleBoxPredictions
            } : {
                position: 'absolute', zIndex: 10, width: '100%', backgroundColor: '#FFF', marginTop: 5, borderRadius: 10, padding: 5
            }}>
                {pred.map((i, _) => (
                    <div className={styles.google_option} key={_} onClick={() => {
                        setOptionSelected(i);
                        setValue(i.description)
                        setPred([]);
                    }}>
                        {i.description}
                    </div>
                ))}
            </div>}
        </div>
    )
}
export default InputAutocomplete;
