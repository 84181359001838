import React, { useState } from 'react';

import { Button, Col, Row, notification } from 'antd';
import { useNavigate } from 'react-router-dom';

import { useAppSelector } from 'src/hooks';
import { AppI, CommonI } from 'src/redux';

import homeI18n from 'src/i18n/home.i18n.json';
import notifI18n from 'src/i18n/notifications.i18n.json';
import styles from './OurServices.module.css';

interface OurServicesI {
    setIsRef?: () => void;
}

const OurServices = ({ setIsRef }: OurServicesI) => {
    const { currentZone, services } = useAppSelector(({ common }: { common: CommonI }) => common);
    const { lang } = useAppSelector(({ app }: { app: AppI }) => app);
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();

    const [cardButton, showCardButton] = useState('');
    return (
        <div className={styles.services}>
            {contextHolder}
            <h2>{homeI18n[lang].ourServicesTitle}</h2>
            <Row id={'servicios'} gutter={[20, 20]} className={`container ${styles.row_services}`}>
            {services.map(({ id, name, photo }) => 
                <Col key={id} style={{ display: 'flex', justifyContent: 'center' }} xs={12} sm={12} md={12} lg={8} xl={8} xxl={8}>
                    <div
                        style={{
                            background: `url(${photo}) 50% 25%`,
                            backgroundSize: 'cover',
                            width: '100%'
                        }}
                    >
                        <div
                            onMouseEnter={() => showCardButton(name[lang])}
                            onMouseLeave={() => showCardButton('')}
                            className={styles.service_card}
                        >
                            <p className={styles.card_title}>{name[lang]}</p>
                            {cardButton === name[lang] &&
                                <Button type='primary' className={styles.service_button} onClick={() => {
                                    if (currentZone.locality) {
                                        navigate(`servicios/${id}`)
                                    } else {
                                        if (setIsRef) setIsRef();
                                        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                                        api.warning({
                                            message: notifI18n[lang].ourservicesTitleNotification,
                                            description: notifI18n[lang].ourservicesDescriptionNotification,
                                            placement: 'topLeft',
                                            key: 'ourservkey'
                                        });
                                    }
                                }}>
                                    {lang === 'es' ? 'Ver Más' : 'See More'}
                                </Button>
                            }
                        </div>
                    </div>
                </Col>
                )}
            </Row>
            <p style={{ fontFamily: 'Gotham', fontSize: 14 }}>{homeI18n[lang].common}</p>
        </div>
    )
}
export default OurServices

