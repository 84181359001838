import { useEffect, useState } from 'react';

import { Input as AInput, Button, Col, Modal, notification, Row } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import { glLettersLogo } from 'src/assets/images';
import { Input } from 'src/components';
import { useAppSelector } from 'src/hooks';
import { AppI } from 'src/redux';
import { emailValidation, ucFirst } from 'src/utils';

import formI18n from 'src/i18n/form.i18n.json';
import glI18n from 'src/i18n/goodlookero.i18n.json';
import notifI18n from 'src/i18n/notifications.i18n.json';

const FormGoodlookero = () => {
    const { lang } = useAppSelector(({ app }: { app: AppI }) => app);

    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();
    const [name, setName] = useState('');
    const [age, setAge] = useState('');
    const [speciality, setSpeciality] = useState('');
    const [email, setEmail] = useState('');
    const [link, setLink] = useState('');
    const [phone, setPhone] = useState('');
    const [brands, setBrands] = useState('');
    const [beGood, setBeGood] = useState('');
    
    const [nameEmpty, setNameEmpty] = useState(false);
    const [ageEmpty, setAgeEmpty] = useState(false);
    const [specialityEmpty, setSpecialityEmpty] = useState(false);
    const [linkEmpty, setLinkEmpty] = useState(false);
    const [emailEmpty, setEmailEmpty] = useState(false);
    const [phoneEmpty, setPhoneEmpty] = useState(false);
    const [brandsEmpty, setBrandsEmpty] = useState(false);
    const [beGoodEmpty, setBeGoodEmpty] = useState(false);

    const [successModal, setSuccessModal] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);

    const handleSubmit = async (e:any) => {
        e.preventDefault();
        setSuccessModal(true);
        try {
            const body = {
                service_id: process.env.REACT_APP_EMAIL_SERVICE_ID || '',
                template_id: process.env.REACT_APP_EMAIL_SUBSCRIBE_TEMPLATE || '',
                user_id: process.env.REACT_APP_EMAIL_KEY || '',
                template_params: {
                    title: 'Solicitud de Goodlookero para Registro',
                    content: `Adjunto Datos de Formulario\n
                    DATOS PERSONALES:\n
                    Nombre: ${name}
                    Edad: ${age}
                    Correo Electrónico: ${email}
                    Teléfono: ${phone}
                    Mi Red Social: ${link}\n
                    DATOS Laborales:\n
                    Especialidad: ${speciality}
                    Marcas con las que trabajo: ${brands}\n\n
                    Motivo para unirme a Good Look: ${beGood}\n\n
                    SALUDOS
                    `
                }
            }
            const { data } = await axios.post('https://api.emailjs.com/api/v1.0/email/send', body) 
            if (data === 'OK') {
                setSuccessModal(true);
            } else {
                api.error({
                    message: notifI18n[lang].goodlookeroTitleNotification,
                    description: notifI18n[lang].goodlookeroDescriptionNotification,
                    placement: 'topLeft',
                    key: 'eventkey'
                });
            }
        } catch (error:any) {
            api.error({
                message: lang === 'es' ? 'Registro de Goodlookero' : 'Goodlookero Registration',
                description: `${notifI18n[lang].goodlookeroDescriptionNotification}. ${error.message}`,
                placement: 'topLeft',
                key: 'eventkey'
            });
        }
    }

    const handleOk = () => {
        setName('')
        setAge('')
        setSpeciality('')
        setEmail('')
        setLink('')
        setPhone('')
        setBrands('');
        setBeGood('');
        setNameEmpty(false);
        setAgeEmpty(false);
        setSpecialityEmpty(false);
        setLinkEmpty(false);
        setEmailEmpty(false);
        setSuccessModal(false);
        navigate('/', { replace: true });
    }

    useEffect(() => {
        if (name && age && speciality && email && link && phone && phone.replace(/ /g, '').length === 10 && brands && beGood) {
            return setIsDisabled(false);
        }
        setIsDisabled(true);
    }, [name, email, phone, speciality, link, brands, beGood, age])

    return (
        <>
            {contextHolder}
            <div style={{ backgroundColor: '#FFF' }}>
                <div className='container p-5'>
                    <h2 style={{ textAlign: 'center' }}>{glI18n[lang].title}</h2>
                    <br />
                    <p style={{ paddingInline: '10%', textAlign: 'center', fontFamily: 'GothamLight', fontSize: 18 }}>{glI18n[lang].description}</p>
                    <br />
                    <form onSubmit={handleSubmit}>
                        <Row style={{ paddingInline: '10%' }} gutter={[10, 5]}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                <h3>{formI18n[lang].contactInfo}</h3>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                <Input type='string' id='name' placeholder={`${formI18n[lang].namePlaceholder}*`}
                                    value={name}
                                    onChange={(e) => {
                                        setName(e.target.value ? ucFirst(e.target.value) : '')
                                        if (e.target.value === '')
                                            return setNameEmpty(true);
                                            setNameEmpty(false);
                                    }}
                                    onBlur={() => { 
                                        if (name === '') 
                                            return setNameEmpty(true)
                                            setNameEmpty(false);
                                    }}
                                    typeColor={nameEmpty ? 'error' : 'default'}
                                    status={nameEmpty ? 'error' : ''}
                                    text={nameEmpty ? formI18n[lang].requiredText : ''}
                                />
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
                                <Input type='number' id='age' placeholder={`${formI18n[lang].agePlaceholder}*`}
                                    value={age}
                                    onChange={(e) => {
                                        setAge(e.target.value)
                                        if (e.target.value === '')
                                            return setAgeEmpty(true);
                                            setAgeEmpty(false);
                                    }}
                                    onBlur={() => { 
                                        if (age === '') 
                                            return setAgeEmpty(true)
                                        setAgeEmpty(false);
                                    }}
                                    typeColor={ageEmpty ? 'error' : 'default'}
                                    status={ageEmpty ? 'error' : ''}
                                    text={ageEmpty ? formI18n[lang].requiredText : ''}
                                />
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
                                <Input type='string' id='speciality' placeholder={`${formI18n[lang].specialityPlaceholder}*`}
                                    value={speciality}
                                    onChange={(e) => {
                                        setSpeciality(e.target.value)
                                        if (e.target.value === '')
                                            return setSpecialityEmpty(true);
                                        setSpecialityEmpty(false);
                                    }}
                                    onBlur={() => { 
                                        if (speciality === '') 
                                            return setSpecialityEmpty(true)
                                        setSpecialityEmpty(false);
                                    }}
                                    typeColor={specialityEmpty ? 'error' : 'default'}
                                    status={specialityEmpty ? 'error' : ''}
                                    text={specialityEmpty ? formI18n[lang].requiredText : ''}
                                />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                <Input type='email' id='email' placeholder={`${formI18n[lang].emailPlaceholder}*`}
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value.trim())
                                        if (e.target.value === '')
                                            return setEmailEmpty(true);
                                        setEmailEmpty(false);
                                    }}
                                    onBlur={() => { 
                                        if (email === '') 
                                            return setEmailEmpty(true)
                                        setEmailEmpty(false);
                                    }}
                                    typeColor={emailEmpty ? 'error' : email && emailValidation(email) ? 'default' : email !== '' ? 'error' : 'default'}
                                    status={emailEmpty ? 'error' : email && emailValidation(email) ? '' : email !== '' ? 'error' : ''}
                                    text={emailEmpty ? formI18n[lang].requiredText : email && emailValidation(email) ? '' : email !== '' ? formI18n[lang].formatError : ''}
                                />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                <Input id='socialmedia' placeholder={`${formI18n[lang].socialMediaPlaceholder}*`}
                                    addonBefore='https://'
                                    value={link}
                                    onChange={(e) => {
                                        setLink(e.target.value ? e.target.value.trim() : '')
                                        if (e.target.value === '')
                                            return setLinkEmpty(true);
                                        setLinkEmpty(false);
                                    }}
                                    onBlur={() => { 
                                        if (link === '') 
                                            return setLinkEmpty(true)
                                        setLinkEmpty(false);
                                    }}
                                    typeColor={linkEmpty ? 'error' : 'default'}
                                    status={linkEmpty ? 'error' : ''}
                                    text={linkEmpty ? formI18n[lang].requiredText : ''}
                                />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                <Input
                                    type='tel' id='phone' placeholder={`${formI18n[lang].numberPlaceHolcer}*`}
                                    typeColor={phoneEmpty ? 'error' : phone && phone.indexOf('_') === -1 ? 'default' : phone !== '' ? 'error' : 'default'}
                                    status={phoneEmpty ? 'error' : phone && phone.indexOf('_') === -1 ? '' : phone !== '' ? 'error' : ''}
                                    text={phoneEmpty ? formI18n[lang].requiredText : ''}
                                    onChange={e => {
                                        const inputValue = e.target.value;

                                        // clean not numeric characters
                                        const cleanedValue = inputValue.replace(/\D/g, '')

                                        // apply mask for phone number
                                        let maskedValue = '';
                                        for (let i=0 ; i < cleanedValue.length ; i++) {
                                            if (i ===2 || i === 6) {
                                                maskedValue+= ' ';
                                            }
                                            maskedValue += cleanedValue[i];
                                        }
                                        setPhone(maskedValue)
                                        if (maskedValue === '' || maskedValue.replace(/ /g, '').length < 10)
                                            return setPhoneEmpty(true);
                                        setPhoneEmpty(false);
                                    }}
                                    onBlur={() => { 
                                        if (phone === '' || phone.replace(/ /g, '').length < 10)
                                            return setPhoneEmpty(true)
                                        setPhoneEmpty(false);
                                    }}
                                    value={phone}
                                    maxLength={12}
                                />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                <Input type='string' id='brands' placeholder={`${formI18n[lang].listBrandsPlaceholder}*`}
                                    value={brands}
                                    onChange={(e) => {
                                        setBrands(e.target.value)
                                        if (e.target.value === '')
                                            return setBrandsEmpty(true);
                                        setBrandsEmpty(false);
                                    }}
                                    onBlur={() => { 
                                        if (brands === '') 
                                            return setBrandsEmpty(true)
                                        setBrandsEmpty(false);
                                    }}
                                    typeColor={brandsEmpty ? 'error' : 'default'}
                                    status={brandsEmpty ? 'error' : ''}
                                    text={brandsEmpty ? formI18n[lang].requiredText : ''}
                                />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                <div className='form-input'>
                                    <AInput.TextArea
                                        id='begoodlook'
                                        name='begoodlook'
                                        placeholder={`${formI18n[lang].whyGoodlookPlaceholder}*`}
                                        rows={4}
                                        maxLength={400}
                                        showCount
                                        value={beGood}
                                        onChange={(e) => {
                                            setBeGood(e.target.value)
                                            if (e.target.value === '')
                                                return setBeGoodEmpty(true);
                                            setBeGoodEmpty(false);
                                        }}
                                        onBlur={() => { 
                                            if (beGood === '') 
                                                return setBeGoodEmpty(true)
                                            setBeGoodEmpty(false);
                                        }}
                                        status={beGoodEmpty ? 'error' : ''}
                                    />
                                    <span className={`span-form ${beGoodEmpty ? 'error' : 'default'}`}>{beGoodEmpty ? formI18n[lang].requiredText : ''}</span>
                                </div>
                            </Col>
                            <Col style={{ textAlign: 'center'}} xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                <Button
                                    htmlType='submit'
                                    size='large' type='primary' ghost
                                    style={{ width: '35%' }}
                                    disabled={isDisabled}
                                >
                                    {formI18n[lang].btnSend}
                                </Button>
                            </Col>
                        </Row>
                    </form>
                </div>
            </div>
            <Modal
                open={successModal}
                onOk={handleOk}
                closable={false}
                centered
                footer={[
                    <Button
                        key="submit" type="primary"
                        onClick={handleOk}
                        ghost
                    >
                        {lang === 'es' ? 'Finalizar' : 'Continue'}
                    </Button>]
                }
            >
                <div style={{ textAlign: 'center'}}>
                    <img src={glLettersLogo} width={70} height={70} alt='gl-letters-logo' />
                    <br />
                    <br />
                    <h2>{lang === 'es' ? 'Gracias por comunicarte con Nosotros' : 'Thank you for communicating with us'}</h2>
                    <p>{lang === 'es' ? 'Nos comunicaremos contigo para más detalles' : 'We will contact you for more details'}</p>
                </div>
            </Modal>
        </>   
    )
}
export default FormGoodlookero;
