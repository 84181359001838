import React, { useEffect } from 'react';

import { Button, Card, Result } from 'antd';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'src/hooks';
import { AppI, initialStateProducts, initialStateZone, setClearIntent, setCurrentProduct, setCurrentZone } from 'src/redux';

import successI18n from 'src/i18n/success.i18n.json';

const Success = () => {
    const { lang } = useAppSelector(({ app }: { app: AppI }) => app);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const folio = new URLSearchParams(window.location.search).get(
        'payment_intent'
    );

    const handleClick = () => {
        navigate('/', { replace: true });
    }

    const handleClear = () => {
        dispatch(setClearIntent());
        dispatch(setCurrentProduct(initialStateProducts));
        dispatch(setCurrentZone(initialStateZone));
    }

    useEffect(() => {
        return () => handleClear()
    }, [])

    return (
        <div className='container' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Card style={{ width: '90%', marginBlock: 20 }}>
                <Result
                    status='success'
                    title={successI18n[lang].title}
                    subTitle={`${successI18n[lang].subtitle} ${folio}`}
                    extra={[
                    <Button type='primary' key='console' onClick={handleClick}>
                        {successI18n[lang].btnEnd}
                    </Button>
                    ]}
                />      
            </Card>
        </div>
      )
}
export default Success;
