import react from 'react';

import dayjs from 'dayjs';
import 'dayjs/locale/es-mx';

dayjs.locale('es-mx');

const Es = () => {

   return (
      <div className='container'>
         <div className='m-5'>
            <h1 className='text-center'>Política de Privacidad en Sitio Web Good Look USA, LLC</h1>
            <hr />
            <h3 style={{ textTransform: 'capitalize' }}>Fecha: {dayjs().format('DD/MMM/YYYY')}</h3>
            <br />
            <div className='text-justified'>
               <h2 id='definiciones'>1. Introducción</h2>
               <br />
               <p>1.1. El propósito de esta política de privacidad es explicar cómo en Good Look USA, LLC (“Good Look”, “nosotros”, “nos” y “nuestro” “nuestra”), tratamos la información de Datos Personales que recopilamos de Usted (“Usted”, “Usuario”, “Cliente”) durante el uso y pago de los servicios del Sitio Web de Good Look USA, LLC, “Sitio Web” (ubicado en <a href='https://goodlook.com.mx' rel='noreferrer' target='_blank'>https://www.goodlook.com.mx</a>).</p>
               <p>1.2. Los términos que no están definidos en esta Política de Privacidad tienen el significado que se les atribuye en nuestros Términos de Uso ubicados en <a href='https://goodlook.com.mx' rel='noreferrer' target='_blank'>https://www.goodlook.com.mx</a></p>
               <p>1.3. Esta política no se aplica a sitios web de terceros a los que usted puede acceder desde nuestro “Sitio Web”, ni incluye otras prácticas de recopilación de información que no se realice por internet.</p>
               <p>1.4. El usuario debe aceptar los actuales lineamientos de protección de datos mediante una confirmación en el “Sitio Web”. Al confirmar estos términos, el usuario manifiesta y acepta las afirmaciones descritas en este documento.</p>
               <br />
               <h2 id='alcance'>2. DEFINICIONES</h2>
               <br/>
               <p>2.1. Para efectos del presente documento, se entenderán de forma enunciativa más no limitativa, las siguientes definiciones:</p>
               <ol type='a'>
                  <li><strong>“Good Look”:</strong> Good Look USA, LLC, es la empresa establecida en el estado de Texas y responsable de recopilar y salvaguardar sus datos personales.</li>
                  <li><strong>Aviso de Privacidad:</strong> El presente documento electrónico o en cualquier otro formato generado por el responsable que es puesto a disposición del titular, previo al tratamiento de sus datos personales.</li>
                  <li><strong>Bases de datos/Sistema de registros:</strong> Se refiere al conjunto organizado de datos personales relacionados con una persona identificada o identificable. Este grupo de registros está bajo el control de una entidad, y la información se obtiene a través de un identificador único personal.</li>
                  <li><strong>Consentimiento:</strong> Manifestación de la voluntad del titular de los datos mediante la cual se efectúa el tratamiento de estos.</li>
                  <li><strong>Datos personales:</strong> Cualquier información concerniente a una persona física identificada o identificable. origen racial o étnico, estado de salud presente y futuro, información genética, creencias religiosas, filosóficas y morales, afiliación sindical, opiniones políticas, preferencia sexual.</li>
                  <li><strong>Perfil:</strong> Se refiere a la recopilación de configuraciones que en conjunto determinan la información que se incluirá o excluirá de un Informe en particular. Por ejemplo, una cuenta de usuario es un perfil.</li>
                  <li><strong>Responsable:</strong> Persona física y moral de carácter privado que decide sobre el tratamiento de datos personales.</li>
                  <li><strong>Tercero:</strong> La persona física o moral, nacional o extranjera, distinta del titular o del responsable de los datos.</li>
               </ol>
               <br />
               <h2 id='naturalezanegocio'>3. DATOS PERSONALES RECABADOS</h2>
               <br/>
               <p>3.1. Cuando usted visita nuestro “Sitio Web” para leer páginas o descargar información, automáticamente recopilamos y almacenamos la siguiente información, que no constituye información personal identificable:</p>
               <ol type='i'>
                  <li>La dirección de protocolo de internet (IP) desde la que usted accedió a nuestro sitio web. Una dirección IP es un número único que se le asigna automáticamente a la computadora que usted está usando cada vez que navega en internet. Este número se usa para nuestros protocolos de seguridad en internet, para ayudar a proteger la seguridad de los sistemas de tecnología de la información de Good Look.</li>
                  <li>El tipo de navegador, como Safari, Internet Explorer o Firefox, y el sistema operativo, como Windows, macOS o Linux, que se usaron para acceder a nuestro sitio, para optimizar su experiencia en la página web.</li>
                  <li>La fecha y la hora en que se accede a nuestro sitio web, para monitorear la demanda.</li>
                  <li>Las páginas que se visitaron, con el fin de mejorar la utilidad de nuestro sitio web al proporcionar enlaces útiles y quitar páginas que no se leen.</li>
               </ol>
               <p>La información que conservamos no incluye información personal identificable. La información anteriormente mencionada se mantiene en los registros de nuestros sistemas para ayudarnos a que nuestro sitio sea más útil para quienes lo visitan, para saber el número de visitantes que tiene nuestro sitio y los tipos de tecnología que usan. Los registros se pueden conservar indefinidamente y utilizar de cualquier modo necesario para evitar una violación de la seguridad y garantizar la integridad de los datos en nuestros servidores.</p>
               <p>3.2. <strong>De forma personal:</strong> Cuando usted mismo nos lo proporciona por diversos medios electrónicos personalmente, mediante contacto vía mensajería instantánea, vía correo electrónico, en la suscripción de nuestros servicios, en la compra de nuestros productos vía internet, cuando nos contacta por las plataformas de mensajería instantánea, participa en las encuestas de los usuarios, cuando participa en nuestras promociones o bien cuando solicita la facturación de un producto o servicio. </p>
               <ol type='i'>
                  <li>
                     Los datos pueden ser entre otros:<br />
                     <ol type='a'>
                        <li>Nombre y Apellido</li>
                        <li>Domicilio completo</li>
                        <li>Correo electrónico</li>
                        <li>Número de contacto</li>
                     </ol>
                     De igual manera, el sistema del “Sitio Web” podrá arrojar la acreditación de validación de seguridad correspondiente. <br /><br />
                     Good Look no recopila, ni almacena cuentas financieras, los datos bancarios serán recabados por plataformas externas, mediante un certificado de validación y verificación de datos.  Si no quiere enviar la información de autenticación o de pago requerida, no podrá realizar sus compras del Sitio Web de Good Look.
                  </li>
               </ol>
               <p>3.3. Las Redes sociales: Son una plataforma de comunicación e interconexión dentro de plataformas digitales de los diferentes usuarios, son ajenas a “Good Look” y/o su titular y sus AFILIADAS y, por lo tanto, no se encuentran bajo su responsabilidad.</p>
               <p>3.4. Sin embargo, Good Look, podrá solicitar a través de sus plataformas de las denominadas redes sociales, así como también de las determinadas como aplicaciones de mensajería instantánea la siguiente información:</p>
               <ol type='a'>
                  <li>Nombre y apellido</li>
                  <li>Número de contacto</li>
                  <li>Correo electrónico</li>
               </ol>
               <p>3.5. Le informamos que en nuestro Sitio Web podremos utilizar cookies, o plataformas externas para reconocer dispositivos, más no para rastrearlos; de las cuales es posible monitorear su comportamiento como usuario de Internet, así como brindarle de publicidad un mejor servicio y experiencia de usuario al navegar en nuestro Sitio Web.</p>
               <p>3.6. Así mismo, las plataformas de almacenamiento de información podrán establecerse en otros países y con terceros proveedores, inherentes al objeto del responsable.</p>
               <br />
               <h2 id='visitantesyusuarios'>4. Ley de Protección de la Privacidad Infantil en Internet (COPPA, por sus siglas en inglés).</h2>
               <br/>
               <p>4.1. Good Look, en estricto cumplimiento con la Ley de Protección de la Privacidad Infantil en Internet (COPPA), Título 16 del Código Federal de Regulaciones, Parte 312, se ajusta a los requisitos establecidos por dicha ley para los operadores de sitios web destinados a niños menores de 13 años y para aquellos que tienen conocimiento de la recopilación y mantenimiento de información de niños menores de 13 años. Conforme a la COPPA, se requiere obtener el consentimiento verificable de los padres antes de recopilar, utilizar o divulgar información personal de los niños. En el caso de Good Look, solo se permitirá la contratación de servicios a mayores de edad. Sin embargo, no asumiremos responsabilidad si un menor de 13 años ingresa sus datos de manera intencional, siendo esta responsabilidad exclusiva del padre o tutor legal.</p>
               <br />
               <h2 id='registrousuario'>5. FINALIDADES DEL TRATAMIENTO DE SUS DATOS PERSONALES</h2>
               <br/>
               <p>
                  5.1. Sus datos personales serán utilizados para las siguientes finalidades:
               </p>
               <ol type='a'>
                  <li>Facilitar la creación y asegurar su Cuenta en nuestra red;</li>
                  <li>Suscripción a los servicios;</li>
                  <li>Facilitar los servicios de belleza en el domicilio que solicita;</li>
                  <li>Identificarlo como usuario en nuestro sistema;</li>
                  <li>Evaluaciones periódicas de los productos y servicios;</li>
                  <li>Mejorar la calidad de la experiencia cuando interactúa con nuestros Servicios</li>
                  <li>Marketing Digital;</li>
                  <li>Atención a clientes;</li>
                  <li>Enviarle notificaciones administrativas por correo electrónico, como avisos de seguridad o de soporte y mantenimiento;</li>
                  <li>Así también para mantener un registro, con fines de informes financieros, auditoría y cumplimiento; además para cumplir con las obligaciones legales que se puedan derivar por la relación comercial y de servicios, de Good Look y usted.</li>
               </ol>
               <br />
               <h2 id='comprasypagos'>6. UNA NOTA PARA LOS USUARIOS FUERA DE LOS ESTADOS UNIDOS</h2>
               <br/>
               <p>6.1. Sus Datos Personales pueden ser procesados en el país en el que fueron recopilados y en otros países, incluidos los Estados Unidos, donde las leyes relacionadas con el procesamiento de Datos Personales pueden ser menos estrictas que las leyes en su país.</p>
               <br />
               <h2 id='cancelacionreembolsos'>7. TRANSFERENCIA DE DATOS</h2>
               <br/>
               <p>7.1. Sus datos personales pueden ser transferidos y tratados dentro del país, por personas distintas a Good Look.</p>
               <p>7.2. En este contexto, su información puede ser compartida con colaboradores y proveedores de servicios independientes de Good Look con el fin de garantizar la correcta prestación de los servicios pagados a través del Sitio Web.</p>
               <p>7.3. La transferencia de información se llevará a cabo con los siguientes propósitos:</p>
               <ol type='a'>
                  <li><strong>Desarrollar y mantener la seguridad de Good Look:</strong> Garantizamos la protección y continuidad de la seguridad de Good Look mediante la transferencia de datos para el monitoreo, análisis y mejora continua de nuestros sistemas y protocolos de seguridad.</li>
                  <li><strong>Con proveedores de servicios independientes:</strong> Compartiremos información con colaboradores y proveedores externos para asegurar la prestación eficiente y efectiva de los servicios ofrecidos a través de Good Look. Esto incluye la coordinación de citas, programar servicios de belleza a domicilio y otros aspectos esenciales para satisfacer las necesidades de nuestros usuarios.</li>
                  <li><strong>Con proveedores de software e ingeniería:</strong> Colaboraremos con proveedores especializados en software e ingeniería para garantizar el desarrollo, la mejora y la innovación continua de nuestras plataformas y servicios digitales. Esta colaboración busca optimizar la experiencia del usuario y mantener nuestros sistemas actualizados con las últimas tecnologías y mejores prácticas.</li>
                  <li><strong>Afiliadas y Adquisiciones:</strong> Podemos compartir algunos o todos sus Datos personales con nuestra empresa matriz empresa constituida bajo las Leyes de México, subsidiarias, empresas conjuntas u otras empresas bajo un control común (&ldquo;Afiliados&rdquo;), en cuyo caso exigiremos a nuestros Afiliados que respeten esta Política de Privacidad. Si otra empresa adquiere nuestra empresa, negocio o nuestros activos, esa empresa poseerá los Datos personales recopilados por nosotros y asumirá los derechos y obligaciones con respecto a sus Datos personales como se describe en esta Política de privacidad.</li>
                  <li><strong>Por razones legales:</strong> Compartiremos información personal fuera de Good Look si creemos de buena fe que el acceso, el uso, la conservación o la divulgación de la información es razonablemente necesario para: (i) Cumplir con cualquier ley, reglamento, proceso legal o solicitud gubernamental aplicable. (ii) Compartimos información sobre la cantidad y el tipo de solicitudes que recibimos de los gobiernos en nuestro Informe de Transparencia. (iii) Hacer cumplir los Términos de servicio aplicables, incluida la investigación de posibles infracciones. (iv) Detectar, prevenir o abordar de otro modo fraudes, seguridad o problemas técnicos. (v) Proteger contra daños a los derechos, la propiedad o la seguridad de Good Look, nuestros usuarios o el público según lo requiera o permita la ley.</li>
               </ol>
               <p>7.4. Para solicitar un reembolso sólo en los casos aplicables en la presente cláusula, Usted deberá de enviar un correo electrónico <a href='mailto:hello@goodlook.com.mx' rel='noreferrer' target='_blank'>hello@goodlook.com.mx</a> para recibir atención al cliente.</p>
               <br />
               <h2 id='vigencia'>8. OPCIONES EN RELACIÓN CON SUS DATOS PERSONALES:</h2>
               <br/>
               <ol type='a'>
                  <li>
                     Opciones de Comunicación: <br /><br />
                     <ol type='i'>
                        <li>Ofrecemos alternativas en relación con la recolección, uso y compartición de sus datos personales. Podremos enviarle boletines informativos y correos electrónicos promocionales que directamente fomentan la utilización de nuestros servicios. En cualquier momento, puede expresar su preferencia de dejar de recibir estas comunicaciones al elegir no participar, siguiendo las indicaciones de cancelación de suscripción proporcionadas en los correos electrónicos recibidos o contactándonos directamente.</li>
                        <li>A pesar de sus preferencias de correo electrónico, es posible que le enviemos comunicaciones vinculadas al servicio, como notificaciones de actualizaciones en nuestros Términos de uso o Política de privacidad.</li>
                     </ol>
                  </li>
                  <li>
                     Modificacion de Datos Personales:<br /><br />
                     Puede actualizar cualquier información personal en su cuenta al enviarnos un correo electrónico a la dirección indicada más abajo. También puede solicitar la eliminación de sus datos personales, aunque es importante señalar que conservaremos registros comerciales de transacciones y podría ser necesario retener cierta información por razones legales o regulatorias. Cuando eliminamos información, esta se borra de la base de datos activa, aunque pueda persistir en nuestros archivos, por ejemplo, para la prevención de fraudes u otros propósitos similares.
                  </li>
                  <li>
                     Seguridad de Sus Datos Personales:<br /><br />
                     Estamos comprometidos a resguardar la seguridad de sus datos personales mediante la implementación de tecnologías y procedimientos de seguridad estándar de la industria. Aunque solicitamos una contraseña para acceder a su información de cuenta, ningún método de transmisión por Internet o almacenamiento electrónico es completamente infalible. A pesar de nuestros esfuerzos razonables, no podemos garantizar la seguridad absoluta de sus datos personales.
                  </li>
               </ol>
               <br />
               <h2 id='fallas'>9. MODIFICACIÓN AL AVISO DE PRIVACIDAD</h2>
               <br/>
               <p>9.1. Nos reservamos el derecho de efectuar en cualquier momento modificaciones o actualizaciones al presente aviso de privacidad, para atención de novedades legislativas o reglamentarias, políticas internas, nuevos requerimientos para la prestación u ofrecimiento de nuestros servicios o productos y prácticas del mercado. Estas modificaciones estarán disponibles al público a través de los medios electrónicos correspondientes, a los correos electrónicos que nos haya proporcionado y por medio de nuestra web <a href='https://goodlook.com.mx' rel='noreferrer' target='_blank'>https://www.goodlook.com.mx/</a></p>
               <br />
               <h2 id='derechosylicencias'>10. SEGURIDAD DE SU INFORMACIÓN PERSONAL</h2>
               <br/>
               <p>10.1. Nos comprometemos a salvaguardar la integridad de sus datos personales mediante la implementación de diversas tecnologías y protocolos de seguridad reconocidos en la industria. Empleamos medidas estándar para prevenir el acceso, uso o divulgación no autorizados de sus datos personales. Asimismo, le solicitamos que establezca una contraseña para acceder a su información de cuenta y le recordamos que evite compartir dicha contraseña con personas no autorizadas. A pesar de nuestros esfuerzos rigurosos, es importante destacar que ningún método de transmisión por internet o almacenamiento electrónico puede considerarse completamente infalible. Por lo tanto, aunque hacemos todos los esfuerzos razonables para proteger sus datos personales, no podemos garantizar su seguridad absoluta.</p>
               <br />
               <h2 id='limitacion'>11. COMUNIQUESE CON NOSOTROS</h2>
               <br/>
               <p>11.1. ¿Necesita más información sobre cómo Good Look protege la privacidad y la confidencialidad? Envié correo a nuestra área de Privacidad <a href='https://hello@goodlook.com.mx' rel='noreferrer' target='_blank'>hello@goodlook.com.mx</a></p>
               <br />
               <h2 id='propiedadintelectual'>12. SOLICITUDES DE DATOS</h2>
               <br/>
               <p>12.1. Para obtener información sobre solicitudes de datos, comuníquese con Good Look por correo electrónico a <a href='https://hello@goodlook.com.mx' rel='noreferrer' target='_blank'>hello@goodlook.com.mx</a></p>
            </div>
         </div>
      </div>
   )
 }
export default Es;