import React, { useState } from 'react';

import { Button, Col, Input, Modal, Row, notification } from 'antd';
import axios from 'axios';

import homeI18n from 'src/i18n/home.i18n.json';
import { useAppSelector } from 'src/hooks';
import { AppI } from 'src/redux';
import { glLettersLogo } from 'src/assets/images';
import { emailValidation } from 'src/utils';

import notifI18n from 'src/i18n/notifications.i18n.json';
import styles from './Subscribe.module.css';

const Subscribe = () => {
    const { lang } = useAppSelector(({ app }: { app: AppI }) => app);
    const [email, setEmail] = useState('');
    const [successModal, setSuccessModal] = useState(false);
    const [api, contextHolder] = notification.useNotification();

    const handleSubmit = async (e:any) => {
        e.preventDefault();
        try {
            const body = {
                service_id: process.env.REACT_APP_EMAIL_SERVICE_ID || '',
                template_id: process.env.REACT_APP_EMAIL_SUBSCRIBE_TEMPLATE || '',
                user_id: process.env.REACT_APP_EMAIL_KEY || '',
                template_params: {
                    title: 'Suscripción a Newsletter',
                    content: `Hola\nEl correo de una nueva suscripción a Newsletter es:\n${email}`
                }
            }
            const { data } = await axios.post('https://api.emailjs.com/api/v1.0/email/send', body) 
            if (data === 'OK') {
                setSuccessModal(true);
            } else {
                api.error({
                    message: notifI18n[lang].subscribeTitleNotification,
                    description: notifI18n[lang].subscribeDescriptionNotification,
                    placement: 'topLeft',
                    key: 'subscribekey'
                });
            }
        } catch (error:any) {
            api.error({
                message: notifI18n[lang].subscribeTitleNotification,
                description: `${notifI18n[lang].subscribeDescriptionNotification}, ${error.message}`,
                placement: 'topLeft',
                key: 'subscribekey'
            });
        }
    }

    const handleOk = () => {
        setEmail('');
        setSuccessModal(false);
    }

    return (
        <div className={styles.contain}>
            {contextHolder}
            <form className={styles.subcontain} onSubmit={emailValidation(email) ? handleSubmit : (e) => e.preventDefault()}>
                <Row>
                    <Col className={styles.subscribe} xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                        <h2>{homeI18n[lang].subscribeTitle} <span>{homeI18n[lang].subscribeConnector}</span></h2>
                        <h2>{homeI18n[lang].subscribeDescription}</h2>
                    </Col>
                    <Col className={styles.form} xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                        <div className={styles.form_input}>
                            <Input
                                style={{ width: '100%' }}
                                placeholder={homeI18n[lang].inputEmail}
                                size='large'
                                type='email'
                                value={email}
                                onChange={(e) => setEmail(e.target.value ? e.target.value.toLowerCase().trim() : '')}
                            />
                        </div>
                        <div className={styles.form_button}>
                            <Button htmlType='submit' style={{ width: '100%' }} ghost size='large'>
                                {homeI18n[lang].btnSusbcribe}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </form>
            <Modal
                open={successModal}
                onOk={handleOk}
                centered
                footer={[
                    <Button key="submit" type="primary" onClick={handleOk} ghost>
                        Ok
                    </Button>]
                }
            >
                <div style={{ textAlign: 'center'}}>
                    <img src={glLettersLogo} width={70} height={70} alt='gl-letters-logo' />
                    <br />
                    <br />
                    <h2>{lang === 'es' ? 'Gracias por suscribirte a nuestro Newsletter' : 'Thanks for subscribe to our Newsletter'}</h2>
                </div>
            </Modal>
        </div>
    )
}
export default Subscribe;
