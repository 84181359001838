import { Action, CombinedState, combineReducers, configureStore } from '@reduxjs/toolkit';
import { 
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { Interceptor } from 'src/api';

import { appSlice } from './app';
import { commonSlice } from './common';
import { cartSlice } from './cart';
import { StoreI } from './schema';
import { userSlice } from './user';


const rootReducer = combineReducers<StoreI>({
    app: appSlice.reducer,
    common: commonSlice.reducer,
    cart: cartSlice.reducer,
    user: userSlice.reducer
})

type AppReducer = CombinedState<StoreI>;

const appReducer = (state: StoreI, action: Action): AppReducer => {
    // if (action.type === 'APP/logout') {
    //     state = {
    //         ...state,
    //         example: exampleInitialState
    //     }
    // }

    return rootReducer(state, action);
}

const rootPersistConfig = {
    key: 'root',
    storage,
    whitelist: ['app', 'common', 'cart']
}
  
const persistedReducer = persistReducer<any, Action<StoreI>>(rootPersistConfig, appReducer);

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        })
});
// pass store to Interceptor
Interceptor(store);
// persisting store
export const persistor = persistStore(store);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
