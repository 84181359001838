import { DownOutlined } from '@ant-design/icons';

export const getOption = (value: string, lang: string, customStyle?: string) => {
    switch (value) {
        case 'es':
            return <div style={{ width: '100%', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className={customStyle || ''}>
                    <p>
                        <span role='img' aria-label='mx'>
                            🇲🇽
                        </span>
                        {lang === 'es' ? 'México' : 'Mexico'}
                    </p>
                </div>
                <div>
                    <DownOutlined style={{ color: '#D5D5D5'}} />
                </div>
            </div>
        case 'en':
            return <div style={{ width: '100%', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className={customStyle || ''}>
                    <p style={{ }}>
                        <span role='img' aria-label='eu'>
                            🇺🇸
                        </span>
                        {lang === 'es' ? 'Estados Unidos' : 'United States'}
                    </p>
                </div>
                <div>
                    <DownOutlined style={{ color: '#D5D5D5'}} />
                </div>
            </div>
        case 'br':
            return <div style={{ width: '100%', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className={customStyle || ''}>
                    <p>
                        <span role='img' aria-label='br'>
                            🇧🇷
                        </span>{lang === 'es' ? 'Brasil' : 'Brazil'}
                    </p>
                </div>
                <div>
                    <DownOutlined style={{ color: '#D5D5D5'}} />
                </div>
            </div>
    }
} 