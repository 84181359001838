import React, { useState } from 'react';

import { Button, Col, Modal, Row } from 'antd';

import { glLettersLogo, howWork } from 'src/assets/images';
import { Input } from 'src/components';
import { useAppSelector } from 'src/hooks';
import { AppI } from 'src/redux';

import homeI18n from 'src/i18n/home.i18n.json';
import formI18n from 'src/i18n/form.i18n.json';

import styles from './HowWork.module.css';
import { emailValidation, ucFirst } from 'src/utils';
import { postCustomer } from 'src/api';
import SuccessCodeModal from './SuccessCodeModal';

const getRandomP = () => {
    const arr = ['brocha', 'peinado', 'rimel', 'estilista', 'peine', 'tijeras', 'sobras', 'tintes', 'secadora', 'peinado'];

    // Generar un índice aleatorio dentro del rango del arreglo
    const randomIndex = Math.floor(Math.random() * arr.length);

    // Generar un número aleatorio de dos dígitos entre 10 y 99
    const randomNumber = Math.floor(Math.random() * 90) + 10;

    // Concatenar el elemento del arreglo con el número aleatorio
    return `${arr[randomIndex]}${randomNumber}`;
}

const HowWork = () => {
    const { lang } = useAppSelector(({ app }: { app: AppI }) => app);
    const [openModal, setOpenModal] = useState(false);
    const [alertModal, setAlertModal] = useState(false);
    const [responseSuccess, setResponseSuccess] = useState(false);
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [code, setCode] = useState('');
    const [loading, setLoading] = useState(false);
    
    const [nameEmpty, setNameEmpty] = useState(false);
    const [lastNameEmpty, setLastNameEmpty] = useState(false);
    const [emailEmpty, setEmailEmpty] = useState(false);
    const [phoneEmpty, setPhoneEmpty] = useState(false);

    const handleConfirm = async () => {
        try {
            setLoading(true);
            const body = {
                name,
                lastnames: lastName,
                email,
                phone: phone.replace(/ /g, ''),
                password: getRandomP()
            };
            
            const resp = await postCustomer(body);
            
            if (resp.success === 'true') {
                setOpenModal(false);
                setAlertModal(true);
                setResponseSuccess(true);
                setCode(resp.data.promo_code);
            } else {
                setOpenModal(false);
                setAlertModal(true);
                setResponseSuccess(false);
            }
            setLoading(false);
        } catch (error: any) {
            console.log(error);
            setLoading(false);
        }
    }

    const clearForm = () => {
        setName('')
        setLastName('')
        setEmail('')
        setPhone('')
        setCode('')
    }

    return (
        <>
        <div>
            <Row>
                <Col className={styles.step_col} xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <h3 className={styles.step_title}>{homeI18n[lang].howWorkTitle}</h3>
                    <h2>{homeI18n[lang].howWorkSubtitle}</h2>
                    {homeI18n[lang].howWorkSteps.map(item => (
                        <Row key={item.id}>
                            <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} xs={4} sm={4} md={2} lg={2} xl={2} xxl={2}>
                                <h2>{item.id}</h2>
                            </Col>
                            <Col xs={20} sm={20} md={22} lg={22} xl={22} xxl={22}>
                                <h3>{item.title}</h3>
                                <p style={{ fontWeight: 400, fontStyle: 'normal', fontSize: '0.8rem' }}>{item.description}</p>
                            </Col>
                        </Row>
                    ))}
                    <Button size='large' type='primary' ghost onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}>{homeI18n[lang].btnSchedule}</Button>
                </Col>
                <Col style={{ minHeight: 377 }} xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <img src={howWork} alt='how-work-img' width='100%' height={'100%'} />
                </Col>
            </Row>
            <div className={styles.code_container}>
                <Row className={styles.code_container_row}>
                    <Col className={styles.code_container_col} xs={24} sm={24} md={12} lg={16} xl={16} xxl={16}>
                        <h1>{homeI18n[lang].howWorkTitleCode}</h1>
                        <p>{homeI18n[lang].howWorkDescriptionCode}</p>
                    </Col>
                    <Col className={styles.code_container_col} xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                        <Button className={styles.get_code_btn} size='large' type='primary' ghost onClick={() => setOpenModal(true)}>{homeI18n[lang].howWorkbtnGetCode}</Button>
                    </Col>
                </Row>
            </div>
        </div>
        <Modal
            centered
            open={openModal}
            onOk={() => setOpenModal(false)}
            onCancel={() => setOpenModal(false)}
            closable
            footer={[
                <Button key="submit" type="primary" onClick={handleConfirm} loading={loading} ghost>
                    {homeI18n[lang].howWorkCodeModalBtn}
                </Button>]
            }
        >
            <div style={{ textAlign: 'center'}}>
                <img src={glLettersLogo} width={70} height={70} alt='gl-letters-logo' />
                <br />
                <br />
                <p>{homeI18n[lang].howWorkDescriptionModal}</p>
                <Input
                    id='name'
                    name='name'
                    placeholder={`${formI18n[lang].namesPlaceHolder}*`}
                    value={name}
                    onChange={(e) => {
                        setName(e.target.value ? ucFirst(e.target.value) : '')
                        if (e.target.value === '')
                            return setNameEmpty(true);
                            setNameEmpty(false);
                    }}
                    onBlur={() => { 
                        if (name === '') 
                            return setNameEmpty(true)
                            setNameEmpty(false);
                    }}
                    typeColor={nameEmpty ? 'error' : 'default'}
                    status={nameEmpty ? 'error' : ''}
                    text={nameEmpty ? `${formI18n[lang].requiredText}` : ''}
                />
                <Input
                    id='lastname'
                    name='lastname'
                    placeholder={`${formI18n[lang].lastNamesPlaceHolder}*`}
                    value={lastName}
                    onChange={(e) => {
                        setLastName(e.target.value ? ucFirst(e.target.value) : '')
                        if (e.target.value === '')
                            return setLastNameEmpty(true);
                            setLastNameEmpty(false);
                    }}
                    onBlur={() => { 
                        if (lastName === '') 
                            return setNameEmpty(true)
                            setNameEmpty(false);
                    }}
                    typeColor={lastNameEmpty ? 'error' : 'default'}
                    status={lastNameEmpty ? 'error' : ''}
                    text={lastNameEmpty ? `${formI18n[lang].requiredText}` : ''}
                />
                <Input
                    id='email'
                    name='email'
                    placeholder={`${formI18n[lang].emailPlaceholder}*`}
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value.trim())
                        if (e.target.value === '')
                            return setEmailEmpty(true);
                        setEmailEmpty(false);
                    }}
                    onBlur={() => { 
                        if (email === '') 
                            return setEmailEmpty(true)
                        setEmailEmpty(false);
                    }}
                    typeColor={emailEmpty ? 'error' : email && emailValidation(email) ? 'default' : email !== '' ? 'error' : 'default'}
                    status={emailEmpty ? 'error' : email && emailValidation(email) ? '' : email !== '' ? 'error' : ''}
                    text={emailEmpty ? `${formI18n[lang].requiredText}` : email && emailValidation(email) ? '' : email !== '' ? `${formI18n[lang].formatError}` : ''}
                />
                <Input
                    type='tel' id='phone' placeholder={`${formI18n[lang].numberPlaceHolcer}*`}
                    typeColor={phoneEmpty ? 'error' : phone && phone.indexOf('_') === -1 ? 'default' : phone !== '' ? 'error' : 'default'}
                    status={phoneEmpty ? 'error' : phone && phone.indexOf('_') === -1 ? '' : phone !== '' ? 'error' : ''}
                    text={phoneEmpty ? `${formI18n[lang].requiredText}` : ''}
                    onChange={e => {
                        const inputValue = e.target.value;

                        // clean not numeric characters
                        const cleanedValue = inputValue.replace(/\D/g, '')

                        // apply mask for phone number
                        let maskedValue = '';
                        for (let i=0 ; i < cleanedValue.length ; i++) {
                            if (i ===2 || i === 6) {
                                maskedValue+= ' ';
                            }
                            maskedValue += cleanedValue[i];
                        }
                        setPhone(maskedValue)
                        if (maskedValue === '' || maskedValue.replace(/ /g, '').length < 10)
                            return setPhoneEmpty(true);
                        setPhoneEmpty(false);
                    }}
                    onBlur={() => { 
                        if (phone === '' || phone.replace(/ /g, '').length < 10)
                            return setPhoneEmpty(true)
                        setPhoneEmpty(false);
                    }}
                    value={phone}
                    maxLength={12}
                />
            </div>
        </Modal>
        <SuccessCodeModal
            openModal={alertModal}
            success={responseSuccess}
            handleModal={() => {
                setAlertModal(false);
                clearForm();
            }}
            code={code}
        />
        </>
    )
}
export default HowWork
