import React from 'react';

import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import { useAppSelector } from 'src/hooks';
import { AppI } from 'src/redux';
import Carousel from 'react-multi-carousel';

import homeI18n from 'src/i18n/home.i18n.json';
import styles from './OurProfessionals.module.css';
import 'react-multi-carousel/lib/styles.css';
import { professionals } from './professionals';

const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1200 },
      items: 3,
      slidesToSlide: 2
    },
    desktop: {
      breakpoint: { max: 1200, min: 768 },
      items: 3,
      slidesToSlide: 2
    },
    tablet: {
      breakpoint: { max: 768, min: 576 },
      items: 2,
      slidesToSlide: 1
    },
    mobile: {
      breakpoint: { max: 576, min: 0 },
      items: 1,
      slidesToSlide: 1
    }
  }

const OurProfessionals = () => {
    const { lang } = useAppSelector(({ app }: { app: AppI }) => app);

    return (
       <div className={styles.our_professionals}>
          <div className='container' style={{ textAlign: 'center' }}>
             <h2>{homeI18n[lang].ourProfessionalsTitle}</h2>
             <p style={{ marginBottom: 0, fontWeight: 400 }}>{homeI18n[lang].ourProfessionalsDescOne}</p>
             <p style={{ marginTop: 0, fontWeight: 400 }}>{homeI18n[lang].ourProfessionalsDescTwo}</p>
             <div style={{ display: 'flex', flexDirection: 'column' }}>
                {professionals && professionals.length !== 0 ? (
                   <>
                      <Carousel className={styles.carousel} responsive={responsive} autoPlay infinite={true} autoPlaySpeed={5000}>
                         {professionals
                            .filter((_, i) => i < professionals.length)
                            .map((i, index) => (
                               <div style={{ width: '100%' }} key={index}>
                                  {i.url ? (
                                     <>
                                        <img src={i.url} className={styles.professionals_img} />
                                        <p style={{ marginTop: 5 }}>{i.professional}</p>
                                     </>
                                  ) : (
                                     <div
                                        className={styles.professionals_img}
                                        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                                     >
                                        <Avatar size={{ xs: 90, sm: 90, md: 120, lg: 150, xl: 200, xxl: 300 }} icon={<UserOutlined />} />
                                        <p>{i.professional}</p>
                                     </div>
                                  )}
                               </div>
                            ))}
                      </Carousel>
                   </>
                ) : (
                   <div>
                      <h4>No se encontraron resultados</h4>
                   </div>
                )}
             </div>
          </div>
       </div>
    );
}
export default OurProfessionals
