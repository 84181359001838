import { createSlice } from '@reduxjs/toolkit';

import { commonInitialState } from '../schema';

export const commonSlice = createSlice({
    name: 'COMMON',
    initialState: commonInitialState,
    reducers: {
        setCurrentZone: (state, { payload }) => { state.currentZone = payload },
        setCurrentProduct: (state, { payload }) => { state.currentProduct = payload },
        setCurrentService: (state, { payload }) => { state.currentService = payload },
        citiesIsFetching: (state) => { state.citiesIsFetching = true },
        productsIsFetching: (state) => { state.productsIsFetching = true },
        servicesIsFetching: (state) => { state.servicesIsFetching = true },
        setCities: (state, { payload }) => { 
            state.cities = payload.data;
            state.citiesIsFetching = false;
            state.citiesSuccess = payload.success;
        },
        setProducts: (state, { payload }) => { 
            state.products = payload.data;
            state.productsIsFetching = false;
            state.productsSuccess = payload.success;
        },
        setServices: (state, { payload }) => { 
            state.services = payload.data;
            state.servicesIsFetching = false;
            state.servicesSuccess = payload.success;
        }
    },
});

export const {
    setCurrentZone, setCurrentProduct, setCurrentService,
    setCities, setProducts, setServices, citiesIsFetching, productsIsFetching, 
    servicesIsFetching
} = commonSlice.actions;
