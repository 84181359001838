import React, { useEffect, useState } from 'react';

import { Row } from 'antd';
import { goodlookHero } from 'src/assets/images';

const GoodlookeroHero = () => {
    const [width, setWidth] = useState(window.innerWidth);
    
    useEffect(() => {
        window.addEventListener('resize', () => {
            setWidth(window.innerWidth);
        })
        return () => window.removeEventListener('resize', () => {});
    }, [])

    return (
        <div>
            <Row
                style={{
                    backgroundImage: `url(${goodlookHero})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    minHeight: width > 768 ? '50vh' : '40vh',
                    backgroundPositionY: 'center'
                }}
            />
        </div>
    )
}
export default GoodlookeroHero;
