import React, { useEffect, useRef, useState } from 'react';

import { Button, Checkbox, Col, DatePicker, Modal, Row, Select, notification } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import localeEs from 'antd/es/date-picker/locale/es_ES';
import axios from 'axios';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

import { glLettersLogo } from 'src/assets/images';
import { Input, InputAutocomplete } from 'src/components';
import { useAppSelector } from 'src/hooks';
import { AppI } from 'src/redux';
import { disabledCurrentDate, emailValidation, typeEnum, ucFirst } from 'src/utils';

import formI18n from 'src/i18n/form.i18n.json';
import notifI18n from 'src/i18n/notifications.i18n.json';

const CODE_MIN_LENGTH = 5;
const CODE_MAX_LENGTH = 15;
const PHONE_MAX_LENGTH = 10;

const FormEvent = () => {
    const { lang } = useAppSelector(({ app }: { app: AppI }) => app);
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();

    const [address, setAddress] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [date, setDate] = useState<any>('')
    const [type, setType] = useState('');
    const[eventCode, setEventCode] = useState('');

    const [nameEmpty, setNameEmpty] = useState(false);
    const [emailEmpty, setEmailEmpty] = useState(false);
    const [phoneEmpty, setPhoneEmpty] = useState(false);
    const [codeEmpty, setCodeEmpty] = useState(false);

    const [checkedTerms, setCheckedTerms] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [successModal, setSuccessModal] = useState(false);

    const handleSubmit = async (e:any) => {
        e.preventDefault();
        try {
            const body = {
                service_id: process.env.REACT_APP_EMAIL_SERVICE_ID || '',
                template_id: process.env.REACT_APP_EMAIL_EVENT_TEMPLATE || '',
                user_id: process.env.REACT_APP_EMAIL_KEY || '',
                template_params: {
                    name,
                    phone: phone.replace(/ /g, ''),
                    email,
                    date: `${dayjs(date).get('D') < 10 ? `0${dayjs(date).get('D')}` : dayjs(date).get('D')}/${dayjs(date).get('M')+1 < 10 ? `0${dayjs(date).get('M')+1}` : dayjs(date).get('M')+1}/${dayjs(date).get('y')}`,
                    address,
                    type: type ? typeEnum.find(item => item.value === type)?.label : '',
                    eventCode
                }
            }
            const { data } = await axios.post('https://api.emailjs.com/api/v1.0/email/send', body) 
            if (data === 'OK') {
                setSuccessModal(true);
            } else {
                api.error({
                    message: notifI18n[lang].eventTitleNotification,
                    description: notifI18n[lang].eventDescriptionNotification,
                    placement: 'topLeft',
                    key: 'eventkey'
                });
            }
        } catch (error:any) {
            api.error({
                message: notifI18n[lang].eventTitleNotification,
                description: `${notifI18n[lang].eventDescriptionNotification} ${error.message}`,
                placement: 'topLeft',
                key: 'eventkey'
            });
        }
    }

    const onChangeTerms = (e: CheckboxChangeEvent) => {
        setCheckedTerms(e.target.checked);
    }

    const handleOk = () => {
        setAddress('')
        setName('')
        setEmail('')
        setPhone('')
        setDate('')
        setType('')
        setCheckedTerms(false);
        setSuccessModal(false);
        navigate('/', { replace: true });
    }

    useEffect(() => {
        if (name && address && email && phone && phone.replace(/ /g, '').length === PHONE_MAX_LENGTH && phone.indexOf('_') === -1 && date && type !== '' && eventCode.length >= CODE_MIN_LENGTH && checkedTerms) {
            return setIsDisabled(false);
        }
        setIsDisabled(true);
    }, [name, email, phone, address, date, type, eventCode, checkedTerms])

    return (
        <>
            {contextHolder}
            <div style={{ backgroundColor: '#FFF' }}>
                <div className='container p-5'>
                    <h2 style={{ textAlign: 'center' }}>{formI18n[lang].eventTitle}</h2>
                    <br />
                    <p style={{ textAlign: 'center', fontFamily: 'GothamLight', fontSize: 18 }}>{formI18n[lang].eventSubtitle}</p>
                    <br />
                    <form onSubmit={handleSubmit}>
                        <Row style={{ paddingInline: '5%' }} gutter={[40, 10]}>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                <h3>{formI18n[lang].contactInfo}</h3>
                                <Input
                                    id='name'
                                    name='name'
                                    placeholder={`${formI18n[lang].namePlaceholder}*`}
                                    value={name}
                                    onChange={(e) => {
                                        setName(e.target.value ? ucFirst(e.target.value) : '')
                                        if (e.target.value === '')
                                            return setNameEmpty(true);
                                            setNameEmpty(false);
                                    }}
                                    onBlur={() => { 
                                        if (name === '') 
                                            return setNameEmpty(true)
                                            setNameEmpty(false);
                                    }}
                                    typeColor={nameEmpty ? 'error' : 'default'}
                                    status={nameEmpty ? 'error' : ''}
                                    text={nameEmpty ? `${formI18n[lang].requiredText}` : ''}
                                />
                                <Input
                                    id='email'
                                    name='email'
                                    placeholder={`${formI18n[lang].emailPlaceholder}*`}
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value.trim())
                                        if (e.target.value === '')
                                            return setEmailEmpty(true);
                                        setEmailEmpty(false);
                                    }}
                                    onBlur={() => { 
                                        if (email === '') 
                                            return setEmailEmpty(true)
                                        setEmailEmpty(false);
                                    }}
                                    typeColor={emailEmpty ? 'error' : email && emailValidation(email) ? 'default' : email !== '' ? 'error' : 'default'}
                                    status={emailEmpty ? 'error' : email && emailValidation(email) ? '' : email !== '' ? 'error' : ''}
                                    text={emailEmpty ? `${formI18n[lang].requiredText}` : email && emailValidation(email) ? '' : email !== '' ? `${formI18n[lang].formatError}` : ''}
                                />
                                <Input
                                    type='tel' id='phone' placeholder={'Número de Telefono*'}
                                    typeColor={phoneEmpty ? 'error' : phone && phone.indexOf('_') === -1 ? 'default' : phone !== '' ? 'error' : 'default'}
                                    status={phoneEmpty ? 'error' : phone && phone.indexOf('_') === -1 ? '' : phone !== '' ? 'error' : ''}
                                    text={phoneEmpty ? 'Este es un campo requerido' : ''}
                                    onChange={e => {
                                        const inputValue = e.target.value;

                                        // clean not numeric characters
                                        const cleanedValue = inputValue.replace(/\D/g, '')

                                        // apply mask for phone number
                                        let maskedValue = '';
                                        for (let i=0 ; i < cleanedValue.length ; i++) {
                                            if (i ===2 || i === 6) {
                                                maskedValue+= ' ';
                                            }
                                            maskedValue += cleanedValue[i];
                                        }
                                        setPhone(maskedValue)
                                        if (maskedValue === '' || maskedValue.replace(/ /g, '').length < 10)
                                            return setPhoneEmpty(true);
                                        setPhoneEmpty(false);
                                    }}
                                    onBlur={() => { 
                                        if (phone === '' || phone.replace(/ /g, '').length < 10)
                                            return setPhoneEmpty(true)
                                        setPhoneEmpty(false);
                                    }}
                                    value={phone}
                                    maxLength={12}
                                />
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                <h3>{formI18n[lang].eventData}</h3>
                                <DatePicker
                                    placeholder={`${formI18n[lang].datePlaceholder}*`}
                                    style={{ width: '100%', marginBottom: 15 }}
                                    locale={lang === 'es' ? localeEs : undefined}
                                    disabledDate={disabledCurrentDate}
                                    id='date'
                                    name='date'
                                    value={date ? dayjs(dayjs(date), 'YYYY-MM-DD') : null}
                                    onChange={(d) => {
                                        setDate(d ? dayjs(d).toString() : '');
                                    }}
                                />
                                <InputAutocomplete
                                    id='address'
                                    name='address'
                                    value={address}
                                    setValue={setAddress}
                                    placeholder={`${formI18n[lang].serviceAddressPlaceholder}*`}
                                    styleContainer={{ marginBottom: 15 }}
                                />
                                <Select
                                    id='type'
                                    options={typeEnum}
                                    style={{ width: "100%", marginBottom: 15 }}
                                    onChange={(value) => {
                                        setType(value)
                                    }}
                                    value={type ? type : undefined}
                                    placeholder={`${formI18n[lang].chooseEvent}*`}
                                />
                                <Input
                                    id='codeevent'
                                    name='codeevent'
                                    placeholder={`${formI18n[lang].codePlaceholder}*`}
                                    value={eventCode}
                                    onChange={(e) => {
                                        setEventCode(e.target.value ? e.target.value.toUpperCase().trim() : '')
                                        if (e.target.value === '')
                                            return setCodeEmpty(true);
                                            setCodeEmpty(false);
                                    }}
                                    onBlur={() => { 
                                        if (eventCode === '') 
                                            return setCodeEmpty(true)
                                            setCodeEmpty(false);
                                    }}
                                    typeColor={codeEmpty ? 'error' : 'default'}
                                    status={codeEmpty ? 'error' : ''}
                                    text={codeEmpty ? `${formI18n[lang].requiredText}` : `${formI18n[lang].codeAuxText}`}
                                    maxLength={CODE_MAX_LENGTH}
                                />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                <Row>
                                    <Col>
                                        <Checkbox style={{ fontSize: 12 }} checked={checkedTerms} onChange={onChangeTerms}>
                                            {formI18n[lang].agreeText}
                                        </Checkbox>
                                    </Col>
                                    <Col>
                                        <p style={{ fontFamily: 'GothamLight', margin: 0, fontWeight: 400, paddingTop: 3.7, fontSize: 12 }}><a href='/terminos-uso' rel='noreferrer' target='_blank'>{formI18n[lang].termsText}</a></p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{ textAlign: 'center'}} xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                <Button
                                    htmlType='submit'
                                    size='large' type='primary' ghost
                                    style={{ width: '35%' }}
                                    disabled={isDisabled}
                                >
                                    {formI18n[lang].btnSend}
                                </Button>
                            </Col>
                        </Row>
                    </form>
                </div>
            </div>
            <Modal
                open={successModal}
                onOk={handleOk}
                closable={false}
                centered
                footer={[
                    <Button key="submit" type="primary" onClick={handleOk} ghost>
                        {lang === 'es' ? 'Finalizar' : 'Finish'}
                    </Button>]
                }
            >
                <div style={{ textAlign: 'center'}}>
                    <img src={glLettersLogo} width={70} height={70} alt='gl-letters-logo' />
                    <br />
                    <br />
                    <h2>{lang === 'es' ? 'Gracias por registrar tu evento con nosotros' : 'Thank you for registering your event with us'}</h2>
                    <p>{lang === 'es' ? 'Recibirás una notificación vía SMS y correo electrónico con la confirmación' : 'You will receive a notification via SMS and email with confirmation'}</p>
                </div>
            </Modal>
        </>   
    )
}
export default FormEvent;
