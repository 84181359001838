import React, { useRef, useState } from 'react';

import { OurProfessionals } from 'src/pages/compositions';

import { CustomersVoice } from './customersvoice';
import { Hero } from './hero';
import { Goodlookeros } from './goodlookeros';
import { HowWork } from './howwork';
import { OurServices } from './ourservices';
import { RegistryEvent } from './registryevent';
import { SpecialPackage } from './specialpackage';
import { Subscribe } from './subscribe';

const Home = () => {
    const [isRef, setIsRef] = useState(false);
    return (
        <>
            <Hero
                isRef={isRef}
                onBlurRef={() => setIsRef(false)}
            />
            <OurServices
                setIsRef={() => setIsRef(true)}
            />
            <HowWork />
            <RegistryEvent />
            <SpecialPackage />
            <OurProfessionals />
            <CustomersVoice />
            <Goodlookeros />
            <Subscribe />
        </>
    )
}

export default Home;