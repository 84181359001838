import React from 'react';

import { Button, Layout } from 'antd';
import { Outlet, useNavigate } from 'react-router-dom';

import { Footer, MainHeader } from 'src/components';
import { useAppSelector } from 'src/hooks';
import { AppI } from 'src/redux';

import HomeI18n from 'src/i18n/home.i18n.json';
import styles from './HomeLayout.module.css';

const { Content } = Layout;

const HomeLayout = () => {
    const { lang } = useAppSelector(({ app }: { app: AppI }) => app);
    const navigate = useNavigate();

    return (
        <Layout>
            <div className={`text-center ${styles.content}`}>
                <p>{HomeI18n[lang].common} <Button size='small' onClick={() => navigate('/')}>{HomeI18n[lang].btnMore}</Button></p>
            </div>
            <MainHeader />
            <Content>
                <Outlet />
            </Content>
            <Footer />
        </Layout>
    )
}

export default HomeLayout