import React from 'react';

import { Select as ASelect, SelectProps } from 'antd';

interface IProps extends SelectProps {
    text?: React.ReactNode | React.ReactNode[] | string | JSX.Element | JSX.Element[];
    typeColor?: 'default' | 'success' | 'info' | 'error';
}

const Select = ({ text, typeColor='default', ...rest }: IProps) => {
    return (
        <div className='form-input'>
            <ASelect
                {...rest}
            />
            <span
                className={`span-form ${typeColor}`}
            >
                {text}
            </span>
        </div>
    )
}

export default Select