import React, { Fragment } from 'react';

import { useAppSelector } from 'src/hooks';
import { AppI } from 'src/redux';
import { En, Es } from './lang';

const Terms = () => {
   const { lang } = useAppSelector(({ app }: { app: AppI }) => app);
   return (
      <Fragment>
         {lang === 'es' ?
            <Es />
            :
            <En />
         }
      </Fragment>
   )
}

export default Terms;