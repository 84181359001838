import React, { useEffect } from 'react'
import GoodlookeroHero from './HeroGoodlookero';
import FormGoodlookero from './FormGoodlookero';

const Goodlookero = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [])
    return (
        <>
            <GoodlookeroHero />
            <FormGoodlookero />
        </>
    )
}
export default Goodlookero;
