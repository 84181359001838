import React, { useEffect, useState } from 'react';

import { Col, Row } from 'antd';

import { eventBg, eventWebBg, glLettersLogo } from 'src/assets/images';
import { useAppSelector } from 'src/hooks';
import { AppI } from 'src/redux';

import formI18n from 'src/i18n/form.i18n.json';

const HeroEvent = () => {
    const { lang } = useAppSelector(({ app }: { app: AppI }) => app);
    const [width, setWidth] = useState(window.innerWidth);
    
    useEffect(() => {
        window.addEventListener('resize', () => {
            setWidth(window.innerWidth);
        })
        return () => window.removeEventListener('resize', () => {});
    }, [])

    return (
        <div>
            <Row
                style={{
                    backgroundImage: `url(${width > 768 ? eventWebBg : eventBg})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    minHeight: width > 768 ? '45vh' : '30vh'
                }}
            >
                {width > 768 &&
                <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} md={12} lg={12} xl={12} xxl={12}>
                    <img src={glLettersLogo} alt='gl-logo' />
                    <br />
                    <h1 style={{ color: '#FFFFFF' }}>{formI18n[lang].eventTitle}</h1>
                </Col>}
            </Row>
        </div>
    )
}
export default HeroEvent;
