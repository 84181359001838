import React, { ReactNode } from 'react';

import { Input as AInput, InputProps } from 'antd';

interface IProps extends InputProps {
    text?: ReactNode | ReactNode[] | string | JSX.Element | JSX.Element[];
    typeColor?: 'default' | 'success' | 'info' | 'error';
}

const Input = ({ text, typeColor='default', ...rest }: IProps) => {
    return (
        <div className='form-input'>
            <AInput
                {...rest}
            />
            <span
                className={`span-form ${typeColor}`}
            >
                {text}
            </span>
        </div>
    )
}
export default Input
